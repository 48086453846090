import {
  dataSourceTypes,
  PRODUCT_ID,
  sassAppsAndEndpointDataSourceTypes,
  SECURITY_ADDON_ADVANCED_SASS_APPS_ENDPOINTS,
  SECURITY_ADDON_CORE,
  SECURITY_ADDON_HYBRID_ADVANCED_WORKLOADS,
} from "consts";
import keys from "lodash/keys";
import useUpdateCustomer from "queryHooks/useUpdateCustomer/useUpdateCustomer";
import useUpdateTenant from "queryHooks/useUpdateTenant/useUpdateTenant";

export class LicenseUpdateError extends Error {
  errorList = [];

  constructor(props) {
    super();
    const { message, type } = props || { type: "danger" };
    if (message) {
      this.errorList.push({ message, type });
    }
  }

  add({ message, type }) {
    this.errorList.push({ message, type });
  }

  get errors() {
    return this.errorList;
  }
}

const useUpdateSecurityAddon = (customerDetails, tenants) => {
  const { id: customerId } = customerDetails;
  const {
    [PRODUCT_ID.hybridWorkloads]: hybridTenant,
    [PRODUCT_ID.saasAppsAndEndpoints]: sassAppsTenant,
  } = tenants.reduce((acc, tenant) => {
    acc[tenant.productID] = tenant;
    return acc;
  }, {});

  const { mutateAsync: updateCustomer, isLoading: updatingCustomer } =
    useUpdateCustomer(customerId);

  const { mutateAsync: updateHybridTenant, isLoading: updatingHybridTenant } =
    useUpdateTenant({
      customerId,
      tenantId: hybridTenant?.id,
      isSecurityAddonUpdate: true,
    });

  const {
    mutateAsync: updateSassAppsTenant,
    isLoading: updatingSassAppsTenant,
  } = useUpdateTenant({
    customerId,
    tenantId: sassAppsTenant?.id,
    isSecurityAddonUpdate: true,
  });

  const getFailedProductNames = (rejectedPromiseResult) => {
    if (rejectedPromiseResult.length === 0) {
      return null;
    }
    if (rejectedPromiseResult.length === 2) {
      return "SaaS Apps and Endpoints and Hybrid Workloads";
    }
    if (rejectedPromiseResult?.reason?.tenantId === sassAppsTenant?.id) {
      return "SaaS Apps and Endpoints";
    }
    return "Hybrid Workloads";
  };

  const getSuccessProductNames = (resolvedPromiseResult) => {
    if (resolvedPromiseResult.length === 0) {
      return null;
    }
    if (resolvedPromiseResult.length === 2) {
      return "SaaS Apps and Endpoints and Hybrid Workloads";
    }
    if (resolvedPromiseResult[0]?.tenantId === hybridTenant?.id) {
      return "Hybrid Workloads";
    }
    return "SaaS Apps and Endpoints";
  };

  const updateSecurityAddon = async ({ initialValues, values }) => {
    if (initialValues.coreEnabled !== values.coreEnabled) {
      try {
        await updateCustomer({
          ...customerDetails,
          features: [
            {
              name: SECURITY_ADDON_CORE,
              isEnabled: values.coreEnabled,
            },
          ],
        });
      } catch (e) {
        throw new LicenseUpdateError({
          message: e.message,
          type: e.type || "danger",
        });
      }
    }

    // Update tenant only if core is enabled
    if (values.coreEnabled) {
      const productUpdatePromises = [];

      if (
        hybridTenant?.id &&
        initialValues[dataSourceTypes["Hybrid Workloads"]] !==
          values[dataSourceTypes["Hybrid Workloads"]]
      ) {
        productUpdatePromises.push(
          updateHybridTenant({
            features: [
              {
                name: SECURITY_ADDON_HYBRID_ADVANCED_WORKLOADS[
                  "Hybrid Workloads"
                ],
                isEnabled:
                  values[dataSourceTypes["Hybrid Workloads"]] === "advanced",
              },
            ],
          }),
        );
      }

      const arrForSassAppsConfig = keys(
        sassAppsAndEndpointDataSourceTypes,
      ).reduce((acc, type) => {
        if (initialValues[type] !== values[type]) {
          acc.push({
            name: SECURITY_ADDON_ADVANCED_SASS_APPS_ENDPOINTS[type],
            isEnabled: values[type] === "advanced",
          });
        }
        return acc;
      }, []);

      if (sassAppsTenant?.id && arrForSassAppsConfig.length > 0) {
        productUpdatePromises.push(
          updateSassAppsTenant({
            features: arrForSassAppsConfig,
          }),
        );
      }

      const result = await Promise.allSettled(productUpdatePromises);
      const rejectedPromises = result.filter(
        (item) => item.status === "rejected",
      );
      const resolvedPromises = result.filter(
        (item) => item.status === "fulfilled",
      );

      if (rejectedPromises.length > 0) {
        const errors = new LicenseUpdateError();

        rejectedPromises.forEach((error) => {
          errors.add({
            message:
              error?.reason?.message ||
              error?.reason?.data?.message ||
              `Security Add-ons configuration failed for ${getFailedProductNames(
                error,
              )}`,
            type: error?.reason?.type || error?.reason?.data?.type || "danger",
          });
        });

        resolvedPromises.forEach((success) => {
          errors.add({
            message: `Security Add-ons configured for ${getSuccessProductNames(
              success,
            )}`,
            type: "success",
          });
        });

        throw errors;
      }
    }
  };

  return {
    updateSecurityAddon,
    updatingSecurityAddon:
      updatingCustomer || updatingHybridTenant || updatingSassAppsTenant,
  };
};

export default useUpdateSecurityAddon;
