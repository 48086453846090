import React from "react";
import { ConfirmationModal } from "orion";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { genericErrorMessage } from "consts";
import { showAlert } from "slices/alertsSlice";
import useDeleteAdministrator from "queryHooks/useDeleteAdministrator/useDeleteAdministrator";

const DeleteCustomerContactActionModal = ({ key, closeModal, adminId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutate } = useDeleteAdministrator(adminId, {
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
    onSuccess: (data) => {
      if (data?.warning) {
        dispatch(
          showAlert({
            message: data?.message,
            type: "warning",
          })
        );
      } else {
        navigate("/settings/admins");
        closeModal();
        dispatch(
          showAlert({
            message: "Successfully deleted Administrator",
            type: "success",
          })
        );
      }
    },
  });

  return (
    <ConfirmationModal
      key={key}
      isOpen
      toggle={closeModal}
      onContinue={() => mutate()}
      header="Confirm Delete"
      message="Are you sure you want to delete the administrator?"
      continueButtonText="Delete"
      cancelButtonText="Cancel"
    />
  );
};

export default DeleteCustomerContactActionModal;
