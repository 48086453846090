import { useMutation, useQueryClient } from "react-query";
import { API_CUSTOMER, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import { duplicatePollTaskStatusForSecurityAddonUpdateAsync } from "queryHooks/useJobStatus/useJobStatus";
import { useDispatch } from "react-redux";

export default function useUpdateCustomer(customerId, config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const updateCustomer = (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await httpClient.patch(
          withUrlParams(API_CUSTOMER, { customerId }),
          data
        );
        const pollRes =
          await duplicatePollTaskStatusForSecurityAddonUpdateAsync(
            httpClient,
            res?.task?.id,
            dispatch
          );
        resolve(pollRes);
      } catch (err) {
        reject(err);
      }
    });

  return useMutation((data) => updateCustomer(data), {
    ...config,
    onSuccess: (data) => {
      queryClient.invalidateQueries(QUERIES.customer);
      queryClient.invalidateQueries(QUERIES.customers);
      queryClient.invalidateQueries(QUERIES.customersWithTenants);
      config?.onSuccess?.(data);
    },
  });
}
