import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  id: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUserId(state, { payload }) {
      state.id = payload.id;
    },
  },
});

export const { setUserId, setUserMspId } = userSlice.actions;

export default userSlice.reducer;
export const user = (state) => state.user;

export const userId = (state) => state.user.id;
