import React from "react";
import { Loader } from "orion";
import { useSelector } from "react-redux";
import { useIsMutating, useIsFetching } from "react-query";
import { loaderText } from "slices/fullPageLoaderSlice";
import { useLocation } from "react-router-dom";

// Temporary disable loader by path
// Once full screen loader is removed from all the screens, remove the following implementation of disabling by path
const disabledPaths = ["/jobs/2/backup"];

const FullPageLoader = () => {
  const text = useSelector(loaderText);
  const location = useLocation();

  const mutationsNumber = useIsMutating({
    predicate: (query) => {
      return !query.options?.meta?.disableFullPageLoader;
    },
  });
  const fetchingNumber = useIsFetching({
    predicate: (query) => {
      const {
        options: { meta: { disableFullPageLoader, isForOptions } = {} },
      } = query;

      if (disableFullPageLoader || isForOptions) {
        return false;
      }

      return (
        // to not show full page loader for fetching nextpages
        query.isFetching && (query.state.data?.pageParams || []).length < 3
      );
    },
  });

  if (disabledPaths.some((path) => location.pathname.startsWith(path))) {
    return null;
  }

  return (
    <Loader
      isLoading={!!(mutationsNumber + fetchingNumber)}
      text={text}
      fullPage
      dataTestId="full-page-loader"
    />
  );
};

export default FullPageLoader;
