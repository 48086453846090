import { useMutation, useQueryClient } from "react-query";
import { API_TENANTS, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import { pollTaskStatus } from "queryHooks/useJobStatus/useJobStatus";
import { useDispatch } from "react-redux";

export default function useDeleteTenant(customerId, tenantId, config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const deleteTenant = () =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await httpClient.delete(
          withUrlParams(API_TENANTS, {
            customerId,
            tenantId,
          }),
          {
            params: {
              deleteNow: false,
            },
          }
        );
        pollTaskStatus(httpClient, res?.task?.id, resolve, reject, dispatch);
      } catch (err) {
        reject(err);
      }
    });

  return useMutation(() => deleteTenant(), {
    ...config,
    onSuccess: (data) => {
      queryClient.invalidateQueries(QUERIES.tenant);
      queryClient.invalidateQueries(QUERIES.customersTenants);
      queryClient.invalidateQueries(QUERIES.customersWithTenants);
      config?.onSuccess?.(data);
    },
  });
}
