import { Card, Col, LabelValue, Row, ToggleButton, Tooltip } from "orion";
import ModalForm from "shared/ModalForm";
import styles from "./securityAddonForm.module.scss";
import InfoIcon from "@druva-apps/orion-icons/lib/InfoGreyOutline";
import keys from "lodash/keys";
import {
  dataSourceTypes,
  helpArticles,
  labelToDataSourceType,
} from "consts";
import { useArrTenantDetails, useLicensedDataSources } from "utils/tenantUtils";
import ARRRow from "./ARRRow";
import NoProductConfiguredInfo from "./NoProductConfiguredInfo";
import Loader from "orion/lib/loader";
import { useDispatch } from "react-redux";
import { showAlert } from "slices/alertsSlice";
import useUpdateSecurityAddon from "./useUpdateSecurityAddon";
import HelpIcon from "components/HelpIcon";

const SecurityAddonInfo = () => {
  return (
    <section id="info-section" className={styles.infoSection}>
      <Row className="oui-p-3">
        <Col sm={6}>
          <LabelValue
            label="Security Posture & Observability (SP&O) Features"
            value="Anomaly Detection, Pre-built SIEM Integrations, Rollback Actions, Security Command Center, Security Events"
          />
        </Col>
        <Col sm={6}>
          <LabelValue
            label="Accelerated Ransomware Recovery (ARR) Features"
            value="All SP&O Features, Curated Recovery, Quarantine, Recovery Scan, Sandbox Recovery, SOAR Integrations."
          />
        </Col>
      </Row>
    </section>
  );
};

const ToggleSecurityAddon = ({ formikProps, isArrEnabled }) => {
  return (
    <div className={styles.coreToggleButton}>
      <ToggleButton
        isActive={formikProps.values.coreEnabled}
        onClick={() =>
          formikProps.setFieldValue(
            "coreEnabled",
            !formikProps.values.coreEnabled
          )
        }
        label="Enable Security Add-ons"
        disabled={isArrEnabled}
        dataTestId="toggle-security-addon"
      />
      <Tooltip text="Security Posture & Observability will be enabled by default for all products in the future.">
        <InfoIcon />
      </Tooltip>
    </div>
  );
};

const SecurityAddonForm = ({
  closeModal,
  isOpen,
  tenants,
  customerDetails,
}) => {
  const {
    advancedsaasAppsAndEnpointsFeatures,
    advancedhybridWorkloadsFeatures,
    coreFeaturesIsEnabled,
    suspendedFeatures,
    deletedFeatures,
    lockedFeatures,
    isSassAppsExpired,
    isHybridExpired,
  } = useArrTenantDetails(tenants, customerDetails);

  const { updateSecurityAddon, updatingSecurityAddon } = useUpdateSecurityAddon(
    customerDetails,
    tenants
  );

  const dispatch = useDispatch();

  const licensedDataSources = useLicensedDataSources(tenants);

  const featuresExpired = {
    "Hybrid Workloads": isHybridExpired,
    "Microsoft 365": isSassAppsExpired,
    Endpoints: isSassAppsExpired,
    "Google Workspace": isSassAppsExpired,
  };

  const initialValues = keys(dataSourceTypes).reduce(
    (acc, type) => {
      const advanced = [
        ...advancedsaasAppsAndEnpointsFeatures,
        ...advancedhybridWorkloadsFeatures,
      ].map((feature) => labelToDataSourceType[feature]);

      return {
        ...acc,
        [type]: advanced.includes(type) ? "advanced" : "core",
      };
    },
    { coreEnabled: coreFeaturesIsEnabled }
  );

  const arrEnabledWorloads = [
    ...advancedhybridWorkloadsFeatures,
    ...advancedsaasAppsAndEnpointsFeatures,
  ];

  const isArrEnabled = arrEnabledWorloads.length > 0;

  return (
    <ModalForm
      disableSubmitWhenInvalid
      submitButtonLabel="Save"
      closeModal={closeModal}
      header={() => (
        <div className="oui-d-flex">
          <h5>
            {coreFeaturesIsEnabled
              ? "Edit Security Add-ons"
              : "Configure Security Add-ons"}
          </h5>
          <div className="oui-mt-1 oui-ml-2">
            <HelpIcon article={helpArticles.configureSecurityAdOnsInMsc} />
          </div>
        </div>
      )}
      isOpen={isOpen}
      size="xxl"
      onSubmit={async (values) => {
        try {
          await updateSecurityAddon({ values, initialValues });
          closeModal();
          dispatch(
            showAlert({
              message: "Security Add-ons configured successfully.",
              type: "success",
            })
          );
        } catch (e) {
          e.errorList.forEach((error) => {
            dispatch(
              showAlert({
                message: error.message,
                type: error.type || "danger",
              })
            );
          });
        }
      }}
      initialValues={initialValues}
      renderChildren={(formikProps) => {
        return (
          <Loader isLoading={updatingSecurityAddon} fullPage>
            <Card
              title="Security Add-ons Details"
              body={() => {
                return (
                  <div>
                    <SecurityAddonInfo />
                    <section id="core-toggle" className={styles.coreToggle}>
                      <ToggleSecurityAddon
                        formikProps={formikProps}
                        isArrEnabled={isArrEnabled}
                      />
                      {isArrEnabled && (
                        <div className="oui-d-flex">
                          <InfoIcon className="oui-mr-2" />
                          <p
                            className={`oui-text-secondary ${styles.infoText}`}
                          >
                            {`Security Add-ons cannot be disabled as Accelerated Ransomware Recovery is selected for the ${arrEnabledWorloads.join(
                              ", "
                            )}`}
                          </p>
                        </div>
                      )}
                    </section>
                    <section className={styles.arrSection}>
                      {licensedDataSources.length === 0 && (
                        <NoProductConfiguredInfo />
                      )}
                      {licensedDataSources.map((type) => {
                        const featureName = dataSourceTypes[type];
                        return (
                          <ARRRow
                            key={type}
                            featureName={type}
                            formikProps={formikProps}
                            isSuspended={suspendedFeatures.includes(
                              featureName
                            )}
                            isLocked={lockedFeatures.includes(featureName)}
                            isDeleted={deletedFeatures.includes(featureName)}
                            isExpired={featuresExpired[featureName]}
                            disabled={!formikProps.values.coreEnabled}
                          />
                        );
                      })}
                    </section>
                  </div>
                );
              }}
            />
          </Loader>
        );
      }}
    />
  );
};

export default SecurityAddonForm;
