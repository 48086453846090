import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { genericErrorMessage } from "consts";

import { showAlert } from "slices/alertsSlice";
import ModalForm from "shared/ModalForm";
import Field from "components/Field/Field";

import useCreateAPICredentialsForm from "./useCreateAPICredentialsForm";
import useUpdateAPICredentialsForm from "./useUpdateAPICredentialsForm";
import styles from "./APICredentialsForm.module.scss";
import { openModal } from "slices/modalsSlice";

const APICredentialsForm = ({ closeModal, header, isOpen, isEdit, client }) => {
  const dispatch = useDispatch();
  const { current: useMutation } = useRef(
    isEdit ? useUpdateAPICredentialsForm : useCreateAPICredentialsForm
  );
  const [initialValues, { mutate }] = useMutation(client);

  const onSubmitHandler = useCallback(
    ({ name }) => {
      mutate(
        {
          name,
        },
        {
          onError: (e) => {
            dispatch(
              showAlert({
                message: e.data?.message || genericErrorMessage,
                type: "danger",
              })
            );
          },
          onSuccess: (client) => {
            dispatch(
              showAlert({
                message: `Client credential ${
                  isEdit ? "edited" : "created"
                } successfully`,
                type: "success",
              })
            );
            closeModal();
            if (!isEdit) {
              dispatch(
                openModal({
                  key: "EVENT_CLIENT_SECRET_KEY_GENERATED",
                  header: "New Credentials",
                  client,
                })
              );
            }
          },
        }
      );
    },
    [mutate, dispatch, isEdit, closeModal]
  );

  return (
    <ModalForm
      className={styles.modalContainer}
      bodyClassName={styles.overflowYVisible}
      initialValues={initialValues}
      disableSubmitWhenInvalid
      submitButtonLabel="Save"
      closeModal={closeModal}
      withValidation
      header={header}
      isOpen={isOpen}
      size="sm"
      onSubmit={onSubmitHandler}
      renderChildren={(props) => (
        <>
          <Field label="Name" name="name" formikProps={props} fullWidth />
        </>
      )}
    />
  );
};

APICredentialsForm.defaultProps = {
  isEdit: false,
};

export default APICredentialsForm;
