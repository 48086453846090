import { useCallback, useEffect } from "react";
import useAdministratorListV2 from "./useAdministratorListV2";
import groupBy from "lodash/groupBy";
import useUser from "queryHooks/useUser/useUser";

const useAdministratorStatsV2 = (config) => {
  const { data: adminInfo, isLoading: isUserLoading } = useUser();

  const {
    data: adminsList,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isLoading: isAdminListLoading,
  } = useAdministratorListV2({ enabled: !isUserLoading, ...(config || {}) });

  const fetchNextPageHandler = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (!isFetching) {
      fetchNextPageHandler();
    }
  }, [isFetching, fetchNextPageHandler]);

  const countPerRole = groupBy(adminsList, "role");

  return {
    list: adminsList || [],
    countPerRole,
    haveTwoOrLessMSPAdmin: countPerRole[2]?.length <= 2,
    haveOnlyOneMSPAdmin: countPerRole[2]?.length === 1,
    adminInfo,
    isLoading: isUserLoading || isAdminListLoading,
  };
};

export default useAdministratorStatsV2;
