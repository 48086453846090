import { useMutation, useQueryClient } from "react-query";
import { API_CUSTOMER, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import { pollTaskStatus } from "queryHooks/useJobStatus/useJobStatus";
import { useDispatch } from "react-redux";

export default function useDeleteCustomer(customerId, config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const deleteCustomer = () =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await httpClient.delete(
          withUrlParams(API_CUSTOMER, { customerId })
        );
        pollTaskStatus(httpClient, res?.task?.id, resolve, reject, dispatch);
      } catch (err) {
        reject(err);
      }
    });

  return useMutation(deleteCustomer, {
    ...config,
    onSuccess: (data) => {
      queryClient.invalidateQueries(QUERIES.customers);
      queryClient.invalidateQueries(QUERIES.customersWithTenants);
      config?.onSuccess?.(data);
    },
  });
}
