import { useMutation, useQueryClient } from "react-query";
import { API_CANCEL_DELETE_PRODUCT, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import { pollTaskStatus } from "queryHooks/useJobStatus/useJobStatus";
import { useDispatch } from "react-redux";

export default function useUndeleteTenant(customerId, tenantId, config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const undeleteCustomerTenant = () =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await httpClient.post(
          withUrlParams(API_CANCEL_DELETE_PRODUCT, {
            customerId,
            tenantId,
          })
        );
        pollTaskStatus(httpClient, res?.task?.id, resolve, reject, dispatch);
      } catch (err) {
        reject(err);
      }
    });

  return useMutation(undeleteCustomerTenant, {
    ...config,
    onSuccess: (data) => {
      queryClient.invalidateQueries(QUERIES.tenant);
      queryClient.invalidateQueries(QUERIES.customersTenants);
      queryClient.invalidateQueries(QUERIES.customersWithTenants);
      config?.onSuccess?.(data);
    },
  });
}
