import HelpGreyOutline from "@druva-apps/orion-icons/lib/HelpGreyOutline";
import {getHelpPortalURL } from "utils/mspUtils";

const HelpIcon = ({ article }) => {
  return (
    <a
      href={getHelpPortalURL(article)}
      target="_blank"
      rel="noreferrer"
      className="oui-d-flex"
    >
      <HelpGreyOutline muted={false} />
    </a>
  );
};

export default HelpIcon;
