import InfoGreyOutline from "@druva-apps/orion-icons/lib/InfoGreyOutline";
import Tooltip from "orion/lib/tooltip";

const LockedIcon = ({ id }) => (
  <Tooltip text="Updating the feature settings. Please wait.">
    <div className="oui-d-flex oui-align-items-center oui-ml-2 oui-mr-2">
      <InfoGreyOutline id={id} inheritFontColor />
    </div>
  </Tooltip>
);

export default LockedIcon;
