import React from "react";
import { Row, Col } from "orion";
import Field from "components/Field/Field";

const FormContainer = ({ formikProps }) => {
  return (
    <Row>
      <Col>
        <Field
          label="Name"
          name="fullName"
          placeholder="Enter Name"
          dataTestId="msp-form-contact-name-txt"
          formikProps={formikProps}
          fullWidth
        />
        <Field
          label="Email"
          name="email"
          placeholder="Enter Email"
          dataTestId="msp-form-contact-email-txt"
          formikProps={formikProps}
          fullWidth
        />
        <Field
          label="Phone number"
          name="phoneNumber"
          type="phone"
          placeholder="Enter Number"
          dataTestId="msp-form-contact-phone-number-txt"
          formikProps={formikProps}
          fullWidth
        />
        <Field
          label="Comments"
          type="textarea"
          name="comment"
          placeholder=""
          dataTestId="msp-form-contact-comment-txt"
          formikProps={formikProps}
          fullWidth
        />
      </Col>
    </Row>
  );
};

export default FormContainer;
