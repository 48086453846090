import React, { useMemo } from "react";
import { Select } from "orion";

import useSkuList from "queryHooks/useSkuList/useSkuList";

const styles = {
  menuList: () => ({
    textTransform: "capitalize",
  }),
  valueContainer: () => ({
    textTransform: "capitalize",
  }),
};

const UpgradeSkuSelect = ({
  name,
  placeholder,
  formikProps,
  dataTestId,
  disabled,
  isInvalid,
}) => {
  const { data } = useSkuList();

  //3 elite
  //2 enterprise
  //1 business

  // Business -> enterprise, elite
  // Enterprise -> Elite
  // Elite -> disabled

  const initialValue = useMemo(() => formikProps.initialValues[name], []);

  const options = useMemo(() => {
    const possibleOptions = (data?.list || []).map(({ id, name }) => ({
      label: name,
      value: id,
      isDisabled:
        (initialValue.label === "enterprise" && id === 1) ||
        (initialValue.label === "elite" && (id === 1 || id === 2)),
    }));

    if (initialValue.value === -1) {
      possibleOptions.unshift({ ...initialValue });
    }

    return possibleOptions;
  }, [data?.list, initialValue]);

  return (
    <Select
      name={name}
      type="select"
      placeholder={placeholder}
      validationProps={formikProps}
      isDisabled={disabled}
      dataTestId={dataTestId}
      styles={styles}
      options={options}
      value={formikProps.values[name]}
      isInvalid={isInvalid}
    />
  );
};

export default UpgradeSkuSelect;
