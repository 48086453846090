import { useMemo } from "react";
import { useDispatch } from "react-redux";

import { genericErrorMessage } from "consts";
import { showAlert } from "slices/alertsSlice";
import useCustomer from "queryHooks/useCustomer/useCustomer";
import useUpdateCustomer from "queryHooks/useUpdateCustomer/useUpdateCustomer";
import useTenantAdminListV2 from "queryHooks/useAdministratorListV2/useTenantAdminListV2";

const useUpdateCustomerForm = (customerId) => {
  const dispatch = useDispatch();

  const queryConfig = useMemo(
    () => ({
      onError: (e) => {
        dispatch(
          showAlert({
            message: e.data?.message || genericErrorMessage,
            type: "danger",
          })
        );
      },
    }),
    [dispatch]
  );

  const { data: customerData, isFetching } = useCustomer(
    customerId,
    queryConfig
  );

  const { data: tenantAdminListData } = useTenantAdminListV2(
    {
      isForOptions: true,
    },
    queryConfig
  );

  const useMutation = useUpdateCustomer(customerId);

  const initialValues = useMemo(() => {
    if (!isFetching && customerData && tenantAdminListData) {
      const tenantAdmins = tenantAdminListData.list.filter(({ id }) =>
        customerData.tenantAdmins.includes(id)
      );

      return {
        accountName: customerData.accountName,
        address: customerData.address,
        phone: customerData.phone,
        customerName: customerData.customerName,
        alternateNameToDruva:
          customerData.accountName !== customerData.customerName,
        tenantAdmins: (tenantAdmins || []).map(({ email, id }) => ({
          label: email,
          value: id,
        })),
      };
    }
  }, [isFetching, customerData, tenantAdminListData]);

  return [initialValues, useMutation];
};

export default useUpdateCustomerForm;
