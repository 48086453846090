import { dataSourceTypes } from "consts";
import flatten from "lodash/flatten";
import { parseJSON } from "utils/mspUtils";

const getUniqueItemNames = (items) => {
  const itemNames = items.map((item) => item.name);
  return [...new Set(itemNames)];
};

const getItemMap = (items) => {
  return items.reduce((acc, item) => {
    acc[item.name] = item;
    return acc;
  }, {});
};
const getItemRow = (oldItem, newItem, type) => {
  const items = [...oldItem, ...newItem];

  const oldItemMap = getItemMap(oldItem);
  const newItemMap = getItemMap(newItem);

  const uniqueItemNames = getUniqueItemNames(items);

  return uniqueItemNames.map((itemName) => {
    const oldItem = oldItemMap[itemName];
    const newItem = newItemMap[itemName];

    const oldAttributes = oldItem?.attrs || [];
    const newAttributes = newItem?.attrs || [];

    const attributeRows = flatten(
      getItemRow(oldAttributes, newAttributes, "attribute"),
    );

    return [
      {
        type,
        name: itemName,
        old: oldItem,
        new: newItem,
      },
      ...attributeRows,
    ];
  });
};

const getFeatureRows = (oldFeatures, newFeatures) =>
  flatten(getItemRow(oldFeatures, newFeatures, "feature"));

export const useEventHistoryTableRows = (data) => {
  const { oldValue, newValue } = data;
  const oldFeatures = oldValue || [];
  const newFeatures = newValue || [];

  return getFeatureRows(oldFeatures, newFeatures);
};

export const isEventDescriptionJSON = (event) => {
  const description = event.details.Description;
  const isDescriptionJson = typeof description === "object";
  return (
    isDescriptionJson &&
    (Object.hasOwnProperty.call(description, "oldValue") ||
      Object.hasOwnProperty.call(description, "newValue"))
  );
};

export const getEventWithDescriptionJSON = (event) => {
  const description =
    parseJSON(event.details.Description) || event.details.Description;

  return {
    ...event,
    details: { ...event.details, Description: description },
  };
};

const getAttributeLabel = (attributeName) => {
  const attributeLabels = {
    edition: "Edition",
    userCount: "User Count",
    preservedUserCount: "Preserved User Count",
    backupFrequencyLimitMin: "Backup Frequency Limit Minimum",
    // backupFrequencyLimitMax,
    // backupFrequencyLimitUnit,
    // retentionLimitMax,
    // retentionLimitMin,
    // retentionLimitUnit
    // workloads,
    // unlimitedStorage,
    // sensitiveDataGovernance
  };
  return attributeLabels[attributeName] || attributeName;
};

const getFeatureLabel = (featureName) => {
  return dataSourceTypes[featureName] || featureName;
};

export const NameRenderer = ({ rowData }) => {
  const { type, name } = rowData;
  return (
    <div>
      {type === "feature" && (
        <h6 className="oui-m-0">{getFeatureLabel(name)}</h6>
      )}
      {type === "attribute" && (
        <div className="oui-pl-2">{getAttributeLabel(name)}</div>
      )}
    </div>
  );
};

const DefaultValueRenderer = ({ item }) => {
  return <span>{item?.value !== undefined ? item?.value : "-"}</span>;
};

const ATTRIBUTE_RENDERER = {
  default: DefaultValueRenderer,
};

const ValueRenderer = ({ type, item }) => {
  if (type === "feature") {
    return item?.isEnabled ? "Enabled" : "Disabled";
  }

  if (type === "attribute") {
    const attributeName = item?.name;
    if (attributeName in ATTRIBUTE_RENDERER) {
      const AttributeRenderer = ATTRIBUTE_RENDERER[attributeName];
      return <AttributeRenderer item={item} />;
    }
    return <DefaultValueRenderer item={item} />;
  }
  return null;
};

export const OldRenderer = ({ rowData }) => {
  const { type, old } = rowData;
  return <ValueRenderer type={type} item={old} />;
};

export const NewRenderer = ({ rowData }) => {
  const { type, new: newValue } = rowData;
  return <ValueRenderer type={type} item={newValue} />;
};
