import useCreateClient from "queryHooks/useCreateClient/useCreateClient";

const initialValues = {
  name: "",
};

const useCreateAPICredentialsForm = () => {
  const useMutation = useCreateClient();

  return [initialValues, useMutation];
};

export default useCreateAPICredentialsForm;
