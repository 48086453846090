import { useIntercom } from "react-use-intercom";
import { useEffect, useRef, FC, useMemo } from "react";
import useUser from "queryHooks/useUser/useUser";
import { rolesLabels } from "consts";
import useMsp from "queryHooks/useMsp/useMsp";

const Intercom: FC = () => {
  const { boot, update, shutdown } = useIntercom();
  const { data: userData } = useUser();
  const { data: mspData } = useMsp();
  const bootRef = useRef(false);

  const customUserData = useMemo(
    () =>
      userData && mspData
        ? {
            name: `${userData.firstName} ${userData.lastName}`,
            email: userData.email,
            admin_role: (rolesLabels as any)[userData.role],
            parent_type: "MSP",
            msp_admin: userData.role === 2,
            org_name: "",
            msp_license_type: mspData.edition,
            user_hash: userData.userHash,
            user_id: `MSP:${userData.id}`,
            company: {
              company_id: mspData.sfdcId,
              company_name: `MSP:${mspData.sfdcId}`,
            },
          }
        : null,
    [userData, mspData],
  );

  useEffect(() => {
    if (customUserData) {
      if (!bootRef.current) {
        boot({
          customAttributes: customUserData,
        });
        bootRef.current = true;
      } else {
        update({ customAttributes: customUserData });
      }
    }
    return () => {
      shutdown();
    };
  }, [boot, update, shutdown, customUserData]);

  return null;
};

export default Intercom;
