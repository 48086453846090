import {
  backupFrequencyLimitUnitDictionary,
  dataSourceTypes,
  retentionLimitUnitDictionary,
} from "consts";

export const isFeatureEnabled = (features = [], name) =>
  (features || []).find((feature) => feature.name === name)?.isEnabled;

const getEnableFeatureMessage = () =>
  "By default 10 user count and 0 preserved user count will be allotted to the all customers with this service plan.";

export const getDisableFeatureMessage = (feature) =>
  `${dataSourceTypes[feature]} will be disabled for all the mapped customers for this service plan.`;

export const getFeatureMessage = (initial, current, feature) => {
  if (
    isFeatureEnabled(initial?.features, feature) &&
    !isFeatureEnabled(current?.features, feature)
  ) {
    return getDisableFeatureMessage(feature);
  }

  if (
    !isFeatureEnabled(initial?.features, feature) &&
    isFeatureEnabled(current?.features, feature)
  ) {
    return getEnableFeatureMessage();
  }

  return "";
};

export const getBackupFrequencyLimitText = (
  backupFrequencyLimitUnit,
  backupFrequencyLimitMin,
  backupFrequencyLimitMax,
) => {
  if (backupFrequencyLimitUnit) {
    if (!backupFrequencyLimitMin && !backupFrequencyLimitMax) {
      return `${backupFrequencyLimitUnitDictionary[backupFrequencyLimitUnit]}`;
    } else if (backupFrequencyLimitMin === backupFrequencyLimitMax) {
      return `${backupFrequencyLimitUnitDictionary[backupFrequencyLimitUnit]} - ${backupFrequencyLimitMin} times`;
    }
    return `${backupFrequencyLimitUnitDictionary[backupFrequencyLimitUnit]} - ${backupFrequencyLimitMin} to ${backupFrequencyLimitMax} times`;
  }
  return "-";
};

export const getRetentionLimitText = (
  retentionLimitUnit,
  retentionLimitMin,
  retentionLimitMax,
) => {
  if (retentionLimitUnit) {
    if (!retentionLimitMin && !retentionLimitMax) {
      return `unlimited ${retentionLimitUnitDictionary[retentionLimitUnit]}`;
    } else if (retentionLimitMin === retentionLimitMax) {
      return `${retentionLimitMin} ${retentionLimitUnitDictionary[retentionLimitUnit]}`;
    }
    return `${retentionLimitMin} to ${
      retentionLimitMax === 0 ? "unlimited" : retentionLimitMax
    } ${retentionLimitUnitDictionary[retentionLimitUnit]}`;
  }
  return "-";
};
