import React, { useEffect } from "react";
import Card from "orion/lib/card";
import { FieldArray } from "formik";

import { PRODUCT_ID } from "consts";

import Field from "components/Field/Field";
import styles from "./ServicePlanForm.module.scss";
import HybridWorkloadFormContainer from "./HybridWorkloadFormContainer";
import SaaSAppAndEndpointsFormContainer from "./SaaSAppAndEndpointsFormContainer";

const FormContainer = ({ formikProps, isEdit }) => {
  useEffect(() => {
    formikProps.validateForm();
  }, []);
  return (
    <>
      <Card
        title="Details"
        body={() => (
          <>
            <Field
              label="Service Plan"
              name="name"
              formikProps={formikProps}
              disabled={isEdit}
              info="Service Plan Name once entered, cannot be changed"
              dataTestId="msp-add-service-plan-name-txt"
              placeholder="Service Plan Name"
              inputContainerClassName={styles.longInput}
            />
          </>
        )}
      />
      <FieldArray
        name="products"
        render={(_) => (
          <>
            {formikProps.values.products?.length > 0 &&
              formikProps.values.products.map((detail, index) =>
                detail.productID === PRODUCT_ID.hybridWorkloads ? (
                  <HybridWorkloadFormContainer
                    formikProps={formikProps}
                    details={detail}
                    index={index}
                    key="hybrid-workloads-service-plan"
                  />
                ) : (
                  <SaaSAppAndEndpointsFormContainer
                    formikProps={formikProps}
                    details={detail}
                    index={index}
                    key="saas-app-and-ep-service-plan"
                    isEdit={isEdit}
                  />
                ),
              )}
          </>
        )}
      />
    </>
  );
};
export default FormContainer;
