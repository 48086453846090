import { API_TASK, CAM_JOBS_STATUS, genericErrorMessage } from "consts";
import { useHttpClient } from "contexts/httpClient";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { setLoaderText } from "slices/fullPageLoaderSlice";
import withUrlParams from "utils/withUrlParams";

export const pollTaskStatus = (
  httpClient,
  taskId,
  resolve,
  reject,
  dispatch
) => {
  let count = 0;
  let interval = setInterval(async () => {
    count++;
    try {
      const jobData = await httpClient.get(
        withUrlParams(API_TASK, { taskId }),
        taskId
      );
      if (jobData.status === CAM_JOBS_STATUS.finished) {
        if (jobData?.output?.failed) {
          dispatch(setLoaderText({ message: "" }));
          reject({ data: jobData?.output?.errorResp });
          clearInterval(interval);
        } else {
          dispatch(setLoaderText({ message: "" }));
          resolve(jobData?.output?.successResp);
          clearInterval(interval);
        }
      }
      if (count === 2) {
        dispatch(
          setLoaderText({
            message: "Operation is in progress. This could take a while...",
          })
        );
      }
      if (count === 12) {
        dispatch(setLoaderText({ message: "" }));
        resolve({
          warning: true,
          message:
            "The current process is taking longer than expected. Revisit or refresh the page after some time.",
        });
        clearInterval(interval);
      }
    } catch (err) {
      reject(err);
    }
  }, 5000);
};

/**
 * This polling function should be removed and replaced with pollTaskStatus where timeout will reject the promise.
 * We need to update all places where poll function is called.
 */

class PollError extends Error {
  constructor(message = genericErrorMessage, type = "danger", timeout) {
    super();
    this.timeout = timeout;
    this.type = type;
    this.message = message;
  }
}
export const duplicatePollTaskStatusForSecurityAddonUpdateAsync = (
  httpClient,
  taskId,
  dispatch
) => {
  let count = 0;
  return new Promise((resolve, reject) => {
    let interval = setInterval(async () => {
      count++;
      try {
        const jobData = await httpClient.get(
          withUrlParams(API_TASK, { taskId }),
          taskId
        );
        if (jobData.status === CAM_JOBS_STATUS.finished) {
          if (jobData?.output?.failed) {
            const { message, type } = jobData?.output?.errorResp || {};
            dispatch(setLoaderText({ message: "" }));
            clearInterval(interval);
            throw new PollError(message, type);
          } else {
            const { message } = jobData?.output?.successResp || {};
            dispatch(setLoaderText({ message: "" }));
            clearInterval(interval);
            resolve({ message, type: "success" });
          }
        }
        if (count === 2) {
          dispatch(
            setLoaderText({
              message: "Operation is in progress. This could take a while...",
            })
          );
        }
        if (count === 12) {
          dispatch(setLoaderText({ message: "" }));
          clearInterval(interval);
          throw new PollError(
            "The current process is taking longer than expected. Revisit or refresh the page after some time.",
            "warning",
            true
          );
        }
      } catch (err) {
        reject(err);
      }
    }, 5000);
  });
};

export const pollTaskStatusAsync = (httpClient, taskId, dispatch) => {
  let count = 0;
  return new Promise((resolve, reject) => {
    let interval = setInterval(async () => {
      count++;
      try {
        const jobData = await httpClient.get(
          withUrlParams(API_TASK, { taskId }),
          taskId
        );
        if (jobData.status === CAM_JOBS_STATUS.finished) {
          if (jobData?.output?.failed) {
            dispatch(setLoaderText({ message: "" }));
            clearInterval(interval);
            reject({ data: jobData?.output?.errorResp });
          } else {
            dispatch(setLoaderText({ message: "" }));
            clearInterval(interval);
            resolve(jobData?.output?.successResp);
          }
        }
        if (count === 2) {
          dispatch(
            setLoaderText({
              message: "Operation is in progress. This could take a while...",
            })
          );
        }
        if (count === 12) {
          dispatch(setLoaderText({ message: "" }));
          clearInterval(interval);
          resolve({
            warning: true,
            message:
              "The current process is taking longer than expected. Revisit or refresh the page after some time.",
          });
        }
      } catch (err) {
        reject(err);
      }
    }, 5000);
  });
};

export const useJobTaskStatus = (taskID, config) => {
  const httpClient = useHttpClient();
  const dispatch = useDispatch();
  return useQuery(
    ["poll-task", taskID],
    () => pollTaskStatusAsync(httpClient, taskID, dispatch),
    { enabled: !!taskID, retry: false, ...config }
  );
};
