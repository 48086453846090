import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Modal } from "orion";

const ModalForm = ({
  header,
  size,
  docUrl,
  closeOnEscape,
  closeModal,
  isOpen,
  showResetButton,
  submitButtonLabel,
  cancelButtonLabel,
  resetButtonLabel,
  disableSubmitWhenInvalid,
  className,
  bodyClassName,
  ...formProps
}) => (
  <Form
    {...formProps}
    withValidation
    enableReinitialize
    renderChildren={(formikBag) => (
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        header={header}
        className={className}
        bodyClassName={bodyClassName}
        size={size}
        docUrl={docUrl}
        closeOnEscape={closeOnEscape}
        body={() =>
          typeof formProps.renderChildren === "function"
            ? formProps.renderChildren(formikBag)
            : null
        }
        footer={() => (
          <div>
            <Button
              type="secondary"
              label={cancelButtonLabel}
              onClick={closeModal}
            />
            {showResetButton && (
              <Button
                type="primary"
                label={resetButtonLabel}
                onClick={() => formikBag.resetForm()}
              />
            )}
            <Button
              type="primary"
              label={submitButtonLabel}
              onClick={() => formikBag.submitForm()}
              disabled={
                (disableSubmitWhenInvalid && !formikBag.isValid) ||
                !formikBag.dirty
              }
            />
          </div>
        )}
      />
    )}
  />
);

ModalForm.displayName = "ModalForm";

ModalForm.defaultProps = {
  closeModal: () => {},
  header: "",
  submitButtonLabel: "Submit",
  cancelButtonLabel: "Cancel",
  resetButtonLabel: "Reset",
  showResetButton: false,
  disableSubmitWhenInvalid: false,
};

ModalForm.propTypes = {
  header: PropTypes.string,
  size: PropTypes.string,
  docUrl: PropTypes.string,
  closeOnEscape: PropTypes.bool,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  showResetButton: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  resetButtonLabel: PropTypes.string,
  disableSubmitWhenInvalid: PropTypes.bool,
};

export default ModalForm;
