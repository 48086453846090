import { helpPortalFQDN } from "consts";

export const hasM365License = (mspInfo) =>
  mspInfo?.products
    ?.find((product) => product.productID === 2)
    ?.datasources?.find((datasource) => datasource.name === "M365")?.isEnabled;

export const getHelpPortalURL = (article) => {
  return `https://${helpPortalFQDN}/en/articles/${article}`;
};

export const parseJSON = (string) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return null;
  }
};
