import React, { useState } from "react";
import { ConfirmationModal, Heading, InformationModal } from "orion";
import { ExpiredRedOutline } from "@druva-apps/orion-icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { genericErrorMessage } from "consts";
import { showAlert } from "slices/alertsSlice";
import useDeleteServicePlan from "queryHooks/useDeleteServicePlan/useDeleteServicePlan";

const DeleteServicePlanActionModal = ({
  key,
  closeModal,
  servicePlanId,
  isDetailsPage,
}) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const { mutate } = useDeleteServicePlan(servicePlanId, {
    onError: (e) => {
      if (e.code === "Go_MSP_Portal-1101") {
        setStep(1);
      } else {
        dispatch(
          showAlert({
            message: e.data?.message || genericErrorMessage,
            type: "danger",
          }),
        );
      }
    },
    onSuccess: () => {
      if (isDetailsPage) {
        navigate("/service-plans");
      }
      closeModal();
      dispatch(
        showAlert({
          message: "Successfully deleted Service Plan",
          type: "success",
        }),
      );
    },
  });

  const confirmDeleteMessage = isDetailsPage
    ? "Are you sure you want to permanently delete this service plan?"
    : "This will delete the selected service plan from the list. Are you sure you want to delete selected service plan?";

  return (
    <React.Fragment key={key}>
      {step ? (
        <InformationModal
          isOpen
          toggle={closeModal}
          message={
            <div className="oui-d-flex">
              <ExpiredRedOutline className="oui-mr-2" />
              <Heading type="6" text="Unable to delete Service Plan" />
            </div>
          }
          secondaryMessage="The selected service plan is associated with one or more customers. Assign the customers to other service plans and then delete the service plan."
        />
      ) : (
        <ConfirmationModal
          isOpen
          toggle={closeModal}
          onContinue={() => mutate()}
          header="Delete Confirmation"
          message={confirmDeleteMessage}
          continueButtonText="Yes"
          cancelButtonText="No"
        />
      )}
    </React.Fragment>
  );
};

export default DeleteServicePlanActionModal;
