import { useQuery } from "react-query";
import { API_TENANTS, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";

export default function useTenant(tenantId, customerId, config) {
  const httpClient = useHttpClient();
  const URL = withUrlParams(API_TENANTS, { customerId, tenantId });

  return useQuery(
    [QUERIES.tenant, tenantId],
    () => {
      return httpClient.get(URL);
    },
    {
      ...config,
      enabled: !!tenantId,
    }
  );
}
