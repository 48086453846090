import React, { useState } from "react";
import Accordion from "orion/lib/accordion";
import Input from "orion/lib/input";
import Card from "orion/lib/card";
import InfoGreyOutline from "@druva-apps/orion-icons/lib/InfoGreyOutline";
import TickGreenFilled from "@druva-apps/orion-icons/lib/TickGreenFilled";
import ServicePlanFormFeatures from "./ServicePlanFormFeatures";
import { CAM_FEATURES, dataSourceTypes, SKU } from "consts";
import styles from "./ServicePlanForm.module.scss";
import { ReactComponent as GraySVG } from "./../../../assets/icons/grayTick.svg";

const SaaSAppAndEndpointsFeatures = ({ infoText, index, formikProps }) => {
  const { edition, features } = formikProps.values.products[index];

  const [activeItemId, setActiveItemId] = useState("");
  return (
    <Accordion
      activeItemId={activeItemId}
      onItemChange={(id) => setActiveItemId(id)}
      items={(features || []).map((feature, featureIndex) => ({
        id: `feature-${dataSourceTypes[feature.name]}`,
        title: dataSourceTypes[feature.name],
        noGutter: true,
        icon: (
          <div className={styles.grayTick}>
            {feature.isEnabled ? <TickGreenFilled size="sm" /> : <GraySVG />}
          </div>
        ),
        component: () => {
          const featureKey = `products[${index}].features[${featureIndex}].isEnabled`;
          return (
            <Card
              body={() => (
                <>
                  <Input
                    type="checkbox"
                    id={featureKey}
                    name={featureKey}
                    validationProps={formikProps}
                    checked={feature.isEnabled}
                    label={`Enable ${dataSourceTypes[feature.name]}`}
                    className="oui-my-2"
                    disabled={
                      edition?.value === SKU.business &&
                      feature.name === CAM_FEATURES.Endpoints
                    }
                  />
                  {infoText?.[feature.name] ? (
                    <div className="oui-d-flex oui-mb-4">
                      <InfoGreyOutline className="oui-mr-2 oui-my-auto" />
                      <div className="oui-text-muted">
                        {infoText?.[feature.name]}
                      </div>
                    </div>
                  ) : null}
                  {feature.isEnabled && (
                    <ServicePlanFormFeatures
                      formikProps={formikProps}
                      index={index}
                      featureDetails={feature}
                      featureIndex={featureIndex}
                    />
                  )}
                </>
              )}
            />
          );
        },
      }))}
    />
  );
};

export default SaaSAppAndEndpointsFeatures;
