import LockedIcon from "components/LockedIcon";
import SuspendedIcon from "components/SuspendedIcon";
import Col from "orion/lib/col";
import Input from "orion/lib/input";
import Row from "orion/lib/row";
import styles from "./securityAddonForm.module.scss";
import { dataSourceTypes } from "consts";
import DeletedIcon from "components/DeletedIcon";
import InfoIcon from "@druva-apps/orion-icons/lib/InfoGreyOutline";
import { Tooltip } from "orion";

const ARRRow = ({
  featureName,
  isLocked,
  isSuspended,
  isDeleted,
  formikProps,
  disabled,
  isExpired,
}) => {
  const disableRow =
    disabled || isSuspended || isLocked || isDeleted || isExpired;

  return (
    <Row className={`${styles.arrRow} ${disableRow ? styles.disabled : ""}`}>
      <Col sm={4} className="oui-d-flex oui-pl-0">
        <span className={styles.arrRowFeatureName}>
          {dataSourceTypes[featureName]}
        </span>
        {isLocked && <LockedIcon id={`${featureName}-locked-icon`} />}
        {isDeleted && <DeletedIcon id={`${featureName}-deleted-icon`} />}
        {!isDeleted && isSuspended && (
          <SuspendedIcon id={`${featureName}-suspended-icon`} />
        )}
        {!isDeleted && isExpired && !isSuspended && (
          <Tooltip text="License Expired">
            <div className="oui-d-flex oui-align-items-center oui-ml-2 oui-mr-2">
              <InfoIcon inheritFontColor />
            </div>
          </Tooltip>
        )}
      </Col>
      <Col sm={4}>
        <Input
          type="radio"
          id={`${featureName}-security-addon-core`}
          name={featureName}
          value="core"
          label="Security Posture and Observability"
          disabled={disableRow}
          checked={formikProps.values[featureName] === "core"}
          onChange={(e) => {
            formikProps.setFieldValue(featureName, e.target.value);
          }}
        />
      </Col>
      <Col sm={4}>
        <Input
          type="radio"
          id={`${featureName}-security-addon-advanced`}
          name={featureName}
          value="advanced"
          label="Accelerated Ransomware Recovery"
          disabled={disableRow}
          checked={formikProps.values[featureName] === "advanced"}
          onChange={(e) => {
            formikProps.setFieldValue(featureName, e.target.value);
          }}
        />
      </Col>
    </Row>
  );
};

export default ARRRow;
