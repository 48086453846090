import Tooltip from "orion/lib/tooltip";
import InactiveGreyOutline from "@druva-apps/orion-icons/lib/InactiveGreyOutline";

const SuspendedIcon = ({ id }) => (
  <Tooltip text="Product Suspended">
    <div className="oui-d-flex oui-align-items-center oui-text-warning oui-ml-2 oui-mr-2">
      <InactiveGreyOutline id={id} inheritFontColor />
    </div>
  </Tooltip>
);

export default SuspendedIcon;
