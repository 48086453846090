import { useMutation } from "react-query";
import { RESEND_DELETE_OTP, SEND_DELETE_OTP } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import useMspId from "queryHooks/useMspId/useMspId";

export default function useSendOTP(customerId, config, resend = false, otpId) {
  const httpClient = useHttpClient();

  const { data: mspIdData } = useMspId();
  const API_ENDPOINT = resend ? RESEND_DELETE_OTP : SEND_DELETE_OTP;
  const body = resend
    ? { otpId }
    : {
        action: "delete_now_tenant",
        arguments: {
          mspGlobalId: mspIdData?.mspId,
          customerGlobalId: customerId,
        },
      };
  return useMutation(
    () =>
      httpClient.post(
        withUrlParams(API_ENDPOINT, { customerId, mspId: mspIdData?.mspId }),
        body
      ),
    {
      ...config,
      onSuccess: (data) => {
        config?.onSuccess?.(data);
      },
    }
  );
}
