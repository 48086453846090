import { genericErrorMessage } from "consts";
import useCustomerTenants from "queryHooks/useCustomerTenants/useCustomerTenants";
import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import ModalForm from "shared/ModalForm";
import { showAlert } from "slices/alertsSlice";
import styles from "./CustomerForm.module.scss";
import FormContainer from "./FormContainer";
import useCreateCustomerForm from "./useCreateCustomerForm";
import useUpdateCustomerForm from "./useUpdateCustomerForm";

const validationSchemaCreator = (Yup) => {
  return Yup.object().shape({
    address: Yup.string()
      .required("Required")
      .max(100, "Max 100 chars")
      .test("emptyString", "Required", (value) => value?.trim().length > 0),
    phone: Yup.string()
      .required("Required")
      .min(6, "Min 6 chars")
      .max(30, "Max 30 chars"),
    customerName: Yup.string()
      .required("Required")
      .max(100, "Max 100 chars")
      .test("emptyString", "Required", (value) => value?.trim().length > 0),
    accountName: Yup.string().when("alternateNameToDruva", {
      is: true,
      then: Yup.string()
        .required("Required")
        .max(100, "Max 100 chars")
        .test("emptyString", "Required", (value) => value?.trim().length > 0),
    }),
  });
};

const CustomerForm = ({ closeModal, header, isOpen, isEdit, customerId }) => {
  const dispatch = useDispatch();
  const { current: useMutation } = useRef(
    isEdit ? useUpdateCustomerForm : useCreateCustomerForm
  );
  const [defaultInitialValues, { mutate }] = useMutation(customerId);

  const { data: tenants } = useCustomerTenants(
    { customerIDs: isEdit ? [customerId] : [], filters: ["customerIDs"] },
    {
      onError: (e) => {
        dispatch(
          showAlert({
            message: e.data?.message || genericErrorMessage,
            type: "danger",
          })
        );
      },
    }
  );
  const isSandboxCustomer =
    isEdit && (tenants || []).some((tenant) => tenant?.tenantType === 1);

  const onSubmitHandler = useCallback(
    (
      {
        accountName,
        address,
        phone,
        customerName,
        tenantAdmins,
        alternateNameToDruva,
      }
    ) => {
      mutate(
        {
          ...(!isEdit && {
            accountName: alternateNameToDruva
              ? accountName?.trim()
              : customerName?.trim(),
          }),
          address: address?.trim(),
          phone,
          customerName: customerName?.trim(),
          tenantAdmins: tenantAdmins.map(({ value }) => value),
        },
        {
          onError: (e) => {
            const message = e?.message || e?.data?.message;
            const type = e?.type || e?.data?.type;
            dispatch(
              showAlert({
                message: message || genericErrorMessage,
                type: type || "danger",
              })
            );
          },
          onSuccess: (res) => {
            const alertMessage = {
              message: res.warning
                ? res.message
                : `Customer ${isEdit ? "updated" : "created"} successfully`,
              type: res.warning ? "warning" : "success",
            };
            dispatch(showAlert(alertMessage));
            closeModal();
          },
        }
      );
    },
    [dispatch, isEdit, mutate, closeModal]
  );
  return (
    <>
      {defaultInitialValues && (
        <ModalForm
          className={styles.wizard}
          disableSubmitWhenInvalid
          submitButtonLabel="Save"
          closeModal={closeModal}
          withValidation
          header={header}
          isOpen={isOpen}
          size="lg"
          onSubmit={onSubmitHandler}
          initialValues={defaultInitialValues}
          isValid={false}
          validationSchema={validationSchemaCreator}
          renderChildren={(props) => (
            <FormContainer
              formikProps={props}
              isEdit={isEdit}
              isSandboxCustomer={isSandboxCustomer}
            />
          )}
        />
      )}
    </>
  );
};

CustomerForm.defaultProps = {
  isEdit: false,
};

export default CustomerForm;
