import React, { useCallback } from "react";
import { Modal, Button, Row, Col } from "orion";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InfoGreyOutline from "@druva-apps/orion-icons/lib/InfoGreyOutline";
import CopyGreyOutline from "@druva-apps/orion-icons/lib/CopyGreyOutline";
import { showAlert } from "slices/alertsSlice";
import styles from "./ClientCredentialsModal.module.scss";

const ClientSecretGeneratedModal = ({ header, key, closeModal, client }) => {
  const dispatch = useDispatch();

  const onCopyClientSecret = useCallback(() => {
    dispatch(
      showAlert({
        message: "Client Secret Key copied to clipboard.",
        type: "success",
      })
    );
  }, [dispatch]);

  const onCopyClientId = useCallback(() => {
    dispatch(
      showAlert({
        message: "Client ID copied to clipboard.",
        type: "success",
      })
    );
  }, [dispatch]);

  return (
    <Modal
      header={header}
      key={key}
      size="sm"
      isOpen
      toggle={closeModal}
      body={() => (
        <>
          <Row className="oui-mb-3">
            <Col sm={3}>Name:</Col>
            <Col sm={9}>{client?.name}</Col>
          </Row>
          <Row className="oui-mb-3">
            <Col sm={3}>Client ID:</Col>
            <Col sm={9} className={styles.wordBreak}>
              {client?.client_id}
              &nbsp; &nbsp;
              <CopyToClipboard
                onCopy={onCopyClientId}
                text={client?.client_id}
                className="did-copy-btn"
              >
                <CopyGreyOutline />
              </CopyToClipboard>
            </Col>
          </Row>
          <Row className="oui-mb-3">
            <Col sm={3}>Secret Key:</Col>
            <Col sm={9} className={styles.wordBreak}>
              {client?.client_secret}
              &nbsp; &nbsp;
              <CopyToClipboard
                onCopy={onCopyClientSecret}
                text={client?.client_secret}
                className="did-copy-btn"
              >
                <CopyGreyOutline />
              </CopyToClipboard>
            </Col>
          </Row>
          <Row className="oui-mb-3">
            <Col>
              <InfoGreyOutline /> Please copy the secret key before closing the
              window. Secret Key requires regeneration.
            </Col>
          </Row>
        </>
      )}
      footer={() => {
        return (
          <div>
            <Button type="primary" label="Close" onClick={closeModal} />
          </div>
        );
      }}
    />
  );
};

export default ClientSecretGeneratedModal;
