import {
  PRODUCT_ID,
  SECURITY_ADDON_ADVANCED,
  TENANT_STATUS,
  ARR_WORKLOAD_NAMES,
  SECURITY_ADDON_CORE,
  sassAppsAndEndpointDataSourceTypes,
  hybridWorkloadsDataSourceTypes,
  dataSourceTypes,
  CUSTOMER_STATUS,
} from "consts";
import { useMemo } from "react";
import values from "lodash/values";
import difference from "lodash/difference";
import { flatten, intersection, keys } from "lodash";

export const isTenantDeleted = (tenant) =>
  tenant?.status === TENANT_STATUS.deleted;

export const isTenantSuspended = (tenant) =>
  tenant?.status === TENANT_STATUS.suspended;

export const isTenantCreatingOrUpdating = (tenant) =>
  tenant?.status === TENANT_STATUS.pending ||
  tenant?.status === TENANT_STATUS.updating;

export const isHybridWorkloadsDeleted = (customerData) =>
  customerData?.hybridWorkloadsStatus === 3;

export const isM365Deleted = (customerData) =>
  customerData?.m365Details?.status === 3;

export const isHybridWorkloadsSuspended = (customerData) =>
  customerData?.hybridWorkloadsStatus === 2;

export const isM365Suspended = (customerData) =>
  customerData?.m365Details?.status === 2;

export const isActionBlockedOnCustomer = (customerInfo, mspInfo) => {
  const mspHasHybridWorkload = (mspInfo?.productDetails || []).some(
    (product) => product.datasourceType === "Hybrid Workloads"
  );
  const mspHasM365 = (mspInfo?.productDetails || []).some(
    (product) => product.datasourceType === "M365"
  );

  if (mspHasHybridWorkload && mspHasM365) {
    return (
      (isHybridWorkloadsSuspended(customerInfo) ||
        isHybridWorkloadsDeleted(customerInfo)) &&
      (isM365Suspended(customerInfo) || isM365Deleted(customerInfo))
    );
  } else if (mspHasHybridWorkload && !mspHasM365) {
    return (
      isHybridWorkloadsSuspended(customerInfo) ||
      isHybridWorkloadsDeleted(customerInfo)
    );
  } else if (mspHasM365 && !mspHasHybridWorkload) {
    return isM365Suspended(customerInfo) || isM365Deleted(customerInfo);
  } else {
    return false;
  }
};

export const hasAccessTenantConsoleEnabled = (tenants) => {
  if (tenants?.length > 0) {
    return tenants.some((tenant) => tenant.status === 1);
  }
  return false;
};

export const getAccessTenantConsoleTooltipText = (tenants) => {
  if (tenants?.length === 0) {
    return "Add product to access the tenant console";
  }
  if (tenants?.length > 0 && !hasAccessTenantConsoleEnabled(tenants)) {
    return "No active products assigned to customer";
  }
  return "Click here to access the tenant console";
};

export const useTenantExpiryDate = (tenant) => {
  return useMemo(() => {
    const expiryDate = new Date(tenant?.licenseExpiryDate);
    return !Number.isNaN(expiryDate.getTime()) ? expiryDate : null;
  }, [tenant]);
};

export const useArrTenantDetails = (tenants, customerDetails) => {
  const saasAppsAndEnpointsTenant = tenants?.find(
    (tenant) => tenant.productID === PRODUCT_ID.saasAppsAndEndpoints
  );

  const hybridWorkloadsTenant = tenants?.find(
    (tenant) => tenant.productID === PRODUCT_ID.hybridWorkloads
  );

  const sassAppsExpiryDate = useTenantExpiryDate(saasAppsAndEnpointsTenant);

  const hybridWorkloadsExpiryDate = useTenantExpiryDate(hybridWorkloadsTenant);

  return useMemo(() => {
    const isSaasAppsSuspended =
      saasAppsAndEnpointsTenant?.status === TENANT_STATUS.suspended;

    const isHybridWorkloadsSuspended =
      hybridWorkloadsTenant?.status === TENANT_STATUS.suspended;

    const isSassAppsDeleted = isTenantDeleted(saasAppsAndEnpointsTenant);
    const isHybridWorkloadsDeleted = isTenantDeleted(hybridWorkloadsTenant);

    const advancedsaasAppsAndEnpointsFeatures =
      saasAppsAndEnpointsTenant?.features
        ?.filter((feature) =>
          values(SECURITY_ADDON_ADVANCED).includes(feature.name)
        )
        ?.map((enabledFeature) => ARR_WORKLOAD_NAMES[enabledFeature.name]) ||
      [];

    const advancedhybridWorkloadsFeatures =
      hybridWorkloadsTenant?.features
        ?.filter((feature) =>
          values(SECURITY_ADDON_ADVANCED).includes(feature.name)
        )
        ?.map((enabledFeature) => ARR_WORKLOAD_NAMES[enabledFeature.name]) ||
      [];

    const coreFeaturesIsEnabled = customerDetails?.features
      .map((feature) => feature.name)
      ?.includes(SECURITY_ADDON_CORE);

    const coresaasAppsAndEnpointsFeatures =
      coreFeaturesIsEnabled && saasAppsAndEnpointsTenant
        ? difference(
            values(sassAppsAndEndpointDataSourceTypes),
            advancedsaasAppsAndEnpointsFeatures
          )
        : [];

    const corehybridWorkloadsFeatures =
      coreFeaturesIsEnabled && hybridWorkloadsTenant
        ? difference(
            values(hybridWorkloadsDataSourceTypes),
            advancedhybridWorkloadsFeatures
          )
        : [];

    const isSaasAppslocked = isTenantCreatingOrUpdating(
      saasAppsAndEnpointsTenant
    );

    const isHybridWorkloadslocked = isTenantCreatingOrUpdating(
      hybridWorkloadsTenant
    );

    const lockedFeatures = flatten([
      isSaasAppslocked ? values(sassAppsAndEndpointDataSourceTypes) : [],
      isHybridWorkloadslocked ? values(hybridWorkloadsDataSourceTypes) : [],
    ]);

    const suspendedFeatures = flatten([
      isSaasAppsSuspended ? values(sassAppsAndEndpointDataSourceTypes) : [],
      isHybridWorkloadsSuspended ? values(hybridWorkloadsDataSourceTypes) : [],
    ]);

    const deletedFeatures = [
      ...(isSassAppsDeleted ? values(sassAppsAndEndpointDataSourceTypes) : []),
      ...(isHybridWorkloadsDeleted
        ? values(hybridWorkloadsDataSourceTypes)
        : []),
    ];

    const isSassAppsExpired =
      sassAppsExpiryDate && sassAppsExpiryDate.getTime() < Date.now();

    const isHybridExpired =
      hybridWorkloadsExpiryDate &&
      hybridWorkloadsExpiryDate.getTime() < Date.now();

    return {
      isSaasAppsSuspended,
      isHybridWorkloadsSuspended,
      isSassAppsDeleted,
      isHybridWorkloadsDeleted,
      advancedsaasAppsAndEnpointsFeatures,
      advancedhybridWorkloadsFeatures,
      coresaasAppsAndEnpointsFeatures,
      corehybridWorkloadsFeatures,
      coreFeaturesIsEnabled,
      isSaasAppslocked,
      isHybridWorkloadslocked,
      lockedFeatures,
      suspendedFeatures,
      deletedFeatures,
      isCustomerLocked:
        customerDetails?.status === CUSTOMER_STATUS.tenantProcessing,
      isSassAppsExpired,
      isHybridExpired,
    };
  }, [
    customerDetails?.features,
    customerDetails?.status,
    hybridWorkloadsExpiryDate,
    hybridWorkloadsTenant,
    saasAppsAndEnpointsTenant,
    sassAppsExpiryDate,
  ]);
};

export const isCustomerUpdating = (customerDetails) =>
  customerDetails?.status === CUSTOMER_STATUS.tenantProcessing;

export const useLicensedDataSources = (tenants) => {
  return useMemo(() => {
    const features = flatten(
      tenants?.map((tenant) => {
        return tenant?.features?.map((feature) => feature.name);
      }) || []
    );

    return intersection(keys(dataSourceTypes), features);
  }, [tenants]);
};
