import { useQuery } from "react-query";

import { API_CUSTOMER, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";

export default function useCustomer(customerId, config) {
  const httpClient = useHttpClient();
  const URL = withUrlParams(API_CUSTOMER, { customerId });
  return useQuery(
    [QUERIES.customer, customerId],
    () => {
      return httpClient.get(URL);
    },
    {
      ...config,
    }
  );
}
