export const loadSessionStorageState = () => {
  try {
    const serializableState = sessionStorage.getItem("msp_state");

    if (serializableState !== null) {
      return JSON.parse(serializableState);
    }
  } catch (err) {
    return undefined;
  }
};

export const saveSessionStorageState = (state) => {
  try {
    const serializableState = JSON.stringify(state);

    sessionStorage.setItem("msp_state", serializableState);
  } catch (err) {
    console.warn(
      "An error occurred while saving the state. Error details: ",
      err
    );
  }
};

export const removeSessionStorageState = () => {
  try {
    sessionStorage.removeItem("msp_state");
  } catch (err) {
    console.warn(
      "An error occurred while removing the state. Error details: ",
      err
    );
  }
};
