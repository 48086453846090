import React from "react";
import {
  Select,
  Input,
  Label,
  Row,
  Col,
  FormGroup,
  InputFeedback,
} from "orion";

import { backupFrequencyLimitUnitDictionary } from "consts";

const options = Object.entries(backupFrequencyLimitUnitDictionary).map(
  ([value, label]) => ({ value, label })
);

const BackupFrequencyLimitInput = ({ formikProps, index, featureIndex }) => {
  const product =
    formikProps.values?.products?.[index]?.features?.[featureIndex]?.attrs;
  const touched =
    formikProps.touched?.products?.[index]?.features?.[featureIndex]?.attrs;
  const errors =
    formikProps.errors?.products?.[index]?.features?.[featureIndex]?.attrs;
  const hasMinError =
    touched?.backupFrequencyLimitMin &&
    errors?.backupFrequencyLimitMin !== undefined;

  const hasMaxError =
    touched?.backupFrequencyLimitMax &&
    errors?.backupFrequencyLimitMax !== undefined;

  const hasUnitError =
    touched?.backupFrequencyLimitUnit &&
    errors?.backupFrequencyLimitUnit !== undefined;

  let frequencyText = "";
  if (
    product.backupFrequencyLimitUnit &&
    product.backupFrequencyLimitUnit.value
  ) {
    const val = product.backupFrequencyLimitUnit.value;
    frequencyText = val === "weekly" ? "times a week" : "times a day";
  }

  return (
    <FormGroup row className="oui-mb-3">
      <Label muted text="Backup Frequency Limit" sm={3} />
      <Col sm={2}>
        <Select
          options={options}
          name={`products[${index}].features[${featureIndex}].attrs.backupFrequencyLimitUnit`}
          value={product.backupFrequencyLimitUnit}
          isInvalid={hasUnitError}
          isClearable
          menuPosition="fixed"
          validationProps={formikProps}
          dataTestId={`backupFrequencyLimitUnit-select-${index}-${featureIndex}`}
          onChange={(val) => {
            if (!val) {
              formikProps.setFieldValue(
                `products[${index}].features[${featureIndex}].attrs.backupFrequencyLimitMin`,
                0
              );
              formikProps.setFieldValue(
                `products[${index}].features[${featureIndex}].attrs.backupFrequencyLimitMax`,
                0
              );
            }
            formikProps.setFieldValue(
              `products[${index}].features[${featureIndex}].attrs.backupFrequencyLimitUnit`,
              val
            );
          }}
        />
      </Col>
      <Col sm={3}>
        <Row>
          <Col sm={6}>
            <Input
              type="number"
              name={`products[${index}].features[${featureIndex}].attrs.backupFrequencyLimitMin`}
              validationProps={formikProps}
              isInvalid={hasMinError}
              dataTestId={`backupFrequencyLimitMin-input-${index}-${featureIndex}`}
              disabled={!product.backupFrequencyLimitUnit}
            />
            {!hasMinError && !hasUnitError && !hasMaxError && (
              <InputFeedback
                message="min"
                className="oui-text-secondary oui-text-center oui-font-italic"
                show
              />
            )}
          </Col>
          <Col sm={6}>
            <Input
              type="number"
              name={`products[${index}].features[${featureIndex}].attrs.backupFrequencyLimitMax`}
              validationProps={formikProps}
              isInvalid={hasMaxError}
              dataTestId={`backupFrequencyLimitMax-input-${index}-${featureIndex}`}
              disabled={!product.backupFrequencyLimitUnit}
            />
            {!hasMinError && !hasUnitError && !hasMaxError && (
              <InputFeedback
                message="max"
                className="oui-text-secondary oui-text-center oui-font-italic"
                show
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col sm={3} className="oui-pl-0 oui-mb-0">
        <Label text={frequencyText} className="oui-col-form-label" />
      </Col>
    </FormGroup>
  );
};

export default BackupFrequencyLimitInput;
