import React, { useMemo } from "react";
import { getIn } from "formik";
import { FormGroup, Label, Col, Input, InputFeedback, Tooltip } from "orion";
import { InfoGreyOutline } from "@druva-apps/orion-icons";
import GenericOptionsSelect from "components/GenericOptionsSelect";
import UpgradeSkuSelect from "components/UpgradeSkuSelect";
import PhoneInput from "shared/PhoneInput/PhoneInput";

import { genericSelectTypes } from "consts";

const ModalFormField = ({
  name,
  label,
  placeholder,
  formikProps,
  type,
  isMulti,
  dataTestId,
  info,
  unit,
  disabled,
  tooltip,
  className,
  customInput,
  isInvalid: customIsInvalid,
}) => {
  const isInvalid = useMemo(
    () =>
      customIsInvalid !== undefined
        ? customIsInvalid
        : getIn(formikProps.touched, name) &&
          getIn(formikProps.errors, name) !== undefined,
    [customIsInvalid, formikProps.errors, formikProps.touched, name]
  );

  const ChosenInput = customInput ? (
    React.cloneElement(customInput, { formikProps, isInvalid })
  ) : type === "phone" ? (
    <PhoneInput
      name={name}
      value={getIn(formikProps.values, name)}
      formikProps={formikProps}
      isInvalid={isInvalid}
      onChange={(phone) => {
        formikProps.setFieldValue(name, phone);
      }}
      dataTestId={dataTestId}
      placeholder={placeholder}
      disabled={disabled}
    />
  ) : type === "number" ? (
    <Input
      type="number"
      name={name}
      id={name}
      validationProps={formikProps}
      data-testid={dataTestId}
      disabled={disabled}
      inline
      isInvalid={isInvalid}
    />
  ) : type === genericSelectTypes.storageRegions ||
    type === genericSelectTypes.usageReportsFrequencies ||
    type === genericSelectTypes.servicePlanWorkloads ? (
    <GenericOptionsSelect
      isMulti={isMulti}
      name={name}
      type={type}
      placeholder={placeholder}
      formikProps={formikProps}
      dataTestId={dataTestId}
      isInvalid={isInvalid}
      disabled={disabled}
    />
  ) : type === genericSelectTypes.skus ? (
    <UpgradeSkuSelect
      name={name}
      placeholder={placeholder}
      formikProps={formikProps}
      dataTestId={dataTestId}
      isInvalid={isInvalid}
      disabled={disabled}
    />
  ) : (
    <Input
      type="text"
      name={name}
      id={name}
      validationProps={formikProps}
      isInvalid={isInvalid}
      data-testid={dataTestId}
      disabled={disabled}
    />
  );
  return (
    <FormGroup row className={`${className} oui-mb-0`}>
      <Col sm={4}>
        <Label className="oui-col-form-label" muted text={label} />
        {tooltip && (
          <Tooltip maxWidth={205} placement="bottom" text={tooltip}>
            <InfoGreyOutline className="oui-ml-1" />
          </Tooltip>
        )}
      </Col>
      <Col
        sm={unit ? 2 : type === "date" ? 5 : 6}
        className={`oui-mt-auto ${isInvalid || info ? "oui-mb-1" : "oui-mb-4"}`}
      >
        {ChosenInput}
        {!isInvalid && info && (
          <InputFeedback message={info} className="oui-text-secondary" show />
        )}
      </Col>
      {unit && <Label text={unit} sm={6} className="oui-ml-n3" />}
    </FormGroup>
  );
};

ModalFormField.defaultProps = {
  className: "",
  type: "text",
  disabled: false,
};

export default ModalFormField;
