import { useQuery } from "react-query";
import { API_SKU_LIST, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import useMspId from "queryHooks/useMspId/useMspId";
import withUrlParams from "../../utils/withUrlParams";

export default function useSkuList(config) {
  const httpClient = useHttpClient();
  const { data } = useMspId();

  return useQuery(
    [QUERIES.skuList, data?.mspId],
    () => {
      return httpClient.get(
        withUrlParams(API_SKU_LIST, { mspId: data?.mspId })
      );
    },
    {
      enabled: !!data?.mspId,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      ...config,
    }
  );
}
