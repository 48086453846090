import { useMutation, useQueryClient } from "react-query";
import { API_SSO_CONFIG, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";

export default function useUpdateSSOConfig(config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();

  const updateSSOConfig = (body) => httpClient.put(API_SSO_CONFIG, body);

  return useMutation(updateSSOConfig, {
    ...config,
    onSuccess: (data) => {
      queryClient.invalidateQueries(QUERIES.getSSOConfig);
      config?.onSuccess?.(data);
    },
  });
}
