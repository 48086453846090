import React from "react";
import PhoneInput2 from "react-phone-input-2";
import { getIn } from "formik";
import { InputFeedback } from "orion";

import "./style.scss";

const BLOCKED_COUNTRIES_PHONE = ["kp", "ua", "cu", "ir", "ss", "sd", "sy"];
const PREFFERED_COUNTRIES_PHONE = ["us", "gb"];

const PhoneInput = ({
  name,
  value,
  onChange,
  isInvalid,
  formikProps,
  dataTestId,
  placeholder = "(202-555-5555)",
  disabled,
}) => {
  return (
    <>
      <PhoneInput2
        value={value}
        onBlur={() => {
          formikProps.setFieldTouched(name, true);
        }}
        placeholder={placeholder}
        onChange={onChange}
        excludeCountries={BLOCKED_COUNTRIES_PHONE}
        preferredCountries={PREFFERED_COUNTRIES_PHONE}
        preserveOrder={["preferredCountries"]}
        inputClass={`oui-form-control${isInvalid ? " oui-is-invalid" : ""}`}
        buttonClass={`oui-form-control oui-mr-3${
          isInvalid ? " oui-is-invalid" : ""
        }`}
        inputProps={{
          "data-testid": dataTestId,
        }}
        disabled={disabled}
      />
      <InputFeedback
        message={getIn(formikProps.errors, name)}
        show={isInvalid}
        className="phone-input-feedback"
      />
    </>
  );
};

export default PhoneInput;
