import React from "react";
import { ConfirmationModal } from "orion";
import { useDispatch } from "react-redux";

import { genericErrorMessage } from "consts";
import { showAlert } from "slices/alertsSlice";
import useDeleteClient from "queryHooks/useDeleteClient/useDeleteClient";

const DeleteClientCredentialsModal = ({
  key,
  closeModal,
  clientId,
  setSelected,
}) => {
  const dispatch = useDispatch();
  const { mutate } = useDeleteClient(clientId, {
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
    onSuccess: () => {
      setSelected(null);
      closeModal();
      dispatch(
        showAlert({
          message: "Client credential deleted successfully.",
          type: "success",
        })
      );
    },
  });

  return (
    <ConfirmationModal
      key={key}
      isOpen
      toggle={closeModal}
      onContinue={() => mutate()}
      header="Delete Confirmation"
      message="If you delete this Credential, any existing connections associated with this Credential would terminate immediately."
      secondaryMessage="Are you sure you want to delete this credential?"
      continueButtonText="Yes, Delete"
      continueButtonType="danger"
      cancelButtonText="Cancel"
    />
  );
};

export default DeleteClientCredentialsModal;
