import React from "react";
import { ConfirmationModal } from "orion";
import { useDispatch } from "react-redux";

import { genericErrorMessage } from "consts";
import { showAlert } from "slices/alertsSlice";
import useGenerateClientSecret from "queryHooks/useGenerateClientSecret/useGenerateClientSecret";
import { openModal } from "slices/modalsSlice";

const GenerateClientSecretModal = ({ key, closeModal, client }) => {
  const dispatch = useDispatch();
  const { mutate } = useGenerateClientSecret(client?.clientId, {
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
    onSuccess: (data) => {
      closeModal();
      dispatch(
        openModal({
          key: "EVENT_CLIENT_SECRET_KEY_GENERATED",
          header: "New Client Secret Key",
          client: { name: client.name, ...data },
        })
      );
    },
  });

  return (
    <ConfirmationModal
      key={key}
      isOpen
      toggle={closeModal}
      onContinue={() => mutate()}
      header="Confirm Secret Key Generation"
      message="Generating a new secret key will invalidate existing one and all APIs using this credential will not work till they are updated with new secret key."
      secondaryMessage="Are you sure you want to proceed?"
      continueButtonText="Generate"
      cancelButtonText="Cancel"
    />
  );
};

export default GenerateClientSecretModal;
