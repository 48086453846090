import ServicePlanForm from "pages/ServicePlans/ServicePlanForm/ServicePlanForm";
import AdministratorForm from "pages/Settings/Administrators/AdministratorForm/AdministratorForm";
import ContactForm from "pages/Customer/ContactForm/ContactForm";
import APICredentialsForm from "pages/Settings/APICredentials/APICredentialsForm/APICredentialsForm";
import SuspendCustomerTenantActionModal from "components/SuspendCustomerTenantAction/SuspendCustomerTenantActionModal";
import DeleteCustomerTenantAction from "components/DeleteCustomerAction/DeleteCustomerActionModal";
import UndeleteCustomerTenantAction from "components/DeleteCustomerAction/UndeleteCustomerActionModal";
import DeleteServicePlanAction from "components/DeleteServicePlanAction/DeleteServicePlanActionModal";
import DeleteCustomerContactAction from "components/DeleteCustomerContactAction/DeleteCustomerContactActionModal";
import DeleteAdminAction from "components/DeleteAdminAction/DeleteAdminActionModal";
import EventDetails from "pages/Events/EventList/EventDetailsModal/EventDetailsModal";

import ClientSecretGeneratedModal from "components/ClientCredentialsAction/ClientSecretGeneratedModal";
import DeleteClientCredentialsModal from "components/ClientCredentialsAction/DeleteClientCredentialsModal";
import GenerateClientSecretModal from "components/ClientCredentialsAction/GenerateClientSecretModal";
import EditIdleSessionTimeoutModal from "pages/Settings/AccessSettings/IdleSessionTimeout/EditIdleSessionTimeoutModal";
import CustomerForm from "pages/Customers/CustomerForm/CustomerForm";
import TenantForm from "pages/Customer/Tenants/TenantForm";
import DeleteCustomerModal from "components/DeleteCustomerAction/DeleteCustomerModal";
import MFAWarningModal from "pages/Settings/AccessSettings/SingleSignOn/MFAWarningModal";
import DisableFailSafeWarningMOdal from "pages/Settings/AccessSettings/SingleSignOn/DisableFailSafeWarningModal";
import EditSingleSignOnModal from "pages/Settings/AccessSettings/SingleSignOn/EditSingleSignOnModal";
import GenerateSSOTokenModal from "pages/Settings/AccessSettings/SingleSignOn/GenerateSSOToken";
import SecurityAddonForm from "pages/Customer/Tenants/securityAddonForm/SecurityAddonForm";

export const formViews = {
  SERVICE_PLAN_FORM: ServicePlanForm,
  CONTACT_FORM: ContactForm,
  ADMINISTRATOR_FORM: AdministratorForm,
  CREDENTIALS_FORM: APICredentialsForm,
  EDIT_IDLE_SESSION_TIMEOUT: EditIdleSessionTimeoutModal,
  CUSTOMER_FORM: CustomerForm,
  TENANT_FORM: TenantForm,
  MFA_WARNING_MODAL: MFAWarningModal,
  DISABLE_FAILSAFE_MODAL: DisableFailSafeWarningMOdal,
  EDIT_SINGLE_SIGNON_MODAL: EditSingleSignOnModal,
  GENERATE_SSO_TOKEN_MODAL: GenerateSSOTokenModal,
  SECURITY_ADDON_FORM: SecurityAddonForm,
};

export const simpleViews = {
  SUSPEND_CUSTOMER_TENANT_ACTION: SuspendCustomerTenantActionModal,
  DELETE_CUSTOMER_TENANT_ACTION: DeleteCustomerTenantAction,
  DELETE_CUSTOMER: DeleteCustomerModal,
  UNDELETE_CUSTOMER_TENANT_ACTION: UndeleteCustomerTenantAction,
  DELETE_SERVICE_PLAN_ACTION: DeleteServicePlanAction,
  DELETE_CUSTOMER_CONTACT_ACTION: DeleteCustomerContactAction,
  DELETE_ADMIN_ACTION: DeleteAdminAction,
  EVENT_DETAILS: EventDetails,
  EVENT_CLIENT_SECRET_KEY_GENERATED: ClientSecretGeneratedModal,
  DELETE_API_CREDENTIALS_ACTION: DeleteClientCredentialsModal,
  GENERATE_SECRET_KEY_ACTION: GenerateClientSecretModal,
};
