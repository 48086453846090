import { useMutation, useQueryClient } from "react-query";
import { API_ADMIN_UPDATE, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import { pollTaskStatusAsync } from "queryHooks/useJobStatus/useJobStatus";
import { useDispatch } from "react-redux";

export default function useUpdateAdministrator(adminId, config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const updateAdministrator = async (data) => {
    const response = await httpClient.put(
      withUrlParams(API_ADMIN_UPDATE, { adminId }),
      data
    );
    if (response?.task?.id) {
      const pollResponse = await pollTaskStatusAsync(
        httpClient,
        response?.task?.id,
        dispatch
      );
      return pollResponse;
    }
    return response;
  };

  return useMutation((data) => updateAdministrator(data), {
    ...config,
    onSuccess: (data) => {
      queryClient.invalidateQueries(QUERIES.administratorListV2);
      queryClient.invalidateQueries(QUERIES.administrator);
      queryClient.invalidateQueries(QUERIES.customer);
      config?.onSuccess?.(data);
    },
  });
}
