import React, { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { Button, Form, FormGroup } from "orion";

import OTPComponent from "shared/OTPComponent";

const OTPVerificationComponent = ({
  sendOtpCallback,
  resendOtpCallback,
  otpExpiry,
  formikInitialValues,
  onOtpChangeCallback,
}) => {
  const [initialValues, setInitialValues] = useState(formikInitialValues);

  useEffect(() => {
    setInitialValues({
      ...{
        otpCode: "",
        otpVerificationActive: formikInitialValues.otpVerificationActive,
      },
      ...formikInitialValues,
    });
  }, [formikInitialValues]);

  const VerificationSchemaCreator = (Yup) =>
    Yup.object().shape({
      otpCode: Yup.string().length(6).required(),
      otpVerificationActive: Yup.bool().oneOf([true, false]),
    });

  const BEValidationMessage = "Invalid verification code";

  const countDownRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <span
          className="oui-small oui-text-danger oui-ml-2"
          data-testid="login-mfa-expiration-lbl"
        >
          Verification code expired
        </span>
      );
    } else {
      return (
        <>
          <span
            className="oui-small"
            data-testid="login-mfa-expiration-lbl oui-ml-1"
          >
            The verification code expires in
          </span>
          <span className="oui-ml-1">
            {minutes}:{zeroPad(seconds)}
          </span>
        </>
      );
    }
  };

  return (
    <>
      <div className="oui-card-body ">
        <div className="oui-mb-2 oui-ml-1" data-testid="login-mfa-prompt-lbl">
          Enter the verification OTP that will be sent to your registered email
          address.
        </div>
        <Form
          validationSchema={VerificationSchemaCreator}
          withValidation
          enableReinitialize
          initialValues={initialValues}
          className={"form-container"}
          renderChildren={(props) => (
            <>
              <FormGroup
                className={`input oui-mb-1 oui-ml-1`}
                dataTestId="login-mfa-code-form"
                row
              >
                <Button
                  label="Send OTP"
                  dataTestId="login-mfa-verify-btn"
                  type="secondary"
                  onClick={sendOtpCallback}
                  className="oui-mb-2 oui-mr-3"
                  disabled={initialValues.otpVerificationActive}
                />
                <input className="oui-d-none" type="submit" />
                <OTPComponent
                  OTPLength={6}
                  value={props.values.otpCode}
                  inputClassName={`oui-form-control oui-font-weight-bold oui-mr-2 ${
                    props.errors.otpCode === BEValidationMessage
                      ? "oui-is-invalid"
                      : ""
                  }`}
                  onChange={(otpCode) => {
                    props.setFieldValue("otpCode", otpCode);
                    onOtpChangeCallback(otpCode);
                  }}
                  disabled={!initialValues.otpVerificationActive}
                  errorMsg={
                    props.errors.otpCode === BEValidationMessage
                      ? BEValidationMessage
                      : null
                  }
                />
              </FormGroup>

              {initialValues.otpVerificationActive && (
                <div className="oui-mb-3 oui-ml-1">
                  <Countdown
                    date={otpExpiry}
                    renderer={countDownRenderer}
                    // onComplete={() => {
                    //   props.setFieldValue("otpVerificationActive", false);
                    // }}
                  />
                  <Button
                    type="link"
                    label="Resend OTP"
                    onClick={resendOtpCallback}
                    disabled={!initialValues.otpVerificationActive}
                    size="sm"
                    className="oui-text-decoration-none oui-ml-2"
                    dataTestId="login-mfa-resend-btn"
                  />
                </div>
              )}
            </>
          )}
        />
      </div>
    </>
  );
};

export default OTPVerificationComponent;
