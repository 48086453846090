import { NameRenderer, NewRenderer, OldRenderer } from "../eventHistoryUtils";

const columnsDef = [
  {
    key: "name",
    dataKey: "name",
    title: "Name",
    flexGrow: 2,
    truncateText: true,
    resizable: true,
    cellRenderer: (cellData, dataKey, rowINdex, rowData) => (
      <NameRenderer rowData={rowData} />
    ),
  },
  {
    key: "old",
    dataKey: "old",
    title: "Old",
    flexGrow: 1,
    truncateText: true,
    resizable: true,
    cellRenderer: (cellData, dataKey, rowINdex, rowData) => (
      <OldRenderer rowData={rowData} />
    ),
  },
  {
    key: "new",
    dataKey: "new",
    title: "New",
    flexGrow: 1,
    truncateText: true,
    resizable: true,
    cellRenderer: (cellData, dataKey, rowINdex, rowData) => (
      <NewRenderer rowData={rowData} />
    ),
  },
];

export default columnsDef;
