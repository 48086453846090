import { useMutation, useQueryClient } from "react-query";
import { API_CUSTOMER_TENANTS, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import { pollTaskStatus } from "queryHooks/useJobStatus/useJobStatus";
import { useDispatch } from "react-redux";

export default function useCreateTenant(customerId, config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const createTenant = (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await httpClient.post(
          withUrlParams(API_CUSTOMER_TENANTS, { customerId }),
          data
        );
        pollTaskStatus(httpClient, res?.task?.id, resolve, reject, dispatch);
      } catch (err) {
        reject(err);
      }
    });

  return useMutation((data) => createTenant(data), {
    ...config,
    onSuccess: (data) => {
      queryClient.invalidateQueries(QUERIES.tenant);
      queryClient.invalidateQueries(QUERIES.customersTenants);
      queryClient.invalidateQueries(QUERIES.customersWithTenants);
      config?.onSuccess?.(data);
    },
  });
}
