const MINUTES_INTERVAL = 15;

const range = (start = 0, end, step = 1) => {
  const length = (end + 1 - start) / step;
  const arr = [];
  for (let i = 0; i < length; i++) arr.push(start + i * step);
  return arr;
};

// getHoursDropDownData create a range of given value
// e.g maxValue=5 o/p=> [0,1,2,3,4,5]
export const getHoursDropDownData = (maxValue = 6) => range(0, maxValue, 1);

// getMinutesDropDownData create a range of given min value upto 60
// e.g minValue=15 o/p=> [15,30,45]
export const getMinutesDropDownData = (minValue = 0) =>
  range(minValue, 59, MINUTES_INTERVAL);

export const getHourMinutesString = (time = {}) => {
  const { timeInHours, timeInMinutes } = time;
  if (timeInHours > 0 && timeInMinutes > 0) {
    if (timeInHours > 1) {
      return `${timeInHours} hours ${timeInMinutes} minutes`;
    }
    return `${timeInHours} hour ${timeInMinutes} minutes`;
  }
  if (timeInHours > 0 && timeInMinutes === 0) {
    if (timeInHours > 1) {
      return `${timeInHours} hours`;
    }
    return `${timeInHours} hour`;
  }
  return `${timeInMinutes} minutes`;
};

// secondsToMinutes return seconds in minutes
// e.g seconds=60 o/p=> 1
export const secondsToMinutes = (seconds) =>
  Math.floor((Number(seconds) % 3600) / 60);

// secondsToHours return seconds in hours
// e.g seconds=3600 o/p=> 1
export const secondsToHours = (seconds) => Math.floor(Number(seconds) / 3600);

export const secondsToHourMinutes = (seconds) => {
  const hour = secondsToHours(seconds);
  const minutes = secondsToMinutes(seconds);
  return [hour, minutes];
};

export const convertHourAndMinutesToSeconds = (time) => {
  const seconds = time.timeInHours * 3600 + time.timeInMinutes * 60;
  return seconds;
};

export const convertSessionValuesToHoursMinutes = (sessionTimeout) => {
  const hourMinute = secondsToHourMinutes(sessionTimeout);
  return {
    timeInHours: hourMinute[0],
    timeInMinutes: hourMinute[1],
  };
};
