import { CAM_ATTRIBUTES } from "consts";

export const getWorkloadsFromFeature = (features, name) => {
  let attributes = [];
  if (Array.isArray(features)) {
    let filteredFeatures = features;
    if (name) {
      filteredFeatures = features.filter((feature) => feature.name === name);
    }
    attributes = (filteredFeatures || [])
      .map((feature) => {
        return (feature?.attrs || []).filter(
          (attr) => attr.name === CAM_ATTRIBUTES.workloads
        )[0]?.value;
      })
      .flat()
      .filter(Boolean);
  }
  if (typeof features === "object" && features.attrs) {
    attributes = (features?.attrs || []).filter(
      (attr) => attr.name === CAM_ATTRIBUTES.workloads
    )[0]?.value;
  }

  return attributes;
};

export const getComplianceAttributes = (feature) =>
  (feature?.attrs || []).reduce((prev, current) => {
    return { ...prev, [current.name]: current.value };
  }, {});

export const getFeature = (features = [], name) => {
  return features.filter((feature) => feature.name === name)[0];
};

export const getAttributeValue = (attrs = [], name) =>
  attrs.filter((attr) => attr.name === name)[0]?.value;
