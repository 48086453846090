import React from "react";
import ToggleButton from "orion/lib/toggleButton";
import Card from "orion/lib/card";
import Col from "orion/lib/col";
import Heading from "orion/lib/heading";
import Row from "orion/lib/row";
import { productIdToName } from "consts";
import Field from "components/Field/Field";
import ServicePlanFormFeatures from "./ServicePlanFormFeatures";

const HybridWorkloadFormContainer = ({ formikProps, details, index }) => {
  return (
    <Card
      className="oui-mt-4"
      key="HybridWorkloads-ServicePlanCard"
      body={() => {
        const { isEnabled, features } = formikProps.values.products[index];
        return (
          <>
            <Row className="oui-mb-4 oui-mt-1">
              <Col>
                <Heading text={productIdToName[details.productID]} inline />
                <ToggleButton
                  isActive={isEnabled}
                  name={`products[${index}].isEnabled`}
                  onClick={(_, isActive) => {
                    formikProps.setFieldValue(
                      `products[${index}].isEnabled`,
                      isActive
                    );
                    formikProps.setFieldValue(
                      `products[${index}].features[0].isEnabled`,
                      isActive
                    );
                  }}
                  validationProps={formikProps}
                  className="oui-d-inline oui-ml-3 oui-align-middle"
                />
                <div className="font-12 oui-text-muted oui-mt-1">
                  {`${productIdToName[details.productID]} is ${
                    isEnabled ? "enabled" : "disabled"
                  }`}
                </div>
              </Col>
            </Row>
            {isEnabled && (
              <>
                <Field
                  type="select"
                  capitalize
                  name={`products[${index}].edition`}
                  label="Edition"
                  options={details.editionOption}
                  placeholder="Select"
                  formikProps={formikProps}
                />
                <ServicePlanFormFeatures
                  formikProps={formikProps}
                  index={index}
                  featureDetails={features[0]}
                  featureIndex={0}
                />
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default HybridWorkloadFormContainer;
