import React from "react";
import { Popover, Button, Row, Col, LabelValue } from "orion";
import useServicePlan from "queryHooks/useServicePlan/useServicePlan";
import { productIdToName } from "consts";
import { getWorkloadsFromFeature } from "utils/attributesUtils";

const PlanTooltip = ({ id }) => {
  const { data, isLoading } = useServicePlan(id);
  if (isLoading) return "loading";
  return (
    <>
      <Row className="oui-mb-3">
        <Col>
          <LabelValue
            label="Edition"
            className="oui-text-capitalize"
            renderValue={() =>
              (data.products || []).map((product) => (
                <p className="oui-mb-1" key={`edition-${product.productID}`}>
                  {`${productIdToName[product.productID]} - ${product.edition}`}
                </p>
              ))
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <LabelValue
            label="Workloads"
            renderValue={() => {
              return data?.products?.length > 0 ? (
                <>
                  {data.products.map(({ productID, features }) => {
                    const workloadsList = getWorkloadsFromFeature(features);
                    const workloads = (workloadsList || [])
                      .map((workload) => workload.name)
                      .join(", ");
                    return (
                      <p
                        className="oui-mb-1 oui-text-capitalize"
                        key={`retention-lim-${productID}`}
                      >
                        {`${productIdToName[productID]} - ${workloads}`}
                      </p>
                    );
                  })}
                </>
              ) : (
                "-"
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

const PlanPreview = ({ id }) => {
  return (
    <Popover
      type="uncontrolled"
      title="Plan Details"
      placement="bottom"
      className="oui-p-1"
      body={() => <PlanTooltip id={id} />}
    >
      <Button
        size="sm"
        type="link"
        label="View plan"
        className="oui-position-absolute"
      />
    </Popover>
  );
};

export default PlanPreview;
