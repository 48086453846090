import { useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  CAM_ATTRIBUTES,
  CAM_FEATURES,
  genericErrorMessage,
  PRODUCT_ID,
} from "consts";
import { showAlert } from "slices/alertsSlice";
import useTenant from "queryHooks/useTenant/useTenant";
import useServicePlan from "queryHooks/useServicePlan/useServicePlan";
import useTenantTypeList from "queryHooks/useTenantTypeList/useTenantTypeList";
import useUpdateTenant from "queryHooks/useUpdateTenant/useUpdateTenant";
import useMsp from "queryHooks/useMsp/useMsp";
import { asLocalTime } from "utils/timezone";

const useUpdateTenantForm = (customerId, productId, tenantId) => {
  const dispatch = useDispatch();

  const queryConfig = useMemo(
    () => ({
      onError: (e) => {
        dispatch(
          showAlert({
            message: e.data?.message || genericErrorMessage,
            type: "danger",
          })
        );
      },
    }),
    [dispatch]
  );

  const { data: tenantData, isFetching } = useTenant(
    tenantId,
    customerId,
    queryConfig
  );
  const { data: mspInfo } = useMsp();

  const useMutation = useUpdateTenant({ customerId, tenantId });
  const { data: planData } = useServicePlan(
    tenantData?.servicePlanID,
    queryConfig
  );

  const { data: tenantTypeListData } = useTenantTypeList(queryConfig);

  const initialValues = useMemo(() => {
    if (
      !isFetching &&
      tenantData &&
      planData &&
      tenantTypeListData &&
      mspInfo
    ) {
      const tenantType = [
        ...(tenantTypeListData || []),
        { id: 1, name: "Sandbox" },
      ].find(({ id }) => id === tenantData.tenantType);
      const productInfo = (mspInfo.products || []).filter(
        (product) => product.productID === productId
      )[0];

      return {
        customerID: customerId,
        licenseExpiryDate: asLocalTime(new Date(tenantData.licenseExpiryDate)),
        quota: tenantData.quota,
        quotaStartDate: tenantData.quotaStartDate
          ? asLocalTime(new Date(tenantData.quotaStartDate))
          : null,
        quotaEndDate: tenantData.quotaEndDate
          ? asLocalTime(new Date(tenantData.quotaEndDate))
          : null,
        createdOn: tenantData.createdOn,
        servicePlanID: {
          label: planData.name,
          value: planData.id,
          servicePlan: planData,
        },
        storageRegions: (tenantData.storageRegions || []).map(
          (storageRegion) => ({
            label: storageRegion,
            value: storageRegion,
            isFixed: true,
          })
        ),
        ltr: tenantData.features.some(
          (feature) => feature.name === CAM_FEATURES.longTermRetention,
        ),
        tenantType: {
          label: tenantType.name,
          value: tenantType.id,
        },
        productID: productId,
        ...(productId === PRODUCT_ID.saasAppsAndEndpoints
          ? {
              features: (productInfo.datasources || []).map((datasource) => {
                const tenantFeature = tenantData.features.find(
                  (feature) => feature.name === datasource.name
                );
                return {
                  name: datasource.name,
                  isEnabled: tenantFeature ? true : false,
                  attrs: {
                    userCount: tenantFeature?.attrs
                      ? tenantFeature.attrs.find(
                          (attr) => attr.name === CAM_ATTRIBUTES.userCount
                        )?.value
                      : "",
                    preservedUserCount: tenantFeature?.attrs
                      ? tenantFeature.attrs.find(
                          (attr) =>
                            attr.name === CAM_ATTRIBUTES.preservedUserCount
                        )?.value
                      : "",
                  },
                };
              }),
            }
          : {}),
      };
    }
  }, [
    isFetching,
    tenantData,
    planData,
    customerId,
    productId,
    tenantTypeListData,
    mspInfo,
  ]);

  return [initialValues, useMutation];
};

export default useUpdateTenantForm;
