import { useMutation, useQueryClient } from "react-query";
import { API_ADMINS, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import { pollTaskStatusAsync } from "queryHooks/useJobStatus/useJobStatus";
import { useDispatch } from "react-redux";

export default function useCreateAdministrator(config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const createAdministrator = async (data) => {
    const response = await httpClient.post(API_ADMINS, data);
    if (response?.task?.id) {
      const pollResponse = await pollTaskStatusAsync(
        httpClient,
        response?.task?.id,
        dispatch
      );
      return pollResponse;
    }
    return response;
  };

  return useMutation(createAdministrator, {
    ...config,
    onSuccess: (data) => {
      queryClient.invalidateQueries(QUERIES.administratorListV2);
      queryClient.invalidateQueries(QUERIES.customer);
      config?.onSuccess?.(data);
    },
  });
}
