import { useQuery } from "react-query";

import { API_MSP_INFO, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import useMspId from "queryHooks/useMspId/useMspId";

export default function useMsp(config) {
  const httpClient = useHttpClient();
  const { data } = useMspId();

  return useQuery(
    [QUERIES.mspInfo],
    () => {
      return httpClient.get(
        withUrlParams(API_MSP_INFO, { mspId: data?.mspId })
      );
    },
    {
      enabled: !!data?.mspId,
      staleTime: Infinity,
      ...config,
    }
  );
}
