import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { userId } from "slices/userSlice";
import { API_MSP_ID, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";

export default function useMspId(config) {
  const httpClient = useHttpClient();
  const id = useSelector(userId);
  return useQuery(
    [QUERIES.mspId, id],
    () => {
      return httpClient.get(withUrlParams(API_MSP_ID, { userId: id }));
    },
    { enabled: !!id, staleTime: Infinity, ...config }
  );
}
