import React from "react";
import PropTypes from "prop-types";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Headerbar } from "orion";
import { stringify } from "query-string";
import { SettingsGreyOutline } from "@druva-apps/orion-icons";

import { showAlert } from "slices/alertsSlice";
import { AlertGreyOutline } from "@druva-apps/orion-icons";
import { useDruvaAlerts } from "@druva-apps/druva-alerts";
import {
  LOGIN_REDIRECT_URL,
  cookies,
  genericErrorMessage,
  PRODUCT_ID,
  CAM_FEATURES,
} from "consts";
import useLogout from "queryHooks/useLogout/useLogout";
import useMsp from "queryHooks/useMsp/useMsp";
import useUser from "queryHooks/useUser/useUser";
import usePermissions from "components/usePermissions";
import useIdleSessionTimeout from "queryHooks/useIdleSessionTimeout/useIdleSessionTimeout";
import { removeSessionStorageState } from "utils/sessionStorageUtils";
import useAdministratorStatsV2 from "queryHooks/useAdministratorListV2/useAdministratorStatsV2";

const renderNavLinkClassName = ({ isActive }) =>
  `oui-nav-link ${isActive ? "oui-active" : ""}`;

const Navbar = ({ brandName }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { unseenAlertsCount } = useDruvaAlerts();
  const { data: userData } = useUser({
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
  });
  const { msp, tenant } = usePermissions();
  const { haveOnlyOneMSPAdmin } = useAdministratorStatsV2();
  const { data: idleTimeoutData } = useIdleSessionTimeout();
  const { data: mspInfo } = useMsp();

  const mspHasHybridWorkload = (mspInfo?.products || []).some(
    (product) => product.productID === PRODUCT_ID.hybridWorkloads
  );
  const mspHasM365 = (mspInfo?.products || [])
    .find((product) => product.productID === PRODUCT_ID.saasAppsAndEndpoints)
    ?.datasources?.some(
      (datasource) =>
        datasource.name === CAM_FEATURES.M365 && datasource.isEnabled
    );

  const left = [
    {
      component: "link",
      data: {
        renderComponent: () => (
          <NavLink
            to="/service-plans"
            className={renderNavLinkClassName}
            disabled={haveOnlyOneMSPAdmin}
          >
            Service Plans
          </NavLink>
        ),
      },
    },
    {
      component: "link",
      data: {
        renderComponent: () => (
          <NavLink
            to="/customers"
            className={renderNavLinkClassName}
            disabled={haveOnlyOneMSPAdmin}
          >
            Customers
          </NavLink>
        ),
      },
    },
    {
      component: "dropdown",
      activeClassName: "oui-active",
      data: {
        label: "Jobs",
        disabled: haveOnlyOneMSPAdmin,
        onItemClick: (item) => {
          navigate(item.to);
        },
        isActive: location?.pathname.includes("/jobs"),
        items: [
          {
            label: "Hybrid Workloads",
            to: "/jobs/1",
            disabled: !mspHasHybridWorkload,
            isActive: location?.pathname.includes("/jobs/1"),
          },
          {
            label: "Microsoft 365",
            to: "/jobs/2",
            disabled: !mspHasM365,
            isActive: location?.pathname.includes("/jobs/2"),
          },
        ],
      },
    },
    {
      component: "link",
      data: {
        renderComponent: () => (
          <NavLink
            to="/reports"
            className={renderNavLinkClassName}
            disabled={haveOnlyOneMSPAdmin}
          >
            Reports
          </NavLink>
        ),
      },
    },
    {
      component: "link",
      data: {
        renderComponent: () => (
          <NavLink
            to="/events"
            className={renderNavLinkClassName}
            disabled={haveOnlyOneMSPAdmin}
          >
            Events
          </NavLink>
        ),
      },
    },
    ...(msp || tenant
      ? [
          {
            component: "link",
            data: {
              renderComponent: () => (
                <NavLink to="/settings" className={renderNavLinkClassName}>
                  <SettingsGreyOutline inheritFontColor size="sm" />
                </NavLink>
              ),
            },
          },
        ]
      : []),
    // {
    //   component: "icon",
    //   data: {
    //     icon: {
    //       src: QueuedIcon,
    //       muted: false,
    //     },
    //     title: "Time",
    //     badge: {
    //       label: 3,
    //       type: "success",
    //     },
    //     onClick: (e) => {
    //       e.preventDefault();
    //       alert("Time clicked");
    //     },
    //   },
    // },
    ...(msp
      ? [
          {
            component: "icon",
            data: {
              icon: <AlertGreyOutline size="sm" />,
              title: "Alerts",
              badge: {
                label: unseenAlertsCount,
                type: "danger",
              },
              onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/alerts");
              },
              disabled: haveOnlyOneMSPAdmin,
            },
          },
        ]
      : []),
  ];
  const { mutate: logout, isLoading } = useLogout({
    onSettled: (_data, e) => {
      if (e) {
        dispatch(
          showAlert({
            message:
              e?.data?.message ||
              "Unsuccessfully logout attempt. Redirecting...",
            type: "danger",
          })
        );
      }
      setTimeout(
        () => {
          cookies.remove("msp_refreshToken", {
            path: "/",
            secure: true,
            sameSite: "strict",
          });
          window.location = `${LOGIN_REDIRECT_URL}?${stringify({
            redirectUrl: window.location,
          })}`;
        },
        e ? 750 : 0
      );
    },
  });

  useIdleTimer({
    timeout: idleTimeoutData?.sessionTimeout
      ? 1000 * idleTimeoutData?.sessionTimeout
      : 1000 * 30 * 60,
    onIdle: () => {
      removeSessionStorageState();
      logout();
    },
  });

  return (
    <Headerbar
      brand={{
        name: brandName,
        onClick: (e) =>
          haveOnlyOneMSPAdmin ? e.preventDefault() : navigate("/"),
      }}
      left={left}
      right={[
        {
          component: "profile",
          data: {
            user: {
              ...(userData && {
                name: `${userData.firstName} ${userData.lastName}`,
                initials: `${userData.firstName.substr(
                  0,
                  1
                )}${userData.lastName.substr(0, 1)}`,
                email: userData.email,
                // href: "/customers",
                // onClick: (e) => {
                //   e.preventDefault();
                //   alert("Taking you to ur profile.");
                // },
              }),
            },
            links: [],
            logout: {
              onClick: (e) => {
                e.preventDefault();
                removeSessionStorageState();
                if (!isLoading) {
                  logout();
                }
              },
            },
          },
        },
      ]}
    />
  );
};

Navbar.propTypes = {
  brandName: PropTypes.string,
};

export default Navbar;
