import React from "react";
import { Modal } from "orion";
import EventSummaryCard from "./EventSummaryCard";
import EventHistoryCard from "./EventHistoryCard";
import {
  getEventWithDescriptionJSON,
  isEventDescriptionJSON,
} from "../eventHistoryUtils";

const EventDetailsModal = ({ key, closeModal, event }) => {
  const eventWithDetails = getEventWithDescriptionJSON(event);

  return (
    <Modal
      key={key}
      size="lg"
      isOpen
      toggle={closeModal}
      header="Event Details"
      body={() => (
        <>
          <div className="oui-mb-3">
            <EventSummaryCard event={eventWithDetails} />
          </div>
          {isEventDescriptionJSON(eventWithDetails) && (
            <EventHistoryCard event={eventWithDetails} />
          )}
        </>
      )}
    />
  );
};

export default EventDetailsModal;
