import { useMemo } from "react";

import useUpdateClient from "queryHooks/useUpdateClient/useUpdateClient";

const useUpdateAPICredentialsForm = (clientCredentialsData) => {
  const useMutation = useUpdateClient(clientCredentialsData?.clientId);

  const initialValues = useMemo(() => {
    if (clientCredentialsData) {
      return {
        name: clientCredentialsData.name,
      };
    }
  }, [clientCredentialsData]);

  return [initialValues, useMutation];
};

export default useUpdateAPICredentialsForm;
