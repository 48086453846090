import { QUERIES } from "consts";
import useMsp from "queryHooks/useMsp/useMsp";
import { useQuery } from "react-query";

export default function useTenantTypeList(config) {
  const { data } = useMsp();
  return useQuery(
    [QUERIES.tenantTypeList],
    () => {
      return new Promise((resolve) => {
        resolve([
          {
            id: 3,
            name: "Commercial",
            isDisabled: data.edition === "evaluation",
          },
          { id: 2, name: "Evaluation" },
        ]);
      });
    },
    {
      enabled: !!data?.edition,
      staleTime: Infinity,
      ...config,
    }
  );
}
