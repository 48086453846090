import React from "react";
import {
  Select,
  Input,
  Label,
  Row,
  Col,
  FormGroup,
  InputFeedback,
} from "orion";

import { PRODUCT_ID, retentionLimitUnitDictionary } from "consts";

const options = Object.entries(retentionLimitUnitDictionary).map(
  ([value, label]) => ({ value, label })
);

const RetentionLimitInput = ({ formikProps, index, featureIndex }) => {
  const product = formikProps.values?.products?.[index];
  const attrs = product?.features?.[featureIndex]?.attrs;
  const touched =
    formikProps.touched?.products?.[index]?.features?.[featureIndex]?.attrs;
  const errors =
    formikProps.errors?.products?.[index]?.features?.[featureIndex]?.attrs;
  const hasMinError =
    touched?.retentionLimitMin && errors?.retentionLimitMin !== undefined;

  const hasMaxError =
    touched?.retentionLimitMax && errors?.retentionLimitMax !== undefined;

  const hasUnitError =
    touched?.retentionLimitUnit && errors?.retentionLimitUnit !== undefined;

  let frequencyText = "";
  if (attrs.retentionLimitUnit && attrs.retentionLimitUnit.value) {
    const val = attrs.retentionLimitUnit.value;
    frequencyText =
      val === "daily"
        ? "days"
        : val === "weekly"
        ? "weeks"
        : val === "monthly"
        ? "months"
        : val === "yearly"
        ? "years"
        : "";
  }
  return (
    <FormGroup row className="oui-mb-3">
      <Label muted text="Retention Limit" sm={3} />
      <Col sm={2}>
        <Select
          options={
            product.productID === PRODUCT_ID.saasAppsAndEndpoints
              ? options.filter((opt) => opt.value !== "yearly")
              : options
          }
          name={`products[${index}].features[${featureIndex}].attrs.retentionLimitUnit`}
          value={attrs.retentionLimitUnit}
          isInvalid={hasUnitError}
          isClearable
          menuPosition="fixed"
          validationProps={formikProps}
          dataTestId={`retentionLimitUnit-select-${index}-${featureIndex}`}
          onChange={(val) => {
            if (
              !val ||
              (val &&
                val.value === "yearly" &&
                product.productID === PRODUCT_ID.saasAppsAndEndpoints)
            ) {
              formikProps.setFieldValue(
                `products[${index}].features[${featureIndex}].attrs.retentionLimitMin`,
                0
              );
              formikProps.setFieldValue(
                `products[${index}].features[${featureIndex}].attrs.retentionLimitMax`,
                0
              );
            } else {
              formikProps.setFieldValue(
                `products[${index}].features[${featureIndex}].attrs.retentionLimitMin`,
                attrs.retentionLimitMin
              );
              formikProps.setFieldValue(
                `products[${index}].features[${featureIndex}].attrs.retentionLimitMax`,
                attrs.retentionLimitMax
              );
            }
            formikProps.setFieldValue(
              `products[${index}].features[${featureIndex}].attrs.retentionLimitUnit`,
              val
            );
          }}
        />
      </Col>
      <Col sm={3}>
        <Row>
          <Col sm={6}>
            <Input
              type="number"
              name={`products[${index}].features[${featureIndex}].attrs.retentionLimitMin`}
              validationProps={formikProps}
              isInvalid={hasMinError}
              dataTestId={`retentionLimitMin-input-${index}-${featureIndex}`}
              disabled={!attrs.retentionLimitUnit}
            />
            {!hasMinError && !hasUnitError && !hasMaxError && (
              <InputFeedback
                message="min"
                className="oui-text-secondary oui-text-center oui-font-italic"
                show
              />
            )}
          </Col>
          <Col sm={6}>
            <Input
              type="number"
              name={`products[${index}].features[${featureIndex}].attrs.retentionLimitMax`}
              validationProps={formikProps}
              isInvalid={hasMaxError}
              dataTestId={`retentionLimitMax-input-${index}-${featureIndex}`}
              disabled={!attrs.retentionLimitUnit}
            />
            {!hasMinError && !hasUnitError && !hasMaxError && (
              <InputFeedback
                message="max"
                className="oui-text-secondary oui-text-center oui-font-italic"
                show
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col sm={3} className="oui-pl-0 oui-mb-0">
        <Label text={frequencyText} className="oui-col-form-label" />
      </Col>
    </FormGroup>
  );
};

export default RetentionLimitInput;
