import React, { useMemo } from "react";
import { getIn } from "formik";
import ToggleButton from "orion/lib/toggleButton";
import Card from "orion/lib/card";
import Col from "orion/lib/col";
import Heading from "orion/lib/heading";
import Row from "orion/lib/row";
import Tooltip from "orion/lib/tooltip";
import FormGroup from "orion/lib/formGroup";
import Icon from "orion/lib/icon";
import Input from "orion/lib/input";
import Label from "orion/lib/label";
import Select from "orion/lib/select";
import { CAM_FEATURES, productIdToName, PRODUCT_ID, SKU } from "consts";
import InformationSVG from "assets/icons/information.svg";
import { capitalizeSelect } from "components/Field/Field";
import { getFeatureMessage } from "utils/servicePlanUtils";
import SaaSAppAndEndpointsFeatures from "./SaaSAppAndEndpointsFeatures";

const SaaSAppAndEndpointsFormContainer = ({
  formikProps,
  details,
  index,
}) => {
  const { isEnabled, edition, additionalData, features } =
    formikProps.values.products[index];
  const isEliteOrEnterprise =
    edition?.value === SKU.enterprise || edition?.value === SKU.elite;

  const infoText = useMemo(() => {
    const initial = (formikProps.initialValues?.products || []).find(
      (product) => product.productID === PRODUCT_ID.saasAppsAndEndpoints
    );
    const current = (formikProps.values?.products || []).find(
      (product) => product.productID === PRODUCT_ID.saasAppsAndEndpoints
    );
    const m365Text = initial?.isEnabled
      ? getFeatureMessage(initial, current, CAM_FEATURES.M365)
      : "";

    const endpointsText = initial?.isEnabled
      ? getFeatureMessage(initial, current, CAM_FEATURES.Endpoints)
      : "";

    const googleText = initial?.isEnabled
      ? getFeatureMessage(initial, current, CAM_FEATURES.googleWorkspace)
      : "";
    return {
      [CAM_FEATURES.M365]: m365Text,
      [CAM_FEATURES.Endpoints]:
        edition?.value === SKU.business
          ? "Not supported in business edition"
          : endpointsText,
      [CAM_FEATURES.googleWorkspace]: googleText,
    };
  }, [
    edition,
    formikProps.values?.products,
    formikProps.initialValues?.products,
  ]);

  return (
    <Card
      className="oui-mt-4"
      key="SaaSAppAndEndpoints-ServicePlanCard"
      body={() => {
        return (
          <>
            <Row className="oui-mb-2 oui-mt-1">
              <Col>
                <Heading text={productIdToName[details.productID]} inline />
                <ToggleButton
                  isActive={isEnabled}
                  name={`products[${index}].isEnabled`}
                  onClick={(_, isActive) => {
                    formikProps.setFieldValue(
                      `products[${index}].isEnabled`,
                      isActive
                    );
                  }}
                  validationProps={formikProps}
                  className="oui-d-inline oui-ml-3 oui-align-middle"
                />
                <div className="font-12 oui-text-muted oui-mt-1">
                  {`${productIdToName[details.productID]} is ${
                    isEnabled ? "enabled" : "disabled"
                  }`}
                </div>
              </Col>
            </Row>

            {isEnabled && (
              <>
                <FormGroup row className="oui-mb-3">
                  <Label muted text="Edition" sm={3} />
                  <Col sm={5}>
                    <Select
                      name={`products[${index}].edition`}
                      label="Edition"
                      styles={capitalizeSelect}
                      options={details.editionOption}
                      placeholder="Select"
                      value={details.edition}
                      isInvalid={
                        getIn(
                          formikProps.touched,
                          `products[${index}].edition`
                        ) &&
                        getIn(
                          formikProps.errors,
                          `products[${index}].edition`
                        ) !== undefined
                      }
                      validationProps={formikProps}
                      dataTestId={`products[${index}].edition${details.productID}`}
                      onChange={(val) => {
                        formikProps.setFieldValue(
                          `products[${index}].edition`,
                          val
                        );
                        if (val?.value === SKU.business) {
                          formikProps.setFieldValue(
                            `products[${index}].additionalData.storage`,
                            false
                          );
                          formikProps.setFieldValue(
                            `products[${index}].additionalData.sensitiveDataGovernance`,
                            false
                          );
                          const EPEnabledIndex = features?.findIndex(
                            (feature) =>
                              feature.name === CAM_FEATURES.Endpoints &&
                              feature.isEnabled
                          );
                          if (EPEnabledIndex > -1) {
                            formikProps.setFieldValue(
                              `products[${index}].features[${EPEnabledIndex}].isEnabled`,
                              false
                            );
                          }
                        }
                      }}
                    />
                  </Col>
                </FormGroup>
                {isEliteOrEnterprise && (
                  <>
                    <FormGroup row className="oui-mb-3">
                      <Col sm={3}>
                        <label
                          className="oui-text-muted"
                          data-testid={`products[${index}].additionalData.storage`}
                          htmlFor={`products[${index}].additionalData.storage`}
                        >
                          High Storage Tier
                        </label>
                        <Tooltip
                          maxWidth={300}
                          placement="bottom"
                          text="Storage Limit will be set to 300 GB per user"
                        >
                          <Icon
                            src={InformationSVG}
                            className="oui-ml-2 oui-mb-1"
                          />
                        </Tooltip>
                      </Col>
                      <Col sm={5} className={`oui-mt-0 oui-mb-1`}>
                        <Input
                          type="checkbox"
                          name={`products[${index}].additionalData.storage`}
                          id={`products[${index}].additionalData.storage`}
                          checked={additionalData?.storage}
                          validationProps={formikProps}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="oui-mb-3">
                      <Label
                        text="Sensitive Data Governance"
                        muted
                        sm={3}
                        htmlFor={`products[${index}].additionalData.sensitiveDataGovernance`}
                        dataTestId={`products[${index}].additionalData.sensitiveDataGovernance`}
                      />
                      <Col sm={5} className={`oui-mt-0 oui-mb-1`}>
                        <Input
                          type="checkbox"
                          name={`products[${index}].additionalData.sensitiveDataGovernance`}
                          id={`products[${index}].additionalData.sensitiveDataGovernance`}
                          checked={additionalData?.sensitiveDataGovernance}
                          validationProps={formikProps}
                        />
                      </Col>
                    </FormGroup>
                  </>
                )}
                <SaaSAppAndEndpointsFeatures
                  infoText={infoText}
                  index={index}
                  formikProps={formikProps}
                />
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default SaaSAppAndEndpointsFormContainer;
