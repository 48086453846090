import { useMutation, useQueryClient } from "react-query";
import { API_SERVICE_PLAN, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";

export default function useDeleteServicePlan(servicePlanId, config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();

  return useMutation(
    (data) =>
      httpClient.delete(
        withUrlParams(API_SERVICE_PLAN, {
          servicePlanId,
        }),
        data
      ),
    {
      ...config,
      onSuccess: (data) => {
        queryClient.invalidateQueries(QUERIES.servicePlanList);
        config?.onSuccess?.(data);
      },
    }
  );
}
