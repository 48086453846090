import { useMutation, useQueryClient } from "react-query";
import { API_CONTACT, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import useMspId from "queryHooks/useMspId/useMspId";

export default function useUpdateContact(customerId, contactId, config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();

  const { data: mspIdData } = useMspId();

  return useMutation(
    (data) =>
      httpClient.put(
        withUrlParams(API_CONTACT, {
          customerId,
          mspId: mspIdData?.mspId,
          contactId,
        }),
        data
      ),
    {
      ...config,
      onSuccess: (data) => {
        queryClient.invalidateQueries(QUERIES.contactList);
        config?.onSuccess?.(data);
      },
    }
  );
}
