import { useQuery } from "react-query";
import { API_SERVICE_PLANS, PAGE_SIZE_PER_PAGE, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";

const fetchServicePlansList = (httpClient) => {
  async function fetchServicePlans(
    pageToken = "",
    resolve,
    reject,
    servicePlans = []
  ) {
    try {
      const list = await httpClient.get(API_SERVICE_PLANS, {
        params: {
          pageToken,
          ...(!pageToken && { pageSize: PAGE_SIZE_PER_PAGE }),
        },
      });
      if (list.servicePlans) {
        servicePlans = servicePlans.concat(list.servicePlans);
        if (list.nextPageToken) {
          fetchServicePlans(list.nextPageToken, resolve, reject, servicePlans);
        } else {
          resolve(servicePlans);
        }
      }
    } catch (err) {
      reject(err);
    }
  }
  return new Promise((resolve, reject) =>
    fetchServicePlans("", resolve, reject)
  );
};

export default function useServicePlanList({
  isForOptions = false,
  meta = {},
  ...config
}) {
  const httpClient = useHttpClient();

  return useQuery(
    [QUERIES.servicePlanList, isForOptions],
    async () => {
      const servicePlan = await fetchServicePlansList(httpClient);
      if (isForOptions) {
        let plansWithId = servicePlan?.reduce(
          (obj, plan) => ({
            ...obj,
            [plan.id]: plan,
          }),
          {}
        );
        return { list: servicePlan, plans: plansWithId };
      } else {
        return (servicePlan || [])
          .map((plan) => {
            return plan.products?.map((product) => ({
              ...product,
              id: `${plan.id}-${product.productID}`,
              name: plan.name,
              spId: plan.id,
              status: plan.status,
            }));
          })
          .flat();
      }
    },
    { meta, ...config },
  );
}
