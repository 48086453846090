import useCreateAdministrator from "queryHooks/useCreateAdministrator/useCreateAdministrator";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  role: "",
  customers: [],
};

const useCreateAdministratorForm = () => {
  const useMutation = useCreateAdministrator();

  return [initialValues, useMutation];
};

export default useCreateAdministratorForm;
