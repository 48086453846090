import { configureStore } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import fullPageLoader from "slices/fullPageLoaderSlice";
import modals from "slices/modalsSlice";
import alerts from "slices/alertsSlice";
import history from "./history";
import user from "slices/userSlice";
import preferences from "slices/preferencesSlice";

export default configureStore({
  preloadedState: {},
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  reducer: {
    router: connectRouter(history),
    fullPageLoader,
    modals,
    alerts,
    user,
    preferences
  },
});
