import { useQuery } from "react-query";
import { API_SSO_TOKEN, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import { useDispatch } from "react-redux";
import { showAlert } from "slices/alertsSlice";

export default function useSSOToken(config) {
  const httpClient = useHttpClient();
  const dispatch = useDispatch();
  return useQuery(
    [QUERIES.getSSOToken],
    () => {
      return httpClient.post(API_SSO_TOKEN).then((response) => {
        return response.samlAuthToken;
      });
    },
    {
      enabled: false,
      onError: () => {
        dispatch(
          showAlert({
            message: "SSO Token Generation Failed. Please try again.",
            type: "danger",
          })
        );
      },
      ...config,
    }
  );
}
