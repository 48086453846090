import { useInfiniteQuery } from "react-query";
import { API_ADMINISTRATOR_LIST_V2, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";

const PAGE_SIZE_PER_PAGE = 200;

export default function useAdministratorListV2(config) {
  const httpClient = useHttpClient();

  return useInfiniteQuery(
    [QUERIES.administratorListV2],
    async ({ pageParam = "" }) =>
      httpClient.get(API_ADMINISTRATOR_LIST_V2, {
        params: {
          pageToken: pageParam,
          ...(!pageParam && { pageSize: PAGE_SIZE_PER_PAGE }),
        },
      }),
    {
      select: (data) => {
        return (
          data?.pages?.flatMap((data) =>
            data.admins.map((data) => ({
              name: `${data.firstName} ${data.lastName}`,
              ...data,
            }))
          ) || []
        );
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.nextPageToken !== "") {
          return lastPage.nextPageToken;
        }
      },
      ...config,
    }
  );
}
