import { useEffect } from "react";

const useScript = (scriptUrl, scriptId, callback) => {
  useEffect(() => {
    const existingScript = document.getElementById(scriptId);

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = scriptUrl;
      script.id = scriptId;
      script.defer = true;

      document.body.appendChild(script);

      script.onload = () => {
        if (callback) {
          callback();
        }
      };
    }

    if (existingScript && callback) {
      callback();
    }
  }, [scriptUrl, scriptId, callback]);
};

export default useScript;
