import { Cookies } from "react-cookie";
import invert from "lodash/invert";

export const API_URL = window._env_.APP_API;
export const V2_API_URL = window._env_.APP_API_V2;
export const AUTH_API_URL = window._env_.APP_AUTH_API;
export const SD_API_URL = window._env_.APP_SD_API;
export const LOGIN_REDIRECT_URL = window._env_.APP_LOGIN_REDIRECT_URL;
export const EVENT_MANAGER_API = window._env_.APP_EVENT_MANAGER_API;
export const ALERTS_MSP_PATH = window._env_.APP_ALERTS_MSP_PATH;
export const ALERTS_PATH = window._env_.APP_ALERTS_PATH;
export const ALERTS_LIST_ADMIN_API_PATH =
  window._env_.APP_ALERTS_LIST_ADMIN_API_PATH;
export const ALERTS_API = window._env_.APP_ALERTS_API;
export const REPORTING_API = window._env_.APP_REPORTING_API;
export const COMMON_LOGIN_API = window._env_.APP_COMMON_LOGIN_API;
export const BASENAME = window._env_.APP_BASENAME;
export const DOMAIN = window._env_.APP_DOMAIN;
export const SUBDOMAIN = DOMAIN.replace(/^(.*?)\./, ".");
export const IS_TEST = process.env.NODE_ENV === "test";
export const PHOENIX_URL = window._env_.APP_PHOENIX_URL;
export const ENABLE_GAINSIGHT =
  window._env_.ENABLE_GAINSIGHT || window._env_.ENABLE_GAINSIGHT === "true";
export const ALERTS_API_BASE_URL = window._env_.REACT_APP_ALERTS_API_BASE_URL;

export const API_MSP_INFO = V2_API_URL + "/msps/:mspId";
export const API_ADMINISTRATOR_LIST = AUTH_API_URL + "/groups/:mspId/user";
export const API_ADMINISTRATOR_LIST_V2 = V2_API_URL + "/admins";
export const API_CREDENTIALS_LIST = AUTH_API_URL + "/groups/:mspId/clients";
export const API_CREDENTIAL = AUTH_API_URL + "/groups/:mspId/clients/:clientId";
export const API_CREDENTIALS_RESET_SECRET =
  AUTH_API_URL + "/groups/:mspId/clients/:clientId/resetSecret";
export const API_RESET_PASSWORD = AUTH_API_URL + "/user/:userId/resetpwd";
export const API_ADMINS = V2_API_URL + "/admins";
export const API_ADMIN_UPDATE = `${API_ADMINS}/:adminId`;
export const API_ADMINISTRATOR_CREATION = API_URL + "/msps/:mspId/admin";
export const API_ADMINISTRATOR = API_URL + "/msps/:mspId/admin/:adminId";
export const API_MSP_ID = API_URL + "/msp-id/:userId";
export const API_CUSTOMER_ALERT_LIST =
  API_URL + "/msps/:mspId/customers/:customerId/phoenix-alerts";
export const API_CUSTOMER_LIST = API_URL + "/msps/:mspId/customers";
export const API_JOB = API_URL + "/jobs/:jobId";
export const API_TASK = V2_API_URL + "/tasks/:taskId";
export const API_TENANTS_LIST = V2_API_URL + "/tenants";
export const API_CUSTOMER_TENANTS =
  V2_API_URL + "/customers/:customerId/tenants";
export const API_TENANTS =
  V2_API_URL + "/customers/:customerId/tenants/:tenantId";
export const API_CUSTOMER = V2_API_URL + "/customers/:customerId";
export const API_CUSTOMERS = V2_API_URL + "/customers";
export const SEND_DELETE_OTP = API_URL + "/otp";
export const RESEND_DELETE_OTP = API_URL + "/otp/resend";
export const API_DELETE_NOW_CUSTOMER =
  API_URL + "/msps/:mspId/customers/:customerId/delete-now";
export const API_DELETE_PRODUCT =
  API_URL + "/msps/:mspId/customers/:customerId/deleteProduct";
export const API_CANCEL_DELETE_PRODUCT =
  V2_API_URL + "/customers/:customerId/tenants/:tenantId/cancelDelete";
export const API_DELETE_NOW_PRODUCT =
  API_URL + "/msps/:mspId/customers/:customerId/deleteNowProduct";
export const API_SUSPEND_CUSTOMER_TENANT =
  V2_API_URL + "/customers/:customerId/tenants/:tenantId/suspend";
export const API_UNSUSPEND_CUSTOMER_TENANT =
  V2_API_URL + "/customers/:customerId/tenants/:tenantId/unsuspend";
export const API_MANAGE_CUSTOMER =
  API_URL + "/msps/:mspId/customers/:customerId/manage";
export const API_CONTACT_LIST =
  API_URL + "/msps/:mspId/customers/:customerId/contacts";
export const API_CONTACT =
  API_URL + "/msps/:mspId/customers/:customerId/contacts/:contactId";
export const API_CUSTOMER_COMPILANCE =
  API_URL + "/msps/:mspId/customers/:customerId/compilance";
export const API_SERVICE_PLANS = V2_API_URL + "/servicePlans";

export const API_GLOBAL_USAGE_REPORT =
  API_URL + "/msps/:mspId/reports/global-usage";

export const API_MIGRATE_CUSTOMER_DETAILS =
  API_URL + "/msps/:mspId/customers/migrate/getDetails";
export const API_MIGRATE_CUSTOMER_START =
  API_URL + "/msps/:mspId/customers/migrate/start";

export const API_REFRESH_TOKEN = AUTH_API_URL + "/token";
export const API_TENANT_TYPE_LIST = SD_API_URL + "/tenant-types";
export const API_SERVICE_PLAN = V2_API_URL + "/servicePlans/:servicePlanId";
export const API_SKU_LIST = API_URL + "/msps/:mspId/skus";
export const API_JOB_LIST = API_URL + "/msps/:mspId/jobs";
export const API_SERVICE_PLAN_REPORT_OPTION_LIST =
  API_URL + "/service-plans-report-options";
export const API_TOTAL_JOBS_SUMMARY = API_URL + "/totalJobsSummary";
export const API_SERVICE_PLAN_WORKLOAD_LIST = V2_API_URL + "/workloads";
export const API_LOGOUT = API_URL + "/logout";
export const API_USER_CURRENT = AUTH_API_URL + "/user-current";
export const API_EVENT_LIST = EVENT_MANAGER_API + "/events";
export const API_CUSTOMER_BACKUP_JOBS_SUMMARY =
  API_URL + "/msps/:mspId/customers/:customerId/backup-jobs-summary";
export const API_CUSTOMER_CREDITS_AND_STORAGE_CONSUMPTION =
  API_URL + "/msps/:mspId/customers/:customerId/consumption";
export const API_CUSTOMER_BACKUPSET_LIST =
  API_URL + "/msps/:mspId/customers/:customerId/backupsets";
export const API_SETTINGS = AUTH_API_URL + "/groups/:mspId/settings";

export const API_SAML_CONSUME = COMMON_LOGIN_API + "/samlconsume";
export const API_SSO_CONFIG = `${AUTH_API_URL}/settings/sso/saml/config`;
export const API_SSO_TOKEN = `${AUTH_API_URL}/settings/sso/saml/token`;
export const SUPPORT_PORTAL_URL = "https://support.druva.com";
export const docPortalFQDN = "docs.druva.com";
export const helpPortalFQDN = "help.druva.com";

export const helpArticles = {
  createAndManageAdministrators: "8806010-create-and-manage-administrators",
  createAndManageCustomers: "8806037-create-and-manage-customers",
  migrateCustomersToMsc: "8806044-migrate-customers-to-msc",
  complianceSettingsServicePlan: "8808106-compliance-settings-for-service-plan",
  ssoWithMsc: "8806013-set-up-single-sign-on-sso-with-managed-services-center",
  configureSecurityAdOnsInMsc:
    "8806039-configure-security-add-ons-in-managed-services-center",
  configureIdleSessionTimeout: "8806011-configure-idle-session-timeout",
  createNewServicePlan: "8808105-create-a-new-service-plan",
  enableLtr: "9992209-enable-long-term-retention-ltr-in-managed-sevices-center",
};

export const HASH_GENERATE_SSO_TOKEN = "#h_4d97ec62c9";

export const PAGE_SIZE = 25;
export const PAGE_SIZE_PER_PAGE = 50;

export const COLOR_WARNING = "#FF9900";
export const COLOR_DANGER = "#DC3545";

export const genericSelectTypes = {
  storageRegions: "storageRegions",
  tenantTypes: "tenantTypes",
  usageReportsFrequencies: "usageReportsFrequencies",
  servicePlans: "servicePlans",
  skus: "skus",
  servicePlanWorkloads: "servicePlanWorkloads",
  tenantAdmins: "tenantAdmins",
  customers: "customers",
  products: "product",
};

export const PRODUCT_ID_DCP_DB = {
  druvaOne: 4097,
  hybridWorkloads: 12289,
  inSync: 8193,
};

export const PRODUCT_ID = {
  hybridWorkloads: 1,
  saasAppsAndEndpoints: 2,
};

export const productIdToName = {
  1: "Hybrid Workloads",
  2: "SaaS Apps and Endpoints",
};

export const dataSourceIdToName = {
  1: "Hybrid Workloads",
  2: "M365",
  3: "Endpoints",
  4: "Google Workspace",
};

export const jobTypes = {
  restore: "restore",
  backup: "backup",
  dr: "dr",
};

export const jobTypesId = {
  restore: 65538,
  backup: 65537,
};

export const jobStatusesLabels = {
  queued: "Queued",
  running: "Running",
  successful: "Successful",
  successfulWithErrors: "Successful with errors",
  failed: "Failed",
  cancelled: "Cancelled",
  waitingForRetry: "Waiting for retry",
  skipped: "Skipped",
  backupWindowExpired: "Backup window expired",
  neverBackedUp: "Never Backed Up",
};

export const dataSourceTypes = {
  "Hybrid Workloads": "Hybrid Workloads",
  M365: "Microsoft 365",
  Endpoints: "Endpoints",
  "Google Workspace": "Google Workspace",
};

export const labelToDataSourceType = invert(dataSourceTypes);

export const sassAppsAndEndpointDataSourceTypes = {
  M365: "Microsoft 365",
  Endpoints: "Endpoints",
  "Google Workspace": "Google Workspace",
};

export const hybridWorkloadsDataSourceTypes = {
  "Hybrid Workloads": "Hybrid Workloads",
};

export const startedLabels = {
  1: "Last 24 hours",
  2: "Last 7 days",
  3: "Last 1 month",
};

export const jobStatuses = Object.keys(jobStatusesLabels).reduce(
  (acc, key) => ({
    ...acc,
    [key]: key,
  }),
  {},
);

export const dateFormatter = new Intl.DateTimeFormat("en-US", {
  dateStyle: "medium",
  timeZone: "UTC",
});

export const extendedDateFormatter = new Intl.DateTimeFormat("en-US", {
  dateStyle: "medium",
  timeStyle: "medium",
  hourCycle: "h23",
  timeZone: "UTC",
});

export const usageReportsFrequenciesDictionary = {
  1: "Monthly",
  2: "Quarterly",
  3: "Half yearly",
  4: "Yearly",
};

export const rolesLabels = {
  1: "SD Admin",
  2: "MSP Admin",
  3: "Tenant Admin",
  4: "Read Only Admin",
};

export const tenantTypesDictionary = {
  1: "Sandbox",
  2: "Evaluation",
  3: "Commercial",
};

export const backupFrequencyLimitUnitDictionary = {
  daily: "Daily",
  weekly: "Weekly",
};

export const retentionLimitUnitDictionaryForSummary = {
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  yearly: "Yearly",
};

export const retentionLimitUnitDictionary = {
  daily: "Days",
  weekly: "Weeks",
  monthly: "Months",
  yearly: "Years",
};

export const CAM_JOBS_STATUS = {
  pending: 1,
  running: 2,
  paused: 3,
  finished: 4,
};

export const TENANT_STATUS = {
  pending: 0,
  ready: 1,
  suspended: 2,
  deleted: 3,
  beingMigrated: 4,
  updating: 5,
};

export const CUSTOMER_STATUS = {
  creationPending: 0,
  ready: 1,
  tenantProcessing: 2,
};

export const SERVICE_PLAN_STATUS = {
  updating: 0,
  ready: 1,
};

export const MIGRATION_STATUS = {
  notStarted: 0,
  initiated: 1,
  inprogress: 2,
  completed: 3,
};

export const QUERIES = {
  administrator: "Administrator",
  administratorList: "AdministratorList",
  administratorListV2: "AdministratorListV2",
  customer: "Customer",
  customers: "Customers",
  customersWithTenants: "CustomersWithTenants",
  customersTenants: "CustomersTenants",
  apiCredentialsList: "APICredentialsList",
  contactList: "ContactList",
  servicePlanList: "ServicePlanList",
  servicePlans: "ServicePlans",
  customerAlertList: "CustomerAlertList",
  customerBackupJobsSummary: "CustomerBackupJobsSummary",
  customerCompliance: "CustomerCompliance",
  customerCreditsAndStorageConsumption: "CustomerCreditsAndStorageConsumption",
  customerJobList: "CustomerJobList",
  settings: "Settings",
  skuList: "SkuList",
  storageRegionList: "StorageRegionList",
  tenant: "Tenant",
  tenantList: "TenantList",
  tenantTypeList: "TenantTypeList",
  usageReportsFrequencyList: "UsageReportsFrequencyList",
  servicePlan: "ServicePlan",
  jobList: "JobList",
  migrateCustomerDetails: "MigrateCustomerDetails",
  mspInfo: "MspInfo",
  mspId: "MspId",
  mspProductList: "MspProductList",
  servicePlanNameList: "ServicePlanNameList",
  servicePlanWorkloadList: "ServicePlanWorkloadList",
  getSSOConfig: "GetSSOConfig",
  getSSOToken: "GetSSOToken",
};

export const CAM_FEATURES = {
  hybridWorkloads: "Hybrid Workloads",
  M365: "M365",
  Endpoints: "Endpoints",
  googleWorkspace: "Google Workspace",
  storage: "storage",
  sensitiveDataGovernance: "sensitiveDataGovernance",
  longTermRetention: "Long Term Retention",
};

export const CAM_ATTRIBUTES = {
  workloads: "workloads",
  retentionLimitUnit: "retentionLimitUnit",
  retentionLimitMax: "retentionLimitMax",
  retentionLimitMin: "retentionLimitMin",
  backupFrequencyLimitUnit: "backupFrequencyLimitUnit",
  backupFrequencyLimitMax: "backupFrequencyLimitMax",
  backupFrequencyLimitMin: "backupFrequencyLimitMin",
  userCount: "userCount",
  preservedUserCount: "preservedUserCount",
  unlimitedStorage: "unlimitedStorage",
};
// 30 mins stale time
export const STALE_TIME = 1000 * 60 * 30;

export const eventTypes = {
  loginActivities: { name: "Login Activities", value: "login-activities" },
  auditTrails: { name: "Audit Trails", value: "audit-trails" },
  subscriptionHistory: {
    name: "Subscription History",
    value: "subscription-history",
  },
};

export const alertsAllowedAdminRoles = ["MSP Admin"];

export const minDate = new Date();
export const minYear = minDate.getFullYear() - 1;
export const maxYear = minYear + 8;

export const M365_COMMERCIAL_MAX_EXPIRY_DAYS = 1096;

export const cookies = new Cookies();

export const genericErrorMessage = "Unexpected error occured";

export const SKU = {
  business: "business",
  enterprise: "enterprise",
  elite: "elite",
};

export const IS_DEV =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const HYBRID_WORKLOAD_CHART_FILL = "#A0EBCD";
export const ENDPOINTS_CHART_FILL = "#616DDC";
export const M365_WORKLOAD_CHART_FILL = "#BFBD45";
export const GOOGLE_WORKLOAD_CHART_FILL = "#8D6E63";

export const SECURITY_ADDON_CORE = "Security Posture and Observability";

export const SECURITY_ADDON_ADVANCED_SASS_APPS_ENDPOINTS = {
  M365: "M365 Accelerated Ransomware Recovery",
  Endpoints: "Endpoints Accelerated Ransomware Recovery",
  "Google Workspace": "Google Workspace Accelerated Ransomware Recovery",
};
export const SECURITY_ADDON_HYBRID_ADVANCED_WORKLOADS = {
  "Hybrid Workloads": "Hybrid Workloads Accelerated Ransomware Recovery",
};
export const SECURITY_ADDON_ADVANCED = {
  ...SECURITY_ADDON_ADVANCED_SASS_APPS_ENDPOINTS,
  ...SECURITY_ADDON_HYBRID_ADVANCED_WORKLOADS,
};

export const ARR_WORKLOAD_NAMES_SASS_APPS_ENDPOINTS = {
  "M365 Accelerated Ransomware Recovery": "Microsoft 365",
  "Endpoints Accelerated Ransomware Recovery": "Endpoints",
  "Google Workspace Accelerated Ransomware Recovery": "Google Workspace",
};
export const ARR_WORKLOAD_NAMES_HYBRID_WORKLOADS_ENDPOINTS = {
  "Hybrid Workloads Accelerated Ransomware Recovery": "Hybrid Workloads",
};
export const ARR_WORKLOAD_NAMES = {
  ...ARR_WORKLOAD_NAMES_SASS_APPS_ENDPOINTS,
  ...ARR_WORKLOAD_NAMES_HYBRID_WORKLOADS_ENDPOINTS,
};

export const defaultServicePlanNames = [
  "Default Service Plan",
  "Sandbox Service Plan",
];

// Use Test App ID for deploying and testing on QA clouds.
// export const INTERCOM_APP_ID = "n8zz9yyc"; // Test App ID
export const INTERCOM_APP_ID = "bss4b318"; // Prod App ID
