import { PRODUCT_ID } from "consts";
import { getIsProductBusiness } from "pages/ServicePlan/utils";
import { ServicePlan } from "types/apis/ServicePlan";

export const getIsLtrOrArchiveStorageAllowed: (
  plan: ServicePlan,
  productId: number,
) => boolean = (servicePlan, productId) =>
  productId === PRODUCT_ID.hybridWorkloads &&
  !getIsProductBusiness(servicePlan, PRODUCT_ID.hybridWorkloads);
