import useUser from "queryHooks/useUser/useUser";

const usePermissions = () => {
  const { data } = useUser();

  if (data) {
    return {
      readOnly: data.role === 4,
      tenant: data.role === 3,
      msp: data.role === 2,
    };
  } else {
    // to have it explicity
    return {
      readOnly: undefined,
      tenant: undefined,
      msp: undefined,
    };
  }
};

export default usePermissions;
