import { useQuery } from "react-query";

import { API_TENANTS_LIST, PAGE_SIZE_PER_PAGE, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";

export default function useCustomerTenants(filters, config) {
  const httpClient = useHttpClient();
  async function fetchTenants(pageToken = "", resolve, reject, tenants) {
    try {
      const list = await httpClient.get(API_TENANTS_LIST, {
        params: {
          pageToken,
          ...(!pageToken && { pageSize: PAGE_SIZE_PER_PAGE }),
          ...filters,
        },
      });

      if (list.tenants) {
        tenants = tenants.concat(list.tenants);
        if (list.nextPageToken) {
          fetchTenants(list.nextPageToken, resolve, reject, tenants);
        } else {
          resolve(tenants);
        }
      }
    } catch (err) {
      reject(err);
    }
  }

  const fetchTenantsList = () => {
    let tenants = [];
    return new Promise((resolve, reject) =>
      fetchTenants("", resolve, reject, tenants)
    );
  };

  return useQuery([QUERIES.customersTenants, filters], fetchTenantsList, {
    enabled: filters?.customerIDs?.length > 0,
    staleTime: 1000 * 60 * 10,
    ...config,
  });
}
