import React, { useMemo } from "react";
import { Modal, Button } from "orion";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { genericErrorMessage } from "consts";
import { showAlert } from "slices/alertsSlice";
import useDeleteCustomer from "queryHooks/useDeleteCustomer/useDeleteCustomer";

const DeleteCustomerModal = ({ key, closeModal, customerId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate } = useDeleteCustomer(customerId, {
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
    onSuccess: (res) => {
      const alertMessage = {
        message: res.warning
          ? res.message
          : "Successfully deleted Customer account.",
        type: res.warning ? "warning" : "success",
      };
      dispatch(showAlert(alertMessage));
      closeModal();
      navigate("/customers");
    },
  });

  const versionModalProps = useMemo(() => {
    return {
      header: "Delete Confirmation",
      body: () => <div>Do you wish to delete the customer?</div>,
      footer: () => (
        <div>
          <Button
            dataTestId="close-delete-modal-btn"
            label="Cancel"
            onClick={closeModal}
          />
          <Button
            dataTestId="confirm-delete-modal-btn"
            label="Yes, Delete"
            type="danger"
            onClick={() => mutate()}
          />
        </div>
      ),
    };
  }, [closeModal, mutate]);

  return (
    <Modal
      key={key}
      size="sm"
      isOpen
      toggle={closeModal}
      {...versionModalProps}
    />
  );
};

export default DeleteCustomerModal;
