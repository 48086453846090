import { useQuery } from "react-query";

import { API_ADMINISTRATOR, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import useMspId from "queryHooks/useMspId/useMspId";

export default function useAdministrator(adminId, config) {
  const httpClient = useHttpClient();
  const { data } = useMspId();
  return useQuery(
    [QUERIES.administrator, adminId, data?.mspId],
    () => {
      return httpClient.get(
        withUrlParams(API_ADMINISTRATOR, { adminId, mspId: data?.mspId })
      );
    },
    {
      select: (data) => {
        return {
          ...data,
          isMspAdmin: data.role === 2,
        };
      },
      enabled: !!data?.mspId,
      ...config,
    }
  );
}
