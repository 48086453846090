import React from "react";
import PropTypes from "prop-types";
import Portal from "./Portal";
import FormModalRenderer from "./FormModalRenderer";
import SimpleModalRenderer from "./SimpleModalRenderer";

const ModalRenderer = ({ formViews = {}, simpleViews = {} }) => (
  <Portal className="orion-redux-modals">
    <FormModalRenderer components={formViews} />
    <SimpleModalRenderer components={simpleViews} />
  </Portal>
);

ModalRenderer.propTypes = {
  formViews: PropTypes.object,
  simpleViews: PropTypes.object,
};

export default ModalRenderer;
