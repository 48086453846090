import { getIn } from "formik";
import startsWith from "lodash/startsWith";
import endsWith from "lodash/endsWith";

export const checkIfInvalid = (props, propName) => {
  const touched = getIn(props.touched, propName, false);
  const error = getIn(props.errors, propName, null);
  return Boolean(touched && error);
};

const URL_REGEX =
  /^(?:http(s)?:\/\/)((?:[\w]+([-.][\w]+)*\.[a-zA-Z]{2,})|(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))(?:[^\s]*)$/;

export const isValidURL = (url) => {
  return url && url.length > 0 ? URL_REGEX.test(url) : true;
};

const IDP_CERTIFICATE_STARTS_WITH = "-----BEGIN CERTIFICATE-----";
const IDP_CERTIFICATE_ENDS_WITH = "-----END CERTIFICATE-----";
export const isValidCertificate = (certificate) => {
  return certificate && certificate.length > 0
    ? startsWith(certificate, IDP_CERTIFICATE_STARTS_WITH) &&
        endsWith(certificate, IDP_CERTIFICATE_ENDS_WITH)
    : true;
};
