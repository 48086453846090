import React, { Suspense, lazy, Fragment, useMemo, useEffect } from "react";
import { Page } from "orion";
import { Navigate, useMatch, useRoutes } from "react-router-dom";
import { DruvaAlertsProvider } from "@druva-apps/druva-alerts";

import Loader from "orion/lib/loader";
import Navbar from "shared/Navbar";
import usePermissions from "components/usePermissions";
import useRefreshToken from "queryHooks/useRefreshToken/useRefreshToken";
import {
  ALERTS_MSP_PATH,
  ALERTS_API,
  ALERTS_LIST_ADMIN_API_PATH,
  ALERTS_PATH,
  ENABLE_GAINSIGHT,
  alertsAllowedAdminRoles,
  ALERTS_API_BASE_URL,
} from "consts";
import GainsightAnalytics from "components/GainsightAnalytics";
import useAdministratorStatsV2 from "queryHooks/useAdministratorListV2/useAdministratorStatsV2";
import {
  loadSessionStorageState,
  saveSessionStorageState,
} from "utils/sessionStorageUtils";

const Customers = lazy(() => import("./Customers/Customers"));
const Customer = lazy(() => import("./Customer/Customer"));
const ServicePlans = lazy(() => import("./ServicePlans/ServicePlans"));
const ServicePlan = lazy(() => import("./ServicePlan/ServicePlan"));
const Jobs = lazy(() => import("./Jobs/Jobs"));
const Reports = lazy(() => import("./Reports/Reports"));
const Events = lazy(() => import("./Events/Events"));
const Settings = lazy(() => import("./Settings/Settings"));
const Alerts = lazy(() => import("./Alerts/Alerts"));
const Migration = lazy(() => import("./Migration/Migration"));

const App = () => {
  const { msp, tenant } = usePermissions();
  const { mutateAsync } = useRefreshToken({
    meta: {
      disableFullPageLoader: true,
    },
  });
  const alertsMatch = useMatch("/alerts");
  const { haveOnlyOneMSPAdmin, adminInfo, isLoading } =
    useAdministratorStatsV2();

  const routes = useRoutes(
    [
      ...(haveOnlyOneMSPAdmin && adminInfo?.role === 2
        ? []
        : [
            {
              path: "/customers",
              element: (
                <Page honourNavHeight={!alertsMatch}>
                  <Customers />
                </Page>
              ),
            },
            {
              path: "/customers/:customerId/*",
              element: (
                <Page honourNavHeight={!alertsMatch}>
                  <Customer />
                </Page>
              ),
            },
            {
              path: "/service-plans",
              element: (
                <Page honourNavHeight={!alertsMatch}>
                  <ServicePlans />
                </Page>
              ),
            },
            {
              path: "/service-plans/:servicePlanId/*",
              element: (
                <Page honourNavHeight={!alertsMatch}>
                  <ServicePlan />
                </Page>
              ),
            },
            {
              path: "/jobs/:productId/*",
              element: (
                <Page honourNavHeight={!alertsMatch}>
                  <Jobs />
                </Page>
              ),
            },
            {
              path: "/reports/*",
              element: (
                <Page honourNavHeight={!alertsMatch}>
                  <Reports />
                </Page>
              ),
            },
            {
              path: "/events/*",
              element: (
                <Page honourNavHeight={!alertsMatch}>
                  <Events />
                </Page>
              ),
            },
            {
              path: "/alerts/*",
              element: <Alerts />,
            },
          ]),
      (msp || tenant || msp === undefined) && {
        path: "/settings/*",
        element: (
          <Page honourNavHeight={!alertsMatch}>
            <Settings />
          </Page>
        ),
      },
      (msp || msp === undefined) && {
        path: "/migration/:customerId",
        element: (
          <Page honourNavHeight={!alertsMatch}>
            <Migration />
          </Page>
        ),
      },
      {
        path: "*",
        element:
          haveOnlyOneMSPAdmin && adminInfo?.role === 2 ? (
            <Navigate replace to="/settings" />
          ) : (
            <Navigate replace to="/service-plans" />
          ),
      },
    ].filter(Boolean)
  );
  const gainsightAnalytics = useMemo(() => {
    if (ENABLE_GAINSIGHT && adminInfo) {
      return <GainsightAnalytics />;
    }
    return null;
  }, [adminInfo]);

  useEffect(() => {
    if (!loadSessionStorageState()) {
      saveSessionStorageState({
        showExtensionWarning: true,
        autoRefreshJobs: true,
      });
    }
  }, []);

  return (
    <>
      <Fragment>
        <DruvaAlertsProvider
          getAccessTokenCall={mutateAsync}
          apiConfig={{
            alertsPath: ALERTS_MSP_PATH,
            alertsApiPath: ALERTS_PATH,
            listAdminsApiPath: ALERTS_LIST_ADMIN_API_PATH,
            alertsApiBaseUrl: ALERTS_API_BASE_URL,
            eventmanagerBaseUrl: ALERTS_API,
          }}
          uiConfig={{ labelForAdminRole: "All Administrators" }}
          allowedAdminRoles={alertsAllowedAdminRoles}
          isMsp
        >
          <Navbar brandName="Managed Services Center" />
          {gainsightAnalytics}
          <Suspense fallback={<Loader isLoading fullPage />}>
            {!isLoading && routes}
          </Suspense>
        </DruvaAlertsProvider>
      </Fragment>
    </>
  );
};

export default App;
