export default function createPayload(adminInfo) {
  const { email, customerSFDCId, globalId: globalAdminId } = adminInfo;

  const data = {
    email,
    globalAdminId,
    visitor: {
      sfdcID: customerSFDCId,
      inSync: {},
      Phoenix: {},
      dcp: {},
    },
  };

  return data;
}
