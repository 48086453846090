import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { IntercomProvider } from "react-use-intercom";
import IntercomBot from "shared/IntercomBot/Intercom";
import "orion/lib/styles/orion.css";

import { HttpClientProvider } from "contexts/httpClient";
import store from "utils/store";
import FullPageLoader from "shared/FullPageLoader";
import ToastRenderer from "shared/ReduxToast";
import { ModalRenderer } from "shared/ReduxModal";
import Raygun from "shared/raygun";
import { INTERCOM_APP_ID } from "consts";

import App from "pages";

import * as serviceWorker from "./serviceWorker";
import { formViews, simpleViews } from "components/modalViews";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (count, error) => {
        if (count > 2) {
          return false;
        }
        if (error?.status >= 500) {
          return false;
        }
        return true;
      },
      refetchActive: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <Provider store={store}>
      <HttpClientProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={true} />
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <App />
            <IntercomBot />
          </IntercomProvider>
          <Raygun />
          <ModalRenderer formViews={formViews} simpleViews={simpleViews} />
          <ToastRenderer />
          <FullPageLoader />
        </QueryClientProvider>
      </HttpClientProvider>
    </Provider>
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
