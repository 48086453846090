import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import { showAlert } from "slices/alertsSlice";
import ModalForm from "shared/ModalForm";
import { genericErrorMessage } from "consts";

import FormContainer from "./FormContainer";
import useUpdateContactForm from "./useUpdateContactForm";
import useCreateContactForm from "./useCreateContactForm";
import styles from "./ContactForm.module.scss";

const validationSchemaCreator = (Yup) =>
  Yup.object().shape({
    fullName: Yup.string().max(60, "Max 60 chars").required("Required"),
    email: Yup.string()
      .email("Enter a valid email address.")
      .max(100, "Max 100 chars")
      .required("Required"),
    phoneNumber: Yup.string()
      .min(6, "Min 6 chars")
      .max(30, "Max 30 chars")
      .required("Required"),
    comment: Yup.string().max(255, "Max 255 chars"),
  });

const ContactForm = ({
  closeModal,
  header,
  isOpen,
  customerId,
  contactId,
  initialValues,
  isEdit,
}) => {
  const dispatch = useDispatch();

  const { current: useMutation } = useRef(
    !!isEdit ? useUpdateContactForm : useCreateContactForm,
    []
  );

  const { mutate } = useMutation({ customerId, contactId });

  const onSubmitHandler = useCallback(
    ({ fullName, email, phoneNumber, comment }) => {
      mutate(
        {
          fullName,
          email,
          phoneNumber,
          comment,
        },
        {
          onError: (e) => {
            dispatch(
              showAlert({
                message: e.data?.message || genericErrorMessage,
                type: "danger",
              })
            );
          },
          onSuccess: () => {
            dispatch(
              showAlert({
                message: `Contact ${isEdit ? "edited" : "added"} successfully.`,
                type: "success",
              })
            );
            closeModal();
          },
        }
      );
    },
    [mutate, dispatch, isEdit, closeModal]
  );

  return (
    <ModalForm
      className={styles.modalContainer}
      bodyClassName={styles.overflowYVisible}
      disableSubmitWhenInvalid
      closeModal={closeModal}
      withValidation
      header={header}
      isOpen={isOpen}
      size="sm"
      onSubmit={onSubmitHandler}
      initialValues={initialValues}
      validationSchema={validationSchemaCreator}
      renderChildren={(props) => <FormContainer formikProps={props} />}
      submitButtonLabel="Save"
    />
  );
};

ContactForm.defaultProps = {
  isEdit: false,
  initialValues: {
    fullName: "",
    email: "",
    phoneNumber: "",
    // role: "",
  },
};

export default ContactForm;
