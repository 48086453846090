import React, { useMemo } from "react";
import { getIn } from "formik";
import Popover from "orion/lib/popover";
import {
  FormGroup,
  Label,
  Col,
  Input,
  Tooltip,
  Select,
  DatePicker,
  InputFeedback,
} from "orion";
import InfoGreyOutline from "@druva-apps/orion-icons/lib/InfoGreyOutline";
import Checkbox from "components/Checkbox";
import PhoneInput from "shared/PhoneInput/PhoneInput";
import GenericOptionsSelect from "components/GenericOptionsSelect";
import styles from "./Field.module.scss";
import {
  genericSelectTypes,
  minDate as defaultMinDate,
  minYear,
  maxYear,
} from "consts";

export const capitalizeSelect = {
  menuList: () => ({
    textTransform: "capitalize",
  }),
  valueContainer: () => ({
    textTransform: "capitalize",
  }),
};

const Field = ({
  name,
  label,
  placeholder,
  options,
  formikProps,
  type,
  tooltip,
  tooltipProps,
  isMulti,
  dataTestId,
  fixedInitialValues,
  disabled,
  minDate,
  interceptor,
  onChange,
  maxDate,
  queryFilter,
  inputContainerClassName,
  info,
  labelTooltip,
  labelPopover,
  capitalize,
  datasourceType,
  products,
  fullWidth,
  min,
  max,
  checkboxExtraLabel,
  checkboxTooltipProps,
  ...rest
}) => {
  const isInvalid = useMemo(
    () =>
      getIn(formikProps.touched, name) &&
      getIn(formikProps.errors, name) !== undefined,
    [formikProps.errors, formikProps.touched, name]
  );

  const getInputField = () => {
    switch (type) {
      case "checkbox": {
        return (
          <Checkbox
            name={name}
            id={name}
            validationProps={formikProps}
            isInvalid={isInvalid}
            data-testid={dataTestId}
            disabled={disabled}
            checkboxExtraLabel={checkboxExtraLabel}
            tooltipProps={checkboxTooltipProps}
          />
        );
      }
      case "number": {
        return (
          <Input
            type="number"
            className={styles.numberField}
            name={name}
            id={name}
            validationProps={formikProps}
            isInvalid={isInvalid}
            data-testid={dataTestId}
            placeholder={placeholder}
            disabled={disabled}
            min={min}
            max={max}
          />
        );
      }
      case "text": {
        return (
          <Input
            type="text"
            name={name}
            id={name}
            validationProps={formikProps}
            isInvalid={isInvalid}
            data-testid={dataTestId}
            placeholder={placeholder}
            disabled={disabled}
            className="oui-text-truncate"
          />
        );
      }

      case "textarea": {
        return (
          <Input
            type="textarea"
            name={name}
            id={name}
            validationProps={formikProps}
            isInvalid={isInvalid}
            data-testid={dataTestId}
            placeholder={placeholder}
            disabled={disabled}
          />
        );
      }
      case "phone": {
        return (
          <PhoneInput
            name={name}
            value={getIn(formikProps.values, name)}
            formikProps={formikProps}
            isInvalid={isInvalid}
            onChange={(phone) => {
              formikProps.setFieldValue(name, phone);
            }}
            dataTestId={dataTestId}
            placeholder={placeholder}
            disabled={disabled}
          />
        );
      }
      case genericSelectTypes.storageRegions:
      case genericSelectTypes.servicePlans:
      case genericSelectTypes.tenantAdmins:
      case genericSelectTypes.tenantTypes:
      case genericSelectTypes.servicePlanWorkloads:
      case genericSelectTypes.skus: {
        return (
          <GenericOptionsSelect
            isMulti={isMulti}
            name={name}
            type={type}
            placeholder={placeholder}
            formikProps={formikProps}
            dataTestId={dataTestId}
            isInvalid={isInvalid}
            fixedInitialValues={fixedInitialValues}
            disabled={disabled}
            onChange={onChange}
            queryFilter={queryFilter}
            datasourceType={datasourceType}
            products={products}
            {...rest}
          />
        );
      }
      case "select": {
        return (
          <Select
            isMulti={isMulti}
            name={name}
            type="select"
            options={options}
            placeholder={placeholder}
            isInvalid={isInvalid}
            validationProps={formikProps}
            value={getIn(formikProps.values, name)}
            dataTestId={dataTestId}
            isDisabled={disabled}
            styles={capitalize ? capitalizeSelect : {}}
          />
        );
      }
      default: {
        return (
          <DatePicker
            isClearable={false}
            dateFormat="MMM dd, yyyy"
            name={name}
            type="select"
            placeholder={placeholder}
            isInvalid={isInvalid}
            validationProps={formikProps}
            value={getIn(formikProps.values, name)}
            onChange={(nativeDate) => {
              if (interceptor) {
                formikProps.setFieldValue(name, interceptor(nativeDate));
              }
            }}
            dataTestId={dataTestId}
            minDate={minDate}
            maxDate={maxDate}
            startYear={minYear}
            endYear={maxYear}
            disabled={disabled}
          />
        );
      }
    }
  };

  return (
    <FormGroup row className="oui-mb-0">
      <Col sm={fullWidth && !tooltip ? 4 : 3}>
        <Label muted text={label} />
        {labelTooltip && (
          <Tooltip
            maxWidth={250}
            placement="bottom"
            text={labelTooltip}
            {...tooltipProps}
          >
            <InfoGreyOutline muted={false} className="oui-ml-2" />
          </Tooltip>
        )}
        {labelPopover && (
          <Popover
            type="uncontrolled"
            placement="bottom-start"
            body={labelPopover}
          >
            <InfoGreyOutline muted={false} className="oui-ml-2" />
          </Popover>
        )}
      </Col>

      <Col
        sm={fullWidth && !tooltip ? 8 : 5}
        className={`oui-mt-auto ${
          isInvalid || info ? "oui-mb-1" : "oui-mb-4"
        } ${inputContainerClassName}`}
      >
        {getInputField()}
        {!isInvalid && info && (
          <InputFeedback message={info} className="oui-text-secondary" show />
        )}
      </Col>
      {tooltip && (
        <Col
          sm={3}
          className={`oui-pl-0 oui-my-auto ${styles.tooltipContainer}`}
        >
          <Tooltip
            maxWidth={180}
            placement="right"
            text={tooltip}
            {...tooltipProps}
          >
            <InfoGreyOutline muted={false} />
          </Tooltip>
        </Col>
      )}
    </FormGroup>
  );
};

Field.defaultProps = {
  minDate: defaultMinDate,
  type: "text",
  inputContainerClassName: "",
};

export default Field;
