import { Card, Col, FormGroup, Input, Label } from "orion";
import React, { useEffect } from "react";

import Field from "components/Field/Field";
import styles from "./CustomerForm.module.scss";
import { genericSelectTypes } from "consts";
import usePermissions from "components/usePermissions";
import { checkIfInvalid } from "utils/formUtils";

const FormContainer = ({ formikProps, isEdit, isSandboxCustomer }) => {
  const { msp } = usePermissions();

  useEffect(() => {
    formikProps.validateForm();
  }, []);

  return (
    <Card
      className="mt-2"
      title="Account Details"
      body={() => {
        return (
          <>
            <FormGroup row className={isEdit ? "oui-mb-0" : "oui-mb-2"}>
              <Label text="Customer Name" sm={3} />
              <Col
                sm={5}
                className={`${styles.longInput} ${isEdit ? "oui-mb-4" : ""}`}
              >
                <Input
                  type="text"
                  name="customerName"
                  id="customerName"
                  data-testid="msp-add-customer-org-name-txt"
                  disabled={isSandboxCustomer}
                  validationProps={formikProps}
                  isInvalid={checkIfInvalid(formikProps, "customerName")}
                />
              </Col>
            </FormGroup>
            {!isEdit && (
              <FormGroup row>
                <Col sm={3} />
                <Col sm={5} className={`oui-mt-0`}>
                  <Input
                    type="checkbox"
                    name="alternateNameToDruva"
                    id="alternateNameToDruva"
                    validationProps={formikProps}
                    className="oui-d-inline"
                    muted
                  />
                  <Label
                    text="Hide Customer Name from Druva"
                    muted
                    className="oui-d-inline"
                    for="alternateNameToDruva"
                    dataTestId="alternateNameToDruva-label"
                  />
                </Col>
              </FormGroup>
            )}
            {formikProps.values.alternateNameToDruva || isEdit ? (
              <Field
                label="Account Name"
                name="accountName"
                info="Account Name once entered, cannot be changed"
                dataTestId="msp-add-customer-account-name-txt"
                formikProps={formikProps}
                tooltip={`This name will be visible in Druva's internal systems`}
                disabled={isEdit}
                inputContainerClassName={styles.longInput}
              />
            ) : null}
            <Field
              label="Address"
              name="address"
              dataTestId="msp-add-customer-address-txt"
              formikProps={formikProps}
              disabled={isSandboxCustomer}
              inputContainerClassName={styles.longInput}
            />
            <Field
              type="phone"
              label="Phone number"
              name="phone"
              dataTestId="msp-add-customer-phone-number-txt"
              formikProps={formikProps}
              disabled={isSandboxCustomer}
              inputContainerClassName={styles.longInput}
            />
            <Field
              type={genericSelectTypes.tenantAdmins}
              isMulti
              name="tenantAdmins"
              label="Tenant Admin"
              placeholder="Select"
              dataTestId="msp-add-customer-tenant-admin-drpdwn"
              formikProps={formikProps}
              inputContainerClassName={styles.longInput}
              disabled={!msp}
              menuPosition="fixed"
            />
          </>
        );
      }}
    />
  );
};

export default FormContainer;
