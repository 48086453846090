import { useCallback, useEffect, useMemo } from "react";
import useAdministratorListV2 from "./useAdministratorListV2";
import groupBy from "lodash/groupBy";

const useTenantAdminListV2 = (config) => {
  const {
    data: adminsList,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useAdministratorListV2(config);

  const fetchNextPageHandler = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (!isFetching) {
      fetchNextPageHandler();
    }
  }, [isFetching, fetchNextPageHandler]);

  const countPerRole = useMemo(() => groupBy(adminsList, "role"), [adminsList]);

  return { data: { list: countPerRole[3] || [] } };
};

export default useTenantAdminListV2;
