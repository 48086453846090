import React from "react";
import { Card } from "orion";
import MessageBox from "orion/lib/messageBox";
import DataGrid from "orion/lib/dataGrid";
import noElementsSVG from "assets/images/noElements.svg";
import columnsDef from "./columnsDef";
import { useEventHistoryTableRows } from "../eventHistoryUtils";

const EventHistoryCard = ({ event }) => {
  const data = useEventHistoryTableRows(event.details.Description);

  const tableHeight = data.length * 39 + 50;

  const eventHistoryCardBody = () => {
    return (
      <div className="oui-mt-4" style={{ height: `${tableHeight}px` }}>
        <DataGrid
          tableId="msp-subscription-history-details-table"
          multiSelect={false}
          rowCount=""
          data={data}
          columnsDef={columnsDef}
          responsive
          emptyRenderer={() => (
            <MessageBox
              dataTestId="msp-reports-no-events-added-lbl"
              size="lg"
              icon={{
                src: noElementsSVG,
                muted: false,
              }}
              primaryText="No Events"
            />
          )}
        />
      </div>
    );
  };

  return <Card title="Event Changes" body={eventHistoryCardBody} />;
};
export default EventHistoryCard;
