import Input, { InputProps } from "orion/lib/input";
import Tooltip, { TooltipProps } from "orion/lib/tooltip";
import { FC } from "react";

type CheckboxProps = InputProps & {
  checkboxExtraLabel?: string;
  tooltipProps?: TooltipProps;
};

const Checkbox: FC<CheckboxProps> = (props) => {
  const { checkboxExtraLabel, tooltipProps, ...restInputProps } = props;

  return (
    <div className="oui-d-flex oui-align-items-center" style={{ gap: "8px" }}>
      {tooltipProps ? (
        <Tooltip maxWidth={200} placement="right" {...tooltipProps}>
          <Input {...restInputProps} type="checkbox" />
        </Tooltip>
      ) : (
        <Input {...restInputProps} type="checkbox" />
      )}
      {checkboxExtraLabel && <span>{checkboxExtraLabel}</span>}
    </div>
  );
};

export default Checkbox;
