import { useEffect, useMemo, useRef, useCallback, useState } from "react";
import { getIn } from "formik";
import { Select } from "orion";

import { dataSourceIdToName, genericSelectTypes } from "consts";
import useTenantTypeList from "queryHooks/useTenantTypeList/useTenantTypeList";
import useServicePlanList from "queryHooks/useServicePlanList/useServicePlanList";
import useSkuList from "queryHooks/useSkuList/useSkuList";
import useServicePlanWorkloadList from "queryHooks/useServicePlanWorkloadList/useServicePlanWorkloadList";
import useUsageReportsFrequencyList from "queryHooks/useUsageReportsFrequencyList/useUsageReportsFrequencyList";
import useTenantAdminListV2 from "queryHooks/useAdministratorListV2/useTenantAdminListV2";
import useCustomerList from "queryHooks/useCustomerList/useCustomerList";

import PlanPreview from "./PlanPreviev";
import useMspProducts from "queryHooks/useMspProducts/useMspProducts";
import useMsp from "queryHooks/useMsp/useMsp";
import isEqual from "lodash/isEqual";

const orderOptions = (values) => {
  return values
    .filter((v) => v.isFixed)
    .concat(values.filter((v) => !v.isFixed));
};

const getInitialValue = ({ formikProps, name, isMulti, value }) => {
  const rawInitialValue = formikProps ? getIn(formikProps.values, name) : value;
  return isMulti ? orderOptions(rawInitialValue || []) : rawInitialValue;
};

const GenericOptionsSelect = ({
  name,
  placeholder,
  formikProps,
  isMulti,
  dataTestId,
  type,
  isInvalid,
  fixedInitialValues,
  onChange,
  disabled,
  isClearable,
  showViewPlan,
  queryFilter,
  datasourceType,
  products,
  shouldOnChangeBreak,
  ...rest
}) => {
  const initialValue = getInitialValue({
    isMulti,
    value: rest.value,
    name,
    formikProps,
  });

  const selectRef = useRef();
  const { current: useData } = useRef(
    (() => {
      switch (type) {
        case genericSelectTypes.storageRegions:
          return useMsp;
        case genericSelectTypes.tenantTypes:
          return useTenantTypeList;
        case genericSelectTypes.servicePlans:
          return useServicePlanList;
        case genericSelectTypes.skus:
          return useSkuList;
        case genericSelectTypes.servicePlanWorkloads:
          return useServicePlanWorkloadList;
        case genericSelectTypes.usageReportsFrequencies:
          return useUsageReportsFrequencyList;
        case genericSelectTypes.tenantAdmins:
          return useTenantAdminListV2;
        case genericSelectTypes.customers:
          return useCustomerList;
        case genericSelectTypes.products:
          return useMspProducts;
        default:
          return () => ({});
      }
    })(),
  );

  const { data, isLoading } = useData({
    isForOptions: true,
    ...queryFilter,
    meta: {
      isForOptions: true,
      ...(queryFilter?.meta || {}),
    },
  });

  const styles = useMemo(() => {
    return {
      ...((type === genericSelectTypes.skus ||
        type === genericSelectTypes.servicePlanWorkloads ||
        type === genericSelectTypes.jobStatusTypes) && {
        menuList: () => ({
          textTransform: "capitalize",
        }),
        valueContainer: () => ({
          textTransform: "capitalize",
        }),
      }),
      multiValueRemove: (base) => {
        return base.data.isFixed ? { display: "none" } : {};
      },
      multiValueLabel: (base) => {
        return base.data.isFixed ? { paddingRight: "6px" } : {};
      },
    };
  }, [type]);

  const [value, setValue] = useState(initialValue);

  const options = useMemo(() => {
    if (type === genericSelectTypes.servicePlanWorkloads) {
      const list = (data || [])
        .map((product) => product.datasources)
        .flat()
        .map((datasource) => ({
          ...datasource,
          datasourceName: dataSourceIdToName[datasource.datasourceID],
        }));
      if (datasourceType) {
        const workloadsForDataSource = (list || []).filter(
          (d) => d.datasourceName === datasourceType,
        )[0];
        return (workloadsForDataSource?.workloads || []).map((workload) => ({
          value: workload,
          label: workload,
        }));
      } else {
        const flattenWorkloads = (list || []).map((d) => d.workloads).flat();

        return (flattenWorkloads || []).map((workload) => ({
          label: workload,
          value: workload,
        }));
      }
    }

    if (type === genericSelectTypes.skus) {
      const skus = [...new Set((data?.list || []).map((sku) => sku.name))];
      return skus.map((sku) => ({
        label: sku,
        value: sku,
      }));
    }

    if (type === genericSelectTypes.servicePlans && products?.length > 0) {
      const filteredPlans = (data?.list || []).filter((plan) =>
        products.every((productId) =>
          (plan?.products || []).some((p) => p.productID === productId),
        ),
      );

      return filteredPlans.map((servicePlan) => ({
        value: servicePlan.id,
        label: servicePlan.name,
        servicePlan,
      }));
    }

    if (type === genericSelectTypes.storageRegions) {
      let regions = [];
      if (products?.length > 0) {
        const filteredProducts = (data?.products || []).filter((product) =>
          products.every((productId) => product.productID === productId),
        );
        filteredProducts.forEach(
          (product) => (regions = regions.concat(product.storageRegions)),
        );
      } else {
        (data?.products || []).forEach(
          (product) => (regions = regions.concat(product.storageRegions)),
        );
      }
      regions = [...new Set((regions || []).map((region) => region))];
      return (regions || []).map((region) => ({
        label: region,
        value: region,
      }));
    }
    return (data?.pages?.[0]?.list || data?.list || data || []).map(
      (optionValue) => ({
        value: optionValue.id || optionValue.name || optionValue,
        label: optionValue.email
          ? optionValue.email
          : optionValue.name || optionValue.accountName || optionValue,
        isDisabled: !!optionValue.isDisabled,
        isFixed: fixedInitialValues
          ? initialValue.some(({ value: initialValueElem }) => {
              return (
                initialValueElem ===
                (optionValue.id || optionValue.name || optionValue)
              );
            })
          : false,
      }),
    );
  }, [data, fixedInitialValues, initialValue, datasourceType, type, products]);

  const canShowViewPlan = useMemo(
    () =>
      type === genericSelectTypes.servicePlans &&
      value &&
      !isInvalid &&
      showViewPlan,
    [isInvalid, showViewPlan, type, value],
  );

  useEffect(() => {
    if (isMulti && value.length === options.length && options.length > 0) {
      selectRef.current.blur();
    }
  }, [isMulti, options?.length, value?.length]);

  const onChangeHandler = useCallback(
    (value, { action, removedValue }) => {
      if (shouldOnChangeBreak?.(value)) {
        return false;
      }
      if (isMulti) {
        // eslint-disable-next-line default-case
        switch (action) {
          case "remove-value":
          case "pop-value":
            if (removedValue.isFixed) {
              value = [...value, removedValue];
            }
            break;
          case "clear":
            value = options.filter((v) => v.isFixed);
            break;
        }
        setValue(orderOptions(value));
        formikProps?.setFieldValue(name, value);
      } else {
        setValue(value);
      }
      onChange?.(value);
    },
    [formikProps, isMulti, name, onChange, options],
  );

  return (
    <>
      <Select
        onChange={onChangeHandler}
        isMulti={isMulti}
        name={name}
        type="select"
        options={options}
        placeholder={placeholder}
        isInvalid={isInvalid}
        validationProps={formikProps}
        value={value}
        dataTestId={dataTestId}
        styles={styles}
        ref={selectRef}
        isClearable={isClearable || (isMulti && value.some((v) => !v.isFixed))}
        isDisabled={disabled}
        isLoading={isLoading}
        {...rest}
      />
      {canShowViewPlan && <PlanPreview id={value.value} />}
    </>
  );
};

GenericOptionsSelect.defaultProps = {
  showViewPlan: true,
};

export default GenericOptionsSelect;
