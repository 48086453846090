import { Button, Modal } from "orion";

const DisableFailSafeWarningMOdal = ({ isOpen, toggle, onContinue }) => {
  const para1 =
    "Disabling failsafe access will prevent MSP Administrators from using their password for signing in on MSC. Ensure your SSO settings are configured correctly.";

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      header="Disable failsafe Access"
      size="sm"
      body={() => (
        <div>
          <p>{para1}</p>
        </div>
      )}
      footer={() => (
        <div>
          <Button label="Close" onClick={toggle} />
          <Button
            label="Disable failsafe Access"
            onClick={onContinue}
            type="primary"
          />
        </div>
      )}
    />
  );
};

export default DisableFailSafeWarningMOdal;
