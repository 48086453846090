import React from "react";
import { Col, Row, LabelValue, Card } from "orion";
import { extendedDateFormatter } from "consts";

const EventSummaryCard = ({ event }) => {
  const isDescriptionJson = typeof event.details.Description === "object";
  const summaryCardBody = () => (
    <>
      <Row className="oui-mt-3">
        <Col sm={3}>
          <LabelValue
            className="oui-p-1"
            label="Event Type"
            value={event.type}
            type="summary"
          />
        </Col>
        <Col sm={3}>
          <LabelValue
            className="oui-p-1"
            label="Entity Name"
            value={event.details["Entity Name"]}
            type="summary"
          />
        </Col>
        <Col sm={3}>
          <LabelValue
            className="oui-p-1"
            label="Category"
            value={event.category}
            type="summary"
          />
        </Col>
        <Col sm={3}>
          <LabelValue
            className="oui-p-1"
            label="Area"
            value={event.area}
            type="summary"
          />
        </Col>
      </Row>
      <Row className="oui-mt-3">
        <Col sm={3}>
          <LabelValue
            className="oui-p-1"
            label="Generated On"
            value={extendedDateFormatter.format(
              new Date(event.occurenceTime * 1000)
            )}
            type="summary"
          />
        </Col>
        {!isDescriptionJson && (
          <Col sm={3}>
            <LabelValue
              className="oui-p-1"
              label="Event Description"
              value={event.details.Description}
              type="summary"
            />
          </Col>
        )}
        <Col sm={3}>
          <LabelValue
            className="oui-p-1"
            label="Action"
            value={event.details.Action}
            type="summary"
          />
        </Col>
        <Col sm={3}>
          <LabelValue
            className="oui-p-1"
            label="Entity Type"
            value={event.details["Entity Type"]}
            type="summary"
          />
        </Col>
        <Col sm={3}>
          <LabelValue
            className="oui-p-1"
            label="Email"
            value={event.details["Email Address"]}
            type="summary"
          />
        </Col>
      </Row>
      <Row className="oui-mt-3">
        <Col sm={3}>
          <LabelValue
            className="oui-p-1"
            label="IP Address"
            value={event.details["IP Address"]}
            type="summary"
          />
        </Col>
      </Row>
    </>
  );

  return <Card title="Summary" body={summaryCardBody} />;
};

export default EventSummaryCard;
