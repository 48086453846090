import React, { useEffect } from "react";
import Modal from "orion/lib/modal";
import Form from "orion/lib/form";
import FormGroup from "orion/lib/formGroup";
import Input from "orion/lib/input";
import Label from "orion/lib/label";
import Button from "orion/lib/button";
import Col from "orion/lib/col";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { helpArticles, HASH_GENERATE_SSO_TOKEN } from "consts.js";
import { useDispatch } from "react-redux";
import { showAlert } from "slices/alertsSlice";
import useSSOToken from "queryHooks/useSSOToken/useSSOToken";
import { getHelpPortalURL } from "utils/mspUtils";

const GenerateSSOTokenModal = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const { data: ssoToken, refetch } = useSSOToken();

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  return (
    <Modal
      isOpen={isOpen}
      size="sm"
      toggle={closeModal}
      header="Single Sign-On Token"
      body={() => (
        <>
          <p className="did-generate-sso-token-modal-info-paragraph">
            Use this token to update the Managed Services Center configuration
            in your IdP. This token helps to identify Managed Services Center
            sign-in requests. &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={getHelpPortalURL(
                helpArticles.ssoWithMsc + HASH_GENERATE_SSO_TOKEN,
              )}
              className="did-generate-sso-token-modal-click-here-link"
            >
              Learn More.
            </a>
          </p>
          <Form>
            <FormGroup row className="oui-no-gutters">
              <Label
                for="tokenGenerated"
                className="oui-col-4"
                text="Token Generated:"
              />
              <Col sm={6} md={6}>
                <Input
                  id="tokenGenerated"
                  type="text"
                  value={ssoToken || ""}
                  readOnly
                />
              </Col>
              <Col sm={2} md={2} className="oui-pl-2">
                <CopyToClipboard
                  onCopy={() => {
                    dispatch(
                      showAlert({
                        message: "Token copied to clipboard.",
                        type: "success",
                      }),
                    );
                  }}
                  text={ssoToken}
                >
                  <Button label="Copy" />
                </CopyToClipboard>
              </Col>
            </FormGroup>
          </Form>
        </>
      )}
      footer={() => <Button label="Close" onClick={closeModal} />}
    />
  );
};

export default GenerateSSOTokenModal;
