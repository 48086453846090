import { useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  genericErrorMessage,
  backupFrequencyLimitUnitDictionary,
  retentionLimitUnitDictionary,
  CAM_ATTRIBUTES,
  PRODUCT_ID,
  CAM_FEATURES,
} from "consts";
import { showAlert } from "slices/alertsSlice";
import { closeModal } from "slices/modalsSlice";
import useUpdateServicePlan from "queryHooks/useUpdateServicePlan/useUpdateServicePlan";
import useServicePlan from "queryHooks/useServicePlan/useServicePlan";
import useServicePlanWorkloadList from "queryHooks/useServicePlanWorkloadList/useServicePlanWorkloadList";
import useMsp from "queryHooks/useMsp/useMsp";
import { getAttributeValue, getFeature } from "utils/attributesUtils";

const useUpdateServicePlanForm = (servicePlanId) => {
  const dispatch = useDispatch();

  const queryConfig = useMemo(
    () => ({
      onError: (e) => {
        dispatch(closeModal("SERVICE_PLAN_FORM"));
        dispatch(
          showAlert({
            message: e.data.message || genericErrorMessage,
            type: "danger",
          }),
        );
      },
    }),
    [dispatch],
  );

  const { data: mspInfo } = useMsp();
  const { data: servicePlanData } = useServicePlan(servicePlanId, queryConfig);
  const { data: workloadListData } = useServicePlanWorkloadList(queryConfig);

  const useMutation = useUpdateServicePlan(servicePlanId);

  const initialValues = useMemo(() => {
    if (mspInfo && servicePlanData && workloadListData) {
      return {
        name: servicePlanData.name,
        products: (mspInfo?.products || [])
          .sort((p1, p2) => p1.productID - p2.productID)
          .map((details) => {
            const servicePlan = servicePlanData?.products.filter(
              (plan) => plan.productID === details.productID,
            )[0];
            const edition =
              servicePlan &&
              (details.skus || []).find((sku) => sku === servicePlan.edition);

            const editionOption = details.skus.map((sku) => ({
              label: sku,
              value: sku,
              isDisabled: edition
                ? edition === "elite"
                  ? sku === "business" || sku === "enterprise"
                  : edition === "enterprise"
                    ? sku === "business"
                    : false
                : false,
            }));

            return {
              editionOption,
              edition: edition
                ? editionOption.find((o) => o.value === edition)
                : null,
              productID: details.productID,
              features: (details.datasources || []).map((datasource) => {
                const feature = (servicePlan?.features || []).filter(
                  (feature) => feature.name === datasource.name,
                )?.[0];

                const backupFrequencyLimitUnit = getAttributeValue(
                  feature?.attrs,
                  CAM_ATTRIBUTES.backupFrequencyLimitUnit,
                );
                const retentionLimitUnit = getAttributeValue(
                  feature?.attrs,
                  CAM_ATTRIBUTES.retentionLimitUnit,
                );
                return {
                  name: datasource.name,
                  isEnabled: feature?.isEnabled || false,
                  attrs: {
                    datasourceType: datasource.name,
                    backupFrequencyLimitMin:
                      getAttributeValue(
                        feature?.attrs,
                        CAM_ATTRIBUTES.backupFrequencyLimitMin,
                      ) || 0,
                    backupFrequencyLimitMax:
                      getAttributeValue(
                        feature?.attrs,
                        CAM_ATTRIBUTES.backupFrequencyLimitMax,
                      ) || 0,
                    backupFrequencyLimitUnit: backupFrequencyLimitUnit
                      ? {
                          label:
                            backupFrequencyLimitUnitDictionary[
                              backupFrequencyLimitUnit
                            ],
                          value: backupFrequencyLimitUnit,
                        }
                      : null,
                    retentionLimitMin:
                      getAttributeValue(
                        feature?.attrs,
                        CAM_ATTRIBUTES.retentionLimitMin,
                      ) || 0,
                    retentionLimitMax:
                      getAttributeValue(
                        feature?.attrs,
                        CAM_ATTRIBUTES.retentionLimitMax,
                      ) || 0,
                    retentionLimitUnit: retentionLimitUnit
                      ? {
                          label:
                            retentionLimitUnitDictionary[retentionLimitUnit],
                          value: retentionLimitUnit,
                        }
                      : "",
                    workloads:
                      getAttributeValue(
                        feature?.attrs,
                        CAM_ATTRIBUTES.workloads,
                      )?.map(({ name }) => ({ label: name, value: name })) ||
                      [],
                  },
                };
              }),
              ...(details.productID === PRODUCT_ID.saasAppsAndEndpoints
                ? {
                    additionalData: {
                      storage: getFeature(
                        servicePlan?.features,
                        CAM_FEATURES.storage,
                      )
                        ? getFeature(
                            servicePlan?.features,
                            CAM_FEATURES.storage,
                          ).isEnabled
                        : false,
                      sensitiveDataGovernance: getFeature(
                        servicePlan?.features,
                        CAM_FEATURES.sensitiveDataGovernance,
                      )
                        ? getFeature(
                            servicePlan?.features,
                            CAM_FEATURES.sensitiveDataGovernance,
                          ).isEnabled
                        : false,
                    },
                  }
                : {}),
              isEnabled: servicePlan ? true : false,
            };
          }),
      };
    }
  }, [mspInfo, servicePlanData, workloadListData]);

  return [initialValues, useMutation];
};

export default useUpdateServicePlanForm;
