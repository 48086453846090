import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useUser from "queryHooks/useUser/useUser";

const Raygun = () => {
  const { data: userData } = useUser();

  const location = useLocation();

  useEffect(() => {
    if (typeof window.rg4js === "function" && userData) {
      const { globalId, email, firstName, lastName } = userData || {};
      window.rg4js("setUser", {
        identifier: globalId,
        isAnonymous: false,
        email,
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (typeof window.rg4js === "function") {
      window.rg4js("trackEvent", { type: "pageView", path: location.pathname });
    }
  }, [location]);

  return null;
};

export default Raygun;
