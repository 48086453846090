import { useQuery } from "react-query";
import { API_USER_CURRENT } from "consts";
import { useHttpClient } from "contexts/httpClient";

export default function useUser(config) {
  const httpClient = useHttpClient();
  return useQuery(
    ["UserCurrent"],
    () => {
      return httpClient.get(API_USER_CURRENT);
    },
    { ...config, staleTime: Infinity }
  );
}
