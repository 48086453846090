import React from "react";
import { Modal, Button } from "orion";
import { useDispatch } from "react-redux";

import useSuspendCustomerTenant from "queryHooks/useSuspendCustomerTenant/useSuspendCustomerTenant";
import { genericErrorMessage, productIdToName } from "consts";
import { showAlert } from "slices/alertsSlice";

const SuspendCustomerTenantActionModal = ({
  key,
  closeModal,
  customerId,
  tenantId,
  productId,
}) => {
  const dispatch = useDispatch();
  const { mutate } = useSuspendCustomerTenant(customerId, tenantId, {
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
    onSuccess: (data) => {
      if (data?.warning) {
        dispatch(
          showAlert({
            message: data?.message,
            type: "warning",
          })
        );
      } else {
        dispatch(
          showAlert({
            message: `${productIdToName[productId]} suspended successfully.`,
            type: "success",
          })
        );
      }
      closeModal();
    },
  });

  return (
    <Modal
      header="Confirmation"
      key={key}
      size="sm"
      isOpen
      toggle={closeModal}
      body={() => (
        <div>
          Are you sure you want to suspend {productIdToName[productId]} for the
          Customer Account?
        </div>
      )}
      footer={() => {
        return (
          <div>
            <Button label="Cancel" onClick={closeModal} />
            <Button type="primary" label="Suspend" onClick={mutate} />
          </div>
        );
      }}
    />
  );
};

export default SuspendCustomerTenantActionModal;
