import { SKU } from "consts";
import { ServicePlan } from "types/apis/ServicePlan";

export const getIsProductBusiness: (
  plan: ServicePlan,
  productID: number,
) => boolean = (servicePlan, productId) => {
  return !!servicePlan?.products?.find(
    (product) =>
      product.edition === SKU.business && product.productID === productId,
  );
};
