import React, { useCallback, useMemo, useState } from "react";
import { Modal, Button, Input, Label } from "orion";
import { useDispatch } from "react-redux";

import {
  genericErrorMessage,
  productIdToName,
  helpArticles,
} from "consts";
import { showAlert } from "slices/alertsSlice";
import useDeleteTenant from "queryHooks/useDeleteTenant/useDeleteTenant";
import useDeleteNowTenant from "queryHooks/useDeleteNowTenant/useDeleteNowTenant";
import OTPVerificationComponent from "./OTPVerificationComponent";
import useSendOTP from "queryHooks/useSendOTP/useSendOTP";
import useMsp from "queryHooks/useMsp/useMsp";
import "./DeleteCustomerActionModal.scss";
import { getHelpPortalURL } from "utils/mspUtils";

const DeleteCustomerActionModal = ({
  key,
  closeModal,
  customerId,
  tenantId,
  productId,
}) => {
  const [selectedAction, setSelectedAction] = useState();
  const [showDeleteTnC, setShowDeleteTnC] = useState(false);
  const [tncAccepted, setTncAccepted] = useState(false);
  const [otpSent, setOTPSent] = useState(false);
  const [otpExpiry, setOtpExpiry] = useState();
  const [otpId, setOtpId] = useState();
  const [otpCode, setOtpCode] = useState();
  const dispatch = useDispatch();
  const { data: mspInfo } = useMsp();

  const { mutate: sendOTP } = useSendOTP(customerId, {
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || "OTP could not be sent",
          type: "danger",
        })
      );
    },
    onSuccess: (data) => {
      setOtpExpiry(Date.now() + (5 * 60 - 1) * 1000);
      setOtpId(data.otpId);
      setOTPSent(true);
    },
  });

  const { mutate: resendOTP } = useSendOTP(
    customerId,
    {
      onError: (e) => {
        dispatch(
          showAlert({
            message: e.data?.message || "OTP could not be sent",
            type: "danger",
          })
        );
      },
      onSuccess: (data) => {
        setOtpExpiry(Date.now() + (5 * 60 - 1) * 1000);
        setOtpId(data.otpId);
        setOTPSent(true);
      },
    },
    true,
    otpId
  );

  const { mutate: deleteAction } = useDeleteTenant(customerId, tenantId, {
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
    onSuccess: () => {
      dispatch(
        showAlert({
          message: `${productIdToName[productId]} successfully marked for deletion.`,
          type: "success",
        })
      );
      closeModal();
    },
  });

  const { mutate: deleteNowAction } = useDeleteNowTenant(
    customerId,
    tenantId,
    otpId,
    otpCode,
    {
      onError: (e) => {
        dispatch(
          showAlert({
            message: e.data?.message || genericErrorMessage,
            type: "danger",
          })
        );
      },
      onSuccess: () => {
        dispatch(
          showAlert({
            message: `${productIdToName[productId]} successfully marked for deletion.`,
            type: "success",
          })
        );
        closeModal();
      },
    }
  );

  const handleDeleteRadio = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setSelectedAction(value);

    switch (value) {
      case "delete":
        setShowDeleteTnC(false);
        setTncAccepted(false);
        break;
      case "deleteNow":
        setShowDeleteTnC(true);
        break;
      default:
        break;
    }
  }, []);

  const acceptTncCheckboxHandler = useCallback(
    (e) => {
      setOTPSent(false);
      setOtpExpiry(null);
      setOtpCode(null);
      setOtpId(null);
      setTncAccepted(e.currentTarget.checked);
    },
    [setTncAccepted]
  );

  const onOtpChangeCallback = useCallback(
    (otp) => {
      setOtpCode(otp);
    },
    [setOtpCode]
  );

  const onConfirm = useCallback(() => {
    switch (selectedAction) {
      case "delete":
        deleteAction();
        break;
      case "deleteNow":
        deleteNowAction();
        break;
      default:
        break;
    }
  }, [selectedAction, deleteAction, deleteNowAction]);

  const isDeleteDisabled = useCallback(() => {
    if (
      selectedAction === "delete" ||
      (tncAccepted && otpId && otpCode && otpCode.length === 6)
    ) {
      return false;
    }
    return true;
  }, [tncAccepted, otpId, otpCode, selectedAction]);

  const versionModalProps = useMemo(() => {
    return {
      header: "Delete Confirmation",
      body: () => (
        <div>
          <Input
            type="radio"
            id="delete-action"
            value="delete"
            name="deleteActionType"
            label={"Delete product for customer"}
            onChange={handleDeleteRadio}
            className="font-weight-600"
          />

          <Label
            className="oui-pl-4 font-12"
            text="The product will get suspended for 30 days. All the associations, storage, and data will be permanently deleted after 30 days."
            for="delete-action"
          />
          <Input
            type="radio"
            id="delete-now-action"
            value="deleteNow"
            name="deleteActionType"
            label="Delete product for customer now"
            onChange={handleDeleteRadio}
            className="oui-mt-2 font-weight-600"
            disabled={
              !mspInfo?.permissions?.allowedToDeleteNow ||
              new Date(mspInfo?.permissions?.allowedToDeleteNowExpiryDate) <
                new Date()
            }
          />
          <Label
            className="oui-pl-4 font-12"
            text="The product will get deleted immediately. All the associations, storage, and data will be permanently deleted after 24 hours."
            for="delete-now-action"
          />
          {showDeleteTnC && (
            <>
              <Input
                key="delete-tnc"
                inline
                type="checkbox"
                id={"delete-tnc"}
                name="delete-tnc"
                className="oui-ml-4"
                value={tncAccepted}
                label="I acknowledge the risks involved and give my consent to delete this account."
                onChange={acceptTncCheckboxHandler}
              />
              <a
                href={getHelpPortalURL(helpArticles.createAndManageCustomers)}
                target="_blank"
                rel="noreferrer"
                className="oui-d-block oui-ml-4 oui-pl-4"
              >
                Learn more
              </a>
            </>
          )}
          {tncAccepted && (
            <OTPVerificationComponent
              sendOtpCallback={sendOTP}
              resendOtpCallback={resendOTP}
              formikInitialValues={{ otpVerificationActive: otpSent }}
              otpExpiry={otpExpiry}
              onOtpChangeCallback={onOtpChangeCallback}
            />
          )}
        </div>
      ),
      footer: () => (
        <div>
          <Button
            dataTestId="close-delete-modal-btn"
            label="Cancel"
            onClick={closeModal}
          />
          <Button
            dataTestId="confirm-delete-modal-btn"
            label="Delete"
            type="danger"
            onClick={onConfirm}
            disabled={isDeleteDisabled()}
          />
        </div>
      ),
    };
  }, [
    acceptTncCheckboxHandler,
    closeModal,
    handleDeleteRadio,
    onConfirm,
    otpSent,
    sendOTP,
    showDeleteTnC,
    tncAccepted,
    mspInfo,
    otpExpiry,
    resendOTP,
    onOtpChangeCallback,
    isDeleteDisabled,
  ]);

  return (
    <Modal
      key={key}
      size="md"
      isOpen
      toggle={closeModal}
      {...versionModalProps}
    />
  );
};

export default DeleteCustomerActionModal;
