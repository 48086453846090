import { useMemo } from "react";
import { useDispatch } from "react-redux";

import { genericErrorMessage } from "consts";
import { showAlert } from "slices/alertsSlice";
import useAdministrator from "queryHooks/useAdministrator/useAdministrator";
import useUpdateAdministrator from "queryHooks/useUpdateAdministrator/useUpdateAdministrator";
import useCustomerList from "queryHooks/useCustomerList/useCustomerList";

const useUpdateAdministratorForm = (adminId) => {
  const dispatch = useDispatch();

  const queryConfig = useMemo(
    () => ({
      onError: (e) => {
        dispatch(
          showAlert({
            message: e.data?.message || genericErrorMessage,
            type: "danger",
          }),
        );
      },
    }),
    [dispatch],
  );

  const { data: administratorData } = useAdministrator(adminId, queryConfig);
  const { data: customerListData, isFetchedAfterMount: isCustomersFetched } =
    useCustomerList({ isForOptions: true, ...queryConfig });

  const useMutation = useUpdateAdministrator(adminId);

  const initialValues = useMemo(() => {
    if (isCustomersFetched && administratorData && customerListData?.list) {
      const customers = customerListData.list.filter(({ id }) =>
        (administratorData.customers || []).includes(id),
      );

      return {
        firstName: administratorData.firstName,
        lastName: administratorData.lastName,
        email: administratorData.email,
        phone: administratorData.phone,
        customers: customers.map(({ id, accountName }) => ({
          label: accountName,
          value: id,
          isDisabled: false,
          isFixed: false,
        })),
        role: administratorData.role,
      };
    }
  }, [administratorData, customerListData?.list, isCustomersFetched]);

  return [initialValues, useMutation];
};

export default useUpdateAdministratorForm;
