import axios from "axios";
import { useMutation } from "react-query";
import { stringify } from "query-string";

import { cookies, LOGIN_REDIRECT_URL } from "consts";
import refreshToken from "contexts/refreshToken";

export default function useRefreshToken(config) {
  return useMutation(
    () =>
      refreshToken(axios, cookies.get("msp_refreshToken"))
        .then(({ data: { access_token: accessToken } }) => {
          return { accessToken };
        })
        .catch(() => {
          window.location = `${LOGIN_REDIRECT_URL}?${stringify({
            redirectUrl: window.location,
          })}`;
          console.log("Redirecting - unsuccessfully token refreshing.");
        }),
    {
      ...(config || {}),
    }
  );
}
