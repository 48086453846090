import { useQuery } from "react-query";

import { API_SETTINGS, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import useMspId from "queryHooks/useMspId/useMspId";

export default function useIdleSessionTimeout(config) {
  const httpClient = useHttpClient();
  const { data } = useMspId();
  const URL = withUrlParams(API_SETTINGS, { mspId: data?.mspId });
  return useQuery(
    [QUERIES.settings, data?.mspId],
    () => {
      return httpClient.get(URL);
    },
    {
      enabled: !!data?.mspId,
      ...config,
    }
  );
}
