import { PRODUCT_ID } from "consts";
import useCreateServicePlan from "queryHooks/useCreateServicePlan/useCreateServicePlan";
import useMsp from "queryHooks/useMsp/useMsp";

const useCreateServicePlanForm = () => {
  const useMutation = useCreateServicePlan();
  const { data: mspInfo } = useMsp();

  let initialValues = null;
  if (mspInfo) {
    initialValues = {
      name: "",
      products: (mspInfo?.products || [])
        .sort((p1, p2) => p1.productID - p2.productID)
        .map((details) => {
          return {
            productID: details.productID,
            edition: null,
            features: (details.datasources || []).map((datasource) => ({
              name: datasource.name,
              isEnabled: false,
              attrs: {
                datasourceType: datasource.name,
                backupFrequencyLimitMin: 0,
                backupFrequencyLimitMax: 0,
                backupFrequencyLimitUnit: "",
                retentionLimitMin: 0,
                retentionLimitMax: 0,
                retentionLimitUnit: "",
                workloads: [],
              },
            })),
            ...(details.productID === PRODUCT_ID.saasAppsAndEndpoints
              ? {
                  additionalData: {
                    storage: false,
                    sensitiveDataGovernance: false,
                  },
                }
              : {}),
            editionOption: details.skus.map((sku) => ({
              label: sku,
              value: sku,
            })),
            isEnabled: false,
          };
        }),
    };
  }

  return [initialValues, useMutation];
};

export default useCreateServicePlanForm;
