import { Input, FormGroup, Col, Label } from "orion";
import React from "react";

import ModalFormField from "components/ModalFormField/ModalFormField";
import { genericSelectTypes, rolesLabels } from "consts";
import GenericOptionsSelect from "components/GenericOptionsSelect";

const FormContainer = ({ formikProps, isEdit }) => {
  return (
    <>
      <ModalFormField
        label="First Name"
        name="firstName"
        formikProps={formikProps}
      />
      <ModalFormField
        label="Last Name"
        name="lastName"
        formikProps={formikProps}
      />
      <ModalFormField
        label="Email"
        name="email"
        formikProps={formikProps}
        disabled={isEdit}
      />
      <ModalFormField
        type="phone"
        label="Phone"
        placeholder="Enter Number"
        name="phone"
        formikProps={formikProps}
      />
      <FormGroup row className="oui-mb-0">
        <Col sm={4}>
          <Label className="oui-col-form-label" muted text="Role" />
        </Col>
        <Col sm={6} className="oui-mt-auto">
          {isEdit ? (
            <>
              <Input
                type="text"
                value={rolesLabels[formikProps.initialValues.role]}
                disabled
              />
              <div
                className={
                  formikProps.initialValues.role === 3
                    ? "oui-mt-3"
                    : "oui-d-none oui-mt-3"
                }
              >
                <Label muted text="Customers" />
                <GenericOptionsSelect
                  type={genericSelectTypes.customers}
                  isMulti
                  name="customers"
                  placeholder="Select Customer"
                  formikProps={formikProps}
                />
              </div>
            </>
          ) : (
            <>
              <Input
                type="radio"
                value="2"
                className="oui-my-2"
                name="role"
                label="MSP Administrator"
                validationProps={formikProps}
              />
              <Input
                type="radio"
                value="3"
                className="oui-my-2"
                name="role"
                label="Tenant Administrator"
                validationProps={formikProps}
              />
              {formikProps.values.role === "3" && (
                <GenericOptionsSelect
                  type={genericSelectTypes.customers}
                  isMulti
                  name="customers"
                  placeholder="Select Customer"
                  formikProps={formikProps}
                />
              )}
              <Input
                type="radio"
                value="4"
                className="oui-my-2"
                name="role"
                label="Read Only Administrator"
                validationProps={formikProps}
              />
            </>
          )}
        </Col>
      </FormGroup>
    </>
  );
};
export default FormContainer;
