import { useMutation, useQueryClient } from "react-query";
import { API_TENANTS, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";
import {
  duplicatePollTaskStatusForSecurityAddonUpdateAsync,
  pollTaskStatus,
} from "queryHooks/useJobStatus/useJobStatus";
import { useDispatch } from "react-redux";

export default function useUpdateTenant(
  { customerId, tenantId, isSecurityAddonUpdate = false },
  config
) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const updateTenant = (data) =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await httpClient.patch(
          withUrlParams(API_TENANTS, { customerId, tenantId }),
          data
        );
        if (isSecurityAddonUpdate) {
          const pollRes =
            await duplicatePollTaskStatusForSecurityAddonUpdateAsync(
              httpClient,
              res?.task?.id,
              dispatch
            );
          pollRes.originalPayload = data;
          pollRes.tenantId = tenantId;
          resolve(pollRes);
        } else {
          pollTaskStatus(httpClient, res?.task?.id, resolve, reject, dispatch);
        }
      } catch (err) {
        err.originalPayload = data;
        err.tenantId = tenantId;
        return reject(err);
      }
    });

  return useMutation((data) => updateTenant(data), {
    ...config,
    onSuccess: (data) => {
      queryClient.invalidateQueries(QUERIES.tenant);
      queryClient.invalidateQueries(QUERIES.customersTenants);
      queryClient.invalidateQueries(QUERIES.customersWithTenants);
      config?.onSuccess?.(data);
    },
  });
}
