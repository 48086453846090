import { createSlice } from "@reduxjs/toolkit";

const initialPreferencesState = {
    jobsTimezone: null,
};

const preferencesSlice = createSlice({
    name: "preferences",
    initialState: initialPreferencesState,
    reducers: {
        setPreferencesJobsTimezone(state, { payload }) {
            state.jobsTimezone = payload;
        },
    },
});

export const { setPreferencesJobsTimezone } = preferencesSlice.actions;

export default preferencesSlice.reducer;

export const getPreferencesJobsTimezone = (state) => state.preferences.jobsTimezone;
