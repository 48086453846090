import { useState } from "react";
import Input from "orion/lib/input";
import Col from "orion/lib/col";
import FormGroup from "orion/lib/formGroup";
import ModalForm from "shared/ModalForm";
import * as Yup from "yup";
import { Card, Label, Loader } from "orion";
import { isValidCertificate, isValidURL } from "utils/formUtils";
import useSSOConfig from "queryHooks/useSSOConfig/useSSOConfig";
import MFAWarningModal from "./MFAWarningModal";
import DisableFailSafeWarningMOdal from "./DisableFailSafeWarningModal";
import useUpdateSSOConfig from "queryHooks/useUpdateSSOConfig/useUpdateSSOConfig";
import { useDispatch } from "react-redux";
import { showAlert } from "slices/alertsSlice";

const IDProviderConfigCard = ({ formikProps }) => {
  return (
    <Card
      title="ID Provider Configuration"
      body={() => (
        <>
          <FormGroup row className="oui-no-gutters">
            <Label
              for="loginURL"
              text="ID Provider Login URL"
              className="oui-col-4"
            />
            <Col sm={8} md={8}>
              <Input
                type="text"
                id="loginURL"
                name="loginURL"
                maxLength={2048}
                validationProps={formikProps}
                isInvalid={
                  formikProps.touched.loginURL &&
                  formikProps.errors.loginURL !== undefined
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row className="oui-no-gutters">
            <Label
              for="certificate"
              text="ID Provider Certificate"
              className="oui-col-4"
            />
            <Col sm={8} md={8}>
              <Input
                type="textarea"
                id="certificate"
                name="certificate"
                placeholder={" Provide a certificate in PEM format."}
                maxLength={64000}
                validationProps={formikProps}
                isInvalid={
                  formikProps.touched.certificate &&
                  formikProps.errors.certificate !== undefined
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row className="oui-no-gutters">
            <Label
              for="authRequestsSigned"
              text="AuthnRequests Signed"
              className="oui-col-4"
            />
            <Col sm={8} md={8}>
              <Input
                type="checkbox"
                id="authRequestsSigned"
                name="authRequestsSigned"
                validationProps={formikProps}
              />
            </Col>
          </FormGroup>
          <FormGroup row className="oui-no-gutters">
            <Label
              for="needAssertionsEncrypted"
              text="Encrypt Assertions"
              className="oui-col-4"
            />
            <Col sm={8} md={8}>
              <Input
                type="checkbox"
                id="needAssertionsEncrypted"
                name="needAssertionsEncrypted"
                validationProps={formikProps}
              />
            </Col>
          </FormGroup>
          {/* {(authRequestsSigned || needAssertionsEncrypted) && (
            <FormGroup>
              <hr />
              <Row>
                <Col>
                  <Heading text="SSO SAML Certificate" type="6" inline={true} />
                  <UXConfigContext.Consumer>
                    {({ docPortalFQDN }) => (
                      <a
                        href={getDocPortalURL(
                          docPortalFQDN,
                          DCP_UPDATE_SSO_CERTIFICATE
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <HelpGreyOutline size="sm" className="oui-ml-2" />
                      </a>
                    )}
                  </UXConfigContext.Consumer>
                </Col>
              </Row>

              {samlCertificationList?.length > 0 ? (
                samlCertificationList.map((cert, ind) => (
                  <Row key={ind}>
                    <Col>
                      {`${cert.cert_name} (Expires on ${cert.expiry_date} UTC)`}
                      <Button
                        type="link"
                        size="md"
                        onClick={() =>
                          crtDownload(cert.certificate, cert.cert_name + ".crt")
                        }
                        label="Download"
                        className="oui-mb-1"
                      />
                      {cert.is_primary && (
                        <Badge
                          label="New"
                          className="oui-mr-3"
                          clickable={false}
                          style={{
                            color: "#856404",
                            backgroundColor: "#FFF3CD",
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                ))
              ) : (
                <Row>
                  <Col className="oui-text-muted oui-mt-3">
                    <CrossRedOutline size="sm" className="oui-mb-1" />
                    <span className="oui-ml-2">
                      Unable to get the certificate information due to an
                      internal error. Contact support.
                    </span>
                  </Col>
                </Row>
              )}
            </FormGroup>
          )} */}
        </>
      )}
    />
  );
};

const AccessControlConfigCard = ({ formikProps }) => {
  const [showMFAWarning, setShowMFAWarning] = useState(false);
  const [showDisableFailSafeWarning, setShowDisableFailSafeWarning] =
    useState(false);

  const toggleShowMFAWarning = () => setShowMFAWarning((show) => !show);

  const toggleDisableFailSafeWarning = () =>
    setShowDisableFailSafeWarning((show) => !show);

  const { isFailsafeEnabled, isSSOEnabled, isCustomEntityIdInUse } =
    formikProps.values;

  return (
    <Card
      title="Access Control"
      body={() => (
        <>
          <FormGroup row className="oui-no-gutters">
            <Label
              for="isSSOEnabled"
              text="Single Sign-On"
              className="oui-col-4"
            />
            <Col sm={8} md={8}>
              <Input
                type="checkbox"
                id="isSSOEnabled"
                name="isSSOEnabled"
                validationProps={{
                  ...formikProps,
                  handleChange: toggleShowMFAWarning,
                }}
                label="Sign in to MSC using an SSO provider"
              />
              <div>
                <small>
                  This change will take effect upon the next sign-in of the
                  administrators on Managed Services Center.
                </small>
              </div>
            </Col>
          </FormGroup>
          <FormGroup row className="oui-no-gutters">
            <Label
              for="isFailsafeEnabled"
              text="Failsafe Administrators"
              className="oui-col-4"
            />
            <Col sm={8} md={8}>
              <Input
                type="checkbox"
                id="isFailsafeEnabled"
                name="isFailsafeEnabled"
                disabled={!isSSOEnabled}
                validationProps={{
                  ...formikProps,
                  handleChange: (e) => {
                    if (!e.target.checked) {
                      toggleDisableFailSafeWarning();
                    } else {
                      formikProps.handleChange(e);
                    }
                  },
                }}
                label="Allow failsafe access to MSP administrators (recommended)"
              />
              <div>
                <small>
                  Allow MSP Administrators to sign in using their password as
                  well.
                </small>
              </div>
            </Col>
          </FormGroup>
          <MFAWarningModal
            isOpen={showMFAWarning}
            toggle={toggleShowMFAWarning}
            onContinue={() => {
              formikProps.setFieldValue("isSSOEnabled", !isSSOEnabled);
              formikProps.setFieldValue("isFailsafeEnabled", true);

              toggleShowMFAWarning();
            }}
            isSSOEnabled={isSSOEnabled}
          />
          <DisableFailSafeWarningMOdal
            isOpen={showDisableFailSafeWarning}
            toggle={toggleDisableFailSafeWarning}
            isCustomEntityIdInUse={isCustomEntityIdInUse}
            onContinue={() => {
              formikProps.setFieldValue(
                "isFailsafeEnabled",
                !isFailsafeEnabled
              );
              toggleDisableFailSafeWarning();
            }}
          />
        </>
      )}
      className="oui-mt-3"
    />
  );
};

const EditSingleSignOnModal = ({ closeModal, isOpen }) => {
  const { data: ssoConfig, isFetching } = useSSOConfig();
  const { mutate: updateSSOConfig, isLoading: updatingSSOConfig } =
    useUpdateSSOConfig();

  const dispatch = useDispatch();

  const initialValue = {
    samlIDPConfig: {
      loginURL: "",
      certificate: "",
      authRequestsSigned: false,
      needAssertionsEncrypted: false,
    },

    isSSOEnabled: false,
    isFailsafeEnabled: false,
  };
  const {
    samlIDPConfig: {
      loginURL,
      certificate,
      authRequestsSigned,
      needAssertionsEncrypted,
    },
    isSSOEnabled,
    isFailsafeEnabled,
  } = ssoConfig || initialValue;

  const onSubmitHandler = (values) => {
    updateSSOConfig(
      {
        isFailsafeEnabled: values.isFailsafeEnabled,
        samlIDPConfig: {
          authRequestsSigned: values.authRequestsSigned,
          certificate: values.certificate,
          loginURL: values.loginURL,
          needAssertionsEncrypted: values.needAssertionsEncrypted,
        },
        isSSOEnabled: values.isSSOEnabled,
      },
      {
        onSuccess: () => {
          closeModal();
          dispatch(
            showAlert({
              message: "Single Sign-On settings saved.",
              type: "success",
            })
          );
        },
        onError: (e) => {
          dispatch(
            showAlert({
              message:
                e.data.message || "Single Sign-On settings could not be saved.",
              type: "danger",
            })
          );
        },
      }
    );
  };

  return (
    <ModalForm
      size="lg"
      disableSubmitWhenInvalid
      closeModal={closeModal}
      withValidation
      initialValues={{
        loginURL,
        certificate,
        authRequestsSigned,
        needAssertionsEncrypted,
        isSSOEnabled,
        isFailsafeEnabled,
      }}
      validationSchema={() =>
        Yup.object().shape({
          loginURL: Yup.string()
            .required("Required")
            .test("loginURL", "Incorrect login URL format.", (value) =>
              isValidURL(value)
            ),
          certificate: Yup.string()
            .required("Required")
            .test("certificate", "Incorrect IdP certificate format.", (value) =>
              isValidCertificate(value)
            ),
        })
      }
      header="Edit Single Sign-On Settings"
      isOpen={isOpen}
      onSubmit={onSubmitHandler}
      renderChildren={(formikProps) => (
        <Loader isLoading={isFetching || updatingSSOConfig}>
          <IDProviderConfigCard formikProps={formikProps} />
          <AccessControlConfigCard formikProps={formikProps} />
        </Loader>
      )}
      submitButtonLabel="Save"
    />
  );
};

export default EditSingleSignOnModal;
