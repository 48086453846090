import { useMutation, useQueryClient } from "react-query";
import { API_SERVICE_PLANS, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";

export default function useCreateServicePlan(config) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();

  return useMutation((data) => httpClient.post(API_SERVICE_PLANS, data), {
    ...config,
    onSuccess: (data) => {
      queryClient.invalidateQueries(QUERIES.servicePlanList);
      config?.onSuccess?.(data);
    },
  });
}
