import { ReactComponent as NoWorkloadsSVG } from "assets/images/noWorkloads.svg";

const NoProductConfiguredInfo = () => {
  return (
    <div className="oui-p-5">
      <div className="oui-d-flex oui-flex-column oui-justify-content-center oui-align-items-center oui-p-5">
        <NoWorkloadsSVG />
        <h6>No Product Configured</h6>
        <div>
          Security Posture & Observability will be enabled by default for all
          products in the future.
        </div>
      </div>
    </div>
  );
};

export default NoProductConfiguredInfo;
