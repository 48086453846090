import React, {
  useMemo,
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import { stringify } from "query-string";
import { LOGIN_REDIRECT_URL, API_REFRESH_TOKEN, DOMAIN, cookies } from "consts";
import { useDispatch } from "react-redux";
import { setUserId } from "slices/userSlice";
import jwtDecode from "jwt-decode";

import refreshToken from "./refreshToken";

if (process.env.NODE_ENV === "development") {
  if (process.env.REACT_APP_USE_MOCK === "true") {
    cookies.set("refreshToken", "tokenvalue", {
      domain: DOMAIN,
      path: "/",
      secure: true,
      sameSite: "strict",
    });
    const { worker } = require("mocks/worker");
    worker.start();
  } else if (!cookies.get("msp_refreshToken")) {
    const refreshToken = window.prompt("provide msp refresh toekn");
    cookies.set("refreshToken", refreshToken, {
      path: "/",
    });
  }
}

const HttpClientContext = createContext();

if (cookies.get("refreshToken")) {
  cookies.set("msp_refreshToken", cookies.get("refreshToken"), {
    maxAge: 24 * 59 * 60,
    path: "/",
    secure: true,
    sameSite: "strict",
  });
  cookies.remove("refreshToken", {
    domain: DOMAIN,
    path: "/",
    secure: true,
    sameSite: "strict",
  });
}

if (!cookies.get("msp_refreshToken")) {
  window.location = `${LOGIN_REDIRECT_URL}?${stringify({
    redirectUrl: window.location,
  })}`;
  console.log("Redirecting - missing refreshToken cookie");
}

export const HttpClientProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState();

  const dispatch = useDispatch();

  const updateAccessToken = useCallback(
    async (accessToken) => {
      setAccessToken(accessToken);
      const { userId } = jwtDecode(accessToken);
      dispatch(
        setUserId({
          id: userId,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    const initialAccessToken = cookies.get("accessToken");
    cookies.remove("accessToken", {
      domain: DOMAIN,
      path: "/",
      secure: true,
      sameSite: "strict",
    });
    if (!initialAccessToken) {
      refreshToken(axios, cookies.get("msp_refreshToken"))
        .then(({ data: { access_token: accessToken } }) => {
          updateAccessToken(accessToken);
        })
        .catch(() => {
          window.location = `${LOGIN_REDIRECT_URL}?${stringify({
            redirectUrl: window.location,
          })}`;
          console.log("Redirecting - unsuccessfully token refreshing.");
        });
    } else {
      updateAccessToken(initialAccessToken);
    }
  }, [updateAccessToken]);

  const axiosClient = useMemo(() => {
    const httpClient = axios.create();
    httpClient.interceptors.request.use((config) => {
      if (accessToken && config.url !== API_REFRESH_TOKEN && !config.isReady) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    });

    httpClient.interceptors.response.use(
      (res) => res.data,
      (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest.isReady) {
          originalRequest.isReady = true;
          if (cookies.get("msp_refreshToken")) {
            return refreshToken(httpClient, cookies.get("msp_refreshToken"))
              .then(({ access_token: accessToken }) => {
                updateAccessToken(accessToken);
                originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                return httpClient(originalRequest);
              })
              .catch((e) => {
                if (e.config.url === API_REFRESH_TOKEN) {
                  window.location = `${LOGIN_REDIRECT_URL}?${stringify({
                    redirectUrl: window.location,
                  })}`;

                  console.log("Redirecting - unsuccessfully token refreshing.");
                } else {
                  throw e;
                }
              });
          } else {
            window.location = `${LOGIN_REDIRECT_URL}?${stringify({
              redirectUrl: window.location,
            })}`;
            console.log("Redirecting - msp_refreshToken expired");
          }
        }
        throw error?.response || error;
      }
    );

    return httpClient;
  }, [accessToken, updateAccessToken]);

  return (
    <HttpClientContext.Provider value={axiosClient}>
      {accessToken && children}
    </HttpClientContext.Provider>
  );
};

export function useHttpClient() {
  return useContext(HttpClientContext);
}
