import useScript from "queryHooks/useScript";
import useUser from "queryHooks/useUser/useUser";
import createPayload from "./createPayload";

const GainsightAnalytics = () => {
  const { data: adminInfo } = useUser();
  const callback = () => {
    const payload = createPayload(adminInfo);
    if (window.inProductExperience) {
      window.inProductExperience.loadAndInitialize(payload);
    }
  };

  useScript(window._env_.GAINSIGHT_SCRIPT_URL, "gainsight-script", callback);

  return null;
};

export default GainsightAnalytics;
