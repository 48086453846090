import { useQuery } from "react-query";

import { QUERIES, usageReportsFrequenciesDictionary } from "consts";

const usageReportFrequencyOptions = Object.entries(
  usageReportsFrequenciesDictionary
).map(([id, name]) => ({ id: +id, name }));

export default function useUsageReportsFrequencies(config) {
  return useQuery(
    [QUERIES.usageReportsFrequencyList],
    () => {
      return new Promise((resolve) => {
        resolve(usageReportFrequencyOptions);
      });
    },
    config
  );
}
