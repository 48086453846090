import { useQuery } from "react-query";
import { API_SERVICE_PLAN_WORKLOAD_LIST, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";

export default function useServicePlanWorkloadList(config) {
  const httpClient = useHttpClient();
  return useQuery(
    [QUERIES.servicePlanWorkloadList],
    () => {
      return httpClient.get(API_SERVICE_PLAN_WORKLOAD_LIST);
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      ...config,
    }
  );
}
