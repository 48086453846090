import useCreateCustomer from "queryHooks/useCreateCustomer/useCreateCustomer";

const initialValues = {
  accountName: "",
  address: "",
  phone: "",
  alternateNameToDruva: false,
  customerName: "",
  tenantAdmins: [],
};

const useCreateCustomerForm = () => {
  const useMutation = useCreateCustomer();

  return [initialValues, useMutation];
};

export default useCreateCustomerForm;
