import { useQuery } from "react-query";
import { API_SERVICE_PLAN, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import withUrlParams from "utils/withUrlParams";

export default function useServicePlan(servicePlanId, config) {
  const httpClient = useHttpClient();
  return useQuery(
    [QUERIES.servicePlan, servicePlanId],
    () => {
      return httpClient.get(withUrlParams(API_SERVICE_PLAN, { servicePlanId }));
    },
    {
      enabled: !!servicePlanId,
      ...config,
    }
  );
}
