import Tooltip from "orion/lib/tooltip";
import DeleteGreyOutline from "@druva-apps/orion-icons/lib/DeleteGreyOutline";

const DeletedIcon = ({ id }) => (
  <Tooltip text="Marked for Deletion">
    <div className="oui-d-flex oui-align-items-center oui-text-danger oui-ml-2 oui-mr-2">
      <DeleteGreyOutline id={id} inheritFontColor />
    </div>
  </Tooltip>
);

export default DeletedIcon;
