import React, { useMemo } from "react";
import { Modal, Button, Row, Col } from "orion";
import { useDispatch } from "react-redux";

import { genericErrorMessage, productIdToName } from "consts";
import { showAlert } from "slices/alertsSlice";
import "./DeleteCustomerActionModal.scss";
import useUndeleteTenant from "queryHooks/useUndeleteTenant/useUndeleteTenant";

const UndeleteCustomerActionModal = ({
  key,
  closeModal,
  customerId,
  tenantId,
  productId,
}) => {
  const dispatch = useDispatch();

  const { mutate: undeleteAction } = useUndeleteTenant(customerId, tenantId, {
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
    onSuccess: (data) => {
      if (data?.warning) {
        dispatch(
          showAlert({
            message: data?.message,
            type: "warning",
          })
        );
      } else {
        dispatch(
          showAlert({
            message: `${productIdToName[productId]} has been undeleted successfully for customer account.`,
            type: "success",
          })
        );
      }
      closeModal();
    },
  });

  const versionModalProps = useMemo(() => {
    return {
      header: "Undelete Confirmation",
      body: () => (
        <div>
          <Row>
            <Col>Are you sure you want to undelete selected product?</Col>
          </Row>
        </div>
      ),
      footer: () => (
        <div>
          <Button
            dataTestId="close-undelete-modal-btn"
            label="No"
            onClick={closeModal}
          />
          <Button
            dataTestId="confirm-undelete-modal-btn"
            label="Yes"
            type="primary"
            onClick={undeleteAction}
          />
        </div>
      ),
    };
  }, [closeModal, undeleteAction]);

  return (
    <Modal
      key={key}
      size="sm"
      isOpen
      toggle={closeModal}
      {...versionModalProps}
    />
  );
};

export default UndeleteCustomerActionModal;
