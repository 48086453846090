import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { genericErrorMessage } from "consts";

import { showAlert } from "slices/alertsSlice";
import ModalForm from "shared/ModalForm";

import FormContainer from "./FormContainer";
import useCreateAdministratorForm from "./useCreateAdministratorForm";
import useUpdateAdministratorForm from "./useUpdateAdministratorForm";
import styles from "./AdministratorForm.module.scss";
import useAdministratorStatsV2 from "queryHooks/useAdministratorListV2/useAdministratorStatsV2";

const validationSchemaCreator = (Yup) =>
  Yup.object().shape({
    firstName: Yup.string().max(30, "Max 30 chars").required("Required"),
    lastName: Yup.string().max(30, "Max 30 chars").required("Required"),
    email: Yup.string()
      .email("Enter a valid email address.")
      .max(100, "Max 100 chars")
      .required("Required")
      .test(
        "restricted emails",
        "This email is part of restricted public domains: Please use your corporate email address.",
        (value) => {
          const whitlistedDomains = ["yahoo", "gmail", "hotmail", "rediff"];
          return !whitlistedDomains.some((domain) =>
            value?.includes(`@${domain}`)
          );
        }
      ),
    phone: Yup.string()
      .min(6, "Min 6 chars")
      .max(30, "Max 30 chars")
      .required("Required"),
    role: Yup.string().required("Required"),
  });

const AdministratorForm = ({
  closeModal,
  header,
  isOpen,
  isEdit,
  administratorId,
}) => {
  const dispatch = useDispatch();
  const { haveOnlyOneMSPAdmin } = useAdministratorStatsV2();

  const { current: useMutation } = useRef(
    isEdit ? useUpdateAdministratorForm : useCreateAdministratorForm
  );

  const [initialValues, { mutate }] = useMutation(administratorId);

  const onSubmitHandler = useCallback(
    ({ firstName, lastName, email, phone, customers, role }) => {
      if (haveOnlyOneMSPAdmin && ["3", "4"].includes(role)) {
        dispatch(
          showAlert({
            message:
              "Select MSP Administrator as the role type for this administrator.",
            type: "danger",
          })
        );
        return;
      }

      mutate(
        {
          firstName,
          lastName,
          ...(!isEdit && {
            email,
            role: +role,
          }),
          // eslint-disable-next-line eqeqeq
          ...(role == 3 && {
            customers: customers.map(({ value }) => value),
          }),
          phone,
        },
        {
          onError: (e) => {
            dispatch(
              showAlert({
                message: e.data?.message || genericErrorMessage,
                type: "danger",
              })
            );
          },
          onSuccess: (data) => {
            if (data?.warning) {
              dispatch(
                showAlert({
                  message: data?.message,
                  type: "warning",
                })
              );
            } else {
              dispatch(
                showAlert({
                  message: `Administrator ${
                    isEdit ? "edited" : "created"
                  } successfully`,
                  type: "success",
                })
              );
            }
            closeModal();
          },
        }
      );
    },
    [mutate, dispatch, isEdit, closeModal, haveOnlyOneMSPAdmin]
  );

  return initialValues ? (
    <ModalForm
      className={styles.modalContainer}
      bodyClassName={styles.overflowYVisible}
      disableSubmitWhenInvalid
      submitButtonLabel="Save"
      closeModal={closeModal}
      withValidation
      header={header}
      isOpen={isOpen}
      size="md"
      onSubmit={onSubmitHandler}
      initialValues={initialValues}
      validationSchema={validationSchemaCreator}
      renderChildren={(props) => (
        <FormContainer formikProps={props} isEdit={isEdit} />
      )}
    />
  ) : null;
};

AdministratorForm.defaultProps = {
  isEdit: false,
};

export default AdministratorForm;
