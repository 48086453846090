import React from "react";
import Field from "components/Field/Field";
import {
  CAM_FEATURES,
  genericSelectTypes,
  helpArticles,
} from "consts";
import BackupFrequencyLimitInput from "./BackupFrequencyLimitInput";
import RetentionLimitInput from "./RetentionLimitInput";
import Row from "orion/lib/row";
import Col from "orion/lib/col";
import InfoGreyOutline from "@druva-apps/orion-icons/lib/InfoGreyOutline";
import { getHelpPortalURL } from "utils/mspUtils";

const ServicePlanFormFeatures = ({
  formikProps,
  index,
  featureDetails,
  featureIndex,
}) => {
  return (
    <>
      {featureDetails.name !== CAM_FEATURES.Endpoints ? (
        <Field
          type={genericSelectTypes.servicePlanWorkloads}
          isMulti
          name={`products[${index}].features[${featureIndex}].attrs.workloads`}
          label="Workloads"
          placeholder="Select Workloads"
          formikProps={formikProps}
          datasourceType={featureDetails.name}
          menuPosition="fixed"
        />
      ) : null}
      <BackupFrequencyLimitInput
        formikProps={formikProps}
        index={index}
        featureIndex={featureIndex}
      />
      <RetentionLimitInput
        formikProps={formikProps}
        index={index}
        featureIndex={featureIndex}
      />
      <Row>
        <Col sm={3}></Col>
        <Col className="oui-d-flex">
          <InfoGreyOutline className="oui-mr-2" />
          <div className="font-12 oui-text-muted">
            Backup frequency and retention limits correspond to the compliance
            requirements, these are soft limits that can be edited later. &nbsp;
            <a
              href={getHelpPortalURL(helpArticles.complianceSettingsServicePlan)}
              target="_blank"
              rel="noreferrer"
              className="font-12"
            >
              Learn more
            </a>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ServicePlanFormFeatures;
