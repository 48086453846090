import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import {
  CAM_ATTRIBUTES,
  CAM_FEATURES,
  genericErrorMessage,
  PRODUCT_ID,
} from "consts";
import useCreateServicePlanForm from "./useCreateServicePlanForm";
import useUpdateServicePlanForm from "./useUpdateServicePlanForm";
import { showAlert } from "slices/alertsSlice";
import ModalForm from "shared/ModalForm";

import styles from "./ServicePlanForm.module.scss";
import FormContainer from "./FormContainer";

const validationSchemaCreator = (Yup) =>
  Yup.object().shape({
    name: Yup.string()
      .required("Required")
      .max(64, "Must contain maximum 64 characters")
      .test("emptyString", "Required", (value) => value?.trim().length > 0)
      .test("regexMatch", "'|?*:/\\\"<>' are not allowed", function (value) {
        const pattern = /^([^|?*:/\\"<>'])*$/;
        return pattern.test(value);
      }),
    products: Yup.array()
      .of(
        Yup.object().shape({
          edition: Yup.object()
            .nullable()
            .test("enabledEditionID", "Required", function (value) {
              const { isEnabled } = this.parent;
              if (isEnabled) {
                return value instanceof Object;
              }
              return true;
            }),
          features: Yup.array()
            .of(
              Yup.object().shape({
                attrs: Yup.object().when("isEnabled", {
                  is: true,
                  then: Yup.object().shape({
                    backupFrequencyLimitMin: Yup.number()
                      .truncate()
                      .test(
                        "dailyBackupFrequencyLimitMin",
                        "Min value should be between 1 to 9",
                        function (value) {
                          const { backupFrequencyLimitUnit } = this.parent;
                          if (
                            backupFrequencyLimitUnit &&
                            backupFrequencyLimitUnit.value === "daily"
                          ) {
                            return value && value > 0 && value < 10;
                          }
                          return true;
                        }
                      )
                      .test(
                        "weeklyBackupFrequencyLimitMin",
                        "Min value should be between 1 to 99",
                        function (value) {
                          const { backupFrequencyLimitUnit } = this.parent;
                          if (
                            backupFrequencyLimitUnit &&
                            backupFrequencyLimitUnit.value === "weekly"
                          ) {
                            return value && value > 0 && value < 100;
                          }
                          return true;
                        }
                      )
                      .test(
                        "backupFrequencyLimitMax",
                        "Min value should be less than or equal to max value",
                        function (value) {
                          const { backupFrequencyLimitMax } = this.parent;
                          if (backupFrequencyLimitMax) {
                            return value <= backupFrequencyLimitMax;
                          }
                          return true;
                        }
                      ),
                    backupFrequencyLimitMax: Yup.number()
                      .truncate()
                      .test(
                        "daily",
                        "Max value should be between 1 to 9",
                        function (value) {
                          const { backupFrequencyLimitUnit } = this.parent;
                          if (
                            backupFrequencyLimitUnit &&
                            backupFrequencyLimitUnit.value === "daily"
                          ) {
                            return value && value > 0 && value < 10;
                          }
                          return true;
                        }
                      )
                      .test(
                        "weekly",
                        "Max value should be between 1 to 99",
                        function (value) {
                          const { backupFrequencyLimitUnit } = this.parent;
                          if (
                            backupFrequencyLimitUnit &&
                            backupFrequencyLimitUnit.value === "weekly"
                          ) {
                            return value && value > 0 && value < 100;
                          }
                          return true;
                        }
                      ),
                    retentionLimitMin: Yup.number()
                      .truncate()
                      .test(
                        "hybridWorkload",
                        "Min value should be between 0 to 9999",
                        function (value) {
                          const { datasourceType, retentionLimitUnit } =
                            this.parent;
                          if (
                            retentionLimitUnit &&
                            datasourceType === "Hybrid Workloads"
                          ) {
                            return value >= 0 && value < 10000;
                          }
                          return true;
                        }
                      )
                      .test(
                        "M365DailyMin",
                        "Min value should be between 0 to 1000",
                        function (value) {
                          const { datasourceType, retentionLimitUnit } =
                            this.parent;
                          if (
                            datasourceType !== "Hybrid Workloads" &&
                            retentionLimitUnit &&
                            retentionLimitUnit.value === "daily"
                          ) {
                            return value >= 0 && value <= 1000;
                          }
                          return true;
                        }
                      )
                      .test(
                        "M365Weekly",
                        "Min value should be between 0 to 100",
                        function (value) {
                          const { datasourceType, retentionLimitUnit } =
                            this.parent;
                          if (
                            datasourceType !== "Hybrid Workloads" &&
                            retentionLimitUnit &&
                            (retentionLimitUnit.value === "weekly" ||
                              retentionLimitUnit.value === "monthly")
                          ) {
                            return value >= 0 && value <= 100;
                          }
                          return true;
                        }
                      )
                      .test(
                        "retentionLimitMax",
                        "Min value should be less than or equal to max value",
                        function (value) {
                          const { retentionLimitUnit, retentionLimitMax } =
                            this.parent;
                          if (retentionLimitUnit) {
                            return (
                              typeof value === "number" &&
                              (value <= retentionLimitMax ||
                                retentionLimitMax === 0)
                            );
                          }
                          return true;
                        }
                      ),
                    retentionLimitMax: Yup.number()
                      .truncate()
                      .test(
                        "hybridWorkload",
                        "Max value should be between 0 to 9999",
                        function (value) {
                          const { datasourceType, retentionLimitUnit } =
                            this.parent;
                          if (
                            retentionLimitUnit &&
                            datasourceType === "Hybrid Workloads"
                          ) {
                            return value >= 0 && value < 10000;
                          }
                          return true;
                        }
                      )
                      .test(
                        "M365Daily",
                        "Max value should be between 0 to 1000",
                        function (value) {
                          const { datasourceType, retentionLimitUnit } =
                            this.parent;
                          if (
                            datasourceType !== "Hybrid Workloads" &&
                            retentionLimitUnit &&
                            retentionLimitUnit.value === "daily"
                          ) {
                            return value >= 0 && value <= 1000;
                          }
                          return true;
                        }
                      )
                      .test(
                        "M365Monthly",
                        "Max value should be between 0 to 100",
                        function (value) {
                          const { datasourceType, retentionLimitUnit } =
                            this.parent;
                          if (
                            datasourceType !== "Hybrid Workloads" &&
                            retentionLimitUnit &&
                            (retentionLimitUnit.value === "weekly" ||
                              retentionLimitUnit.value === "monthly")
                          ) {
                            return value >= 0 && value <= 100;
                          }
                          return true;
                        }
                      ),
                  }),
                }),
              })
            )
            .when("isEnabled", {
              is: true,
              then: Yup.array().test("features", function (value) {
                return value.some((v) => v.isEnabled);
              }),
            }),
        })
      )
      .test("products", function (value) {
        return value.some((v) => v.isEnabled);
      }),
  });

const ServicePlanForm = ({
  closeModal,
  header,
  isOpen,
  isEdit,
  servicePlanId,
}) => {
  const dispatch = useDispatch();
  const { current: useMutation } = useRef(
    isEdit ? useUpdateServicePlanForm : useCreateServicePlanForm
  );

  const [initialValues, { mutate }] = useMutation(servicePlanId);

  const onSubmitHandler = useCallback(
    ({ name, products }) => {
      const data = {
        ...(!isEdit && { name: name?.trim() }),
        products: products
          .filter(({ isEnabled }) => isEnabled)
          .map(({ edition, productID, features, additionalData }) => {
            const initialProductValues = initialValues.products.filter(
              (product) => product.productID === productID
            )[0];
            let selectedFeatures = features
              .filter(({ isEnabled }) => isEnabled)
              .map(({ isEnabled, name, attrs }) => {
                return {
                  name,
                  isEnabled,
                  attrs: Object.keys(attrs)
                    .filter((key) => key !== "datasourceType")
                    .map((key) => {
                      if (key === CAM_ATTRIBUTES.workloads) {
                        let workloads = (attrs[key] || []).map((workload) => ({
                          name: workload.value,
                          value: true,
                        }));
                        let initialWorkloads =
                          initialProductValues?.features?.filter(
                            (f) => f.name === name
                          )[0]?.attrs?.workloads;
                        if (name === CAM_FEATURES.Endpoints) {
                          workloads = [{ name: "Endpoints", value: true }];
                        }
                        if (isEdit) {
                          workloads = workloads.concat(
                            initialWorkloads
                              ?.filter(
                                (workload) =>
                                  !workloads.some(
                                    (w) => workload.value === w.name
                                  )
                              )
                              .map((workload) => ({
                                name: workload.value,
                                value: false,
                              }))
                          );
                        }
                        return {
                          name: key,
                          value: workloads,
                        };
                      }
                      return {
                        name: key,
                        value: attrs[key]?.value
                          ? attrs[key].value
                          : attrs[key] !== null
                          ? attrs[key]
                          : "",
                      };
                    }),
                };
              });

            if (isEdit) {
              let featuresRemoved = initialProductValues?.features?.filter(
                (feature) =>
                  feature.isEnabled &&
                  !selectedFeatures.some(
                    (f) => feature.name === f.name && f.isEnabled
                  )
              );
              if ((featuresRemoved || [])?.length > 0) {
                selectedFeatures = selectedFeatures.concat(
                  featuresRemoved.map((feature) => ({
                    name: feature.name,
                    isEnabled: false,
                    attrs: [],
                  }))
                );
              }
            }
            if (productID === PRODUCT_ID.saasAppsAndEndpoints) {
              const additionalFeatures = Object.keys(additionalData).map(
                (key) => ({
                  name: key,
                  isEnabled: additionalData[key],
                  ...(key === CAM_FEATURES.storage && additionalData[key]
                    ? {
                        attrs: [
                          {
                            name: CAM_ATTRIBUTES.unlimitedStorage,
                            value: true,
                          },
                        ],
                      }
                    : {}),
                })
              );
              selectedFeatures = selectedFeatures.concat(additionalFeatures);
            }
            return {
              productID,
              edition: edition.value,
              features: selectedFeatures,
              isEnabled: true,
            };
          }),
      };
      let productRemoved =
        isEdit &&
        !initialValues.products
          .filter((product) => product.isEnabled)
          .every((product) =>
            data.products.some(
              (p) => product.productID === p.productID && product.isEnabled
            )
          );
      if (productRemoved) {
        data.products = [
          ...data.products,
          ...initialValues.products
            .filter(
              (product) =>
                !data.products?.some((p) => product.productID === p.productID)
            )
            .map((product) => ({
              productID: product.productID,
              edition: product.edition.value,
              features: [],
              isEnabled: false,
            })),
        ];
      }
      mutate(data, {
        onError: (e) => {
          dispatch(
            showAlert({
              message: e.data?.message || genericErrorMessage,
              type: "danger",
            })
          );
        },
        onSuccess: (res) => {
          const alertMessage = {
            message: res.warning
              ? res.message
              : `Service plan ${ isEdit ? "edited" : "created" } successfully.`,
            type: res.warning ? "warning" : "success",
          };
          dispatch(showAlert(alertMessage));

          closeModal();
        },
      });
    },
    [mutate, dispatch, isEdit, closeModal, initialValues]
  );

  return initialValues ? (
    <ModalForm
      className={styles.modalContainer}
      bodyClassName={styles.overflowYVisible}
      disableSubmitWhenInvalid
      submitButtonLabel="Save"
      closeModal={closeModal}
      withValidation
      header={header}
      isOpen={isOpen}
      size="xxl"
      onSubmit={onSubmitHandler}
      initialValues={initialValues}
      validationSchema={validationSchemaCreator}
      renderChildren={(props) => (
        <FormContainer formikProps={props} isEdit={isEdit} />
      )}
    />
  ) : null;
};

ServicePlanForm.defaultProps = {
  isEdit: false,
};

export default ServicePlanForm;
