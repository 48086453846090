import { genericErrorMessage } from "consts";
import { Col, Label, Row, Select } from "orion";
import useSetIdleSessionTimeout from "queryHooks/useSetIdleSessionTimeout/useSetIdleSessionTimeout";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import ModalForm from "shared/ModalForm";
import { showAlert } from "slices/alertsSlice";
import {
  convertHourAndMinutesToSeconds,
  convertSessionValuesToHoursMinutes,
  getHoursDropDownData,
  getMinutesDropDownData,
} from "./timeUtil";

const EditIdleSessionTimeoutModal = ({
  closeModal,
  isOpen,
  sessionTimeout,
}) => {
  const { timeInHours, timeInMinutes } =
    convertSessionValuesToHoursMinutes(sessionTimeout);

  const [minLimit, setMinLimit] = useState(timeInHours === 0 ? 15 : 0);
  const [minDisabled, setMinDisabled] = useState(timeInHours === 6);

  const dispatch = useDispatch();
  const { mutate } = useSetIdleSessionTimeout();

  const getDropDownOptions = (optionType) => {
    let resultantArray = null;

    if (optionType === "hour") {
      resultantArray = getHoursDropDownData();
    } else {
      resultantArray = getMinutesDropDownData(minLimit);
    }

    return resultantArray.map((val) => ({
      label: `${val} ${optionType}`,
      value: val,
    }));
  };

  const onChangeHours = useCallback(
    (e, setFieldValue, timeInMinutes) => {
      const timeInHours = Number(e.value);
      setMinDisabled(false);
      setMinLimit(0);
      if (timeInHours === 6) {
        setFieldValue("timeInMinutes", {
          label: "0 minutes",
          value: 0,
        });
        setMinDisabled(true);
      }
      if (timeInHours === 0) {
        setMinLimit(15);
        if (timeInMinutes.value === 0) {
          setFieldValue("timeInMinutes", {
            label: "15 minutes",
            value: 15,
          });
        }
      }
    },
    [setMinDisabled, setMinLimit]
  );

  const onSubmitHandler = ({ timeInHours, timeInMinutes }) => {
    const timeInSeconds = convertHourAndMinutesToSeconds({
      timeInHours: timeInHours.value,
      timeInMinutes: timeInMinutes.value,
    });
    mutate(
      {
        sessionTimeout: timeInSeconds,
      },
      {
        onError: (e) => {
          dispatch(
            showAlert({
              message: e.data?.message || genericErrorMessage,
              type: "danger",
            })
          );
        },
        onSuccess: () => {
          dispatch(
            showAlert({
              message:
                "Idle session timeout configuration updated successfully",
              type: "success",
            })
          );
          closeModal();
        },
      }
    );
  };

  return (
    <ModalForm
      disableSubmitWhenInvalid
      closeModal={closeModal}
      withValidation
      initialValues={{
        timeInHours: {
          label: `${timeInHours} hour`,
          value: timeInHours,
        },
        timeInMinutes: {
          label: `${timeInMinutes} minutes`,
          value: timeInMinutes,
        },
      }}
      header="Edit Inactive Login Expiry Settings"
      isOpen={isOpen}
      size="sm"
      onSubmit={onSubmitHandler}
      renderChildren={(formProps) => (
        <>
          <Row>
            <Col>
              <Label text="Inactive Login Expires after" />
            </Col>
          </Row>
          <Row className="oui-mt-2 oui-mb-2">
            <Col sm={6}>
              <Select
                name="timeInHours"
                id="timeInHours"
                type="select"
                menuPosition="fixed"
                options={getDropDownOptions("hour")}
                validationProps={formProps}
                value={formProps.values.timeInHours}
                onChange={(e) =>
                  onChangeHours(
                    e,
                    formProps.setFieldValue,
                    formProps.values.timeInMinutes
                  )
                }
              />
            </Col>
            <Col sm={6}>
              <Select
                name="timeInMinutes"
                id="timeInMinutes"
                type="select"
                menuPosition="fixed"
                options={getDropDownOptions("minutes")}
                isDisabled={minDisabled}
                validationProps={formProps}
                value={formProps.values.timeInMinutes}
              />
            </Col>
          </Row>
        </>
      )}
      submitButtonLabel="Save"
    />
  );
};

export default EditIdleSessionTimeoutModal;
