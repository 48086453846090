import React from "react";
import { ConfirmationModal } from "orion";
import { useDispatch } from "react-redux";

import { genericErrorMessage } from "consts";
import { showAlert } from "slices/alertsSlice";
import useDeleteCustomerContact from "queryHooks/useDeleteContact/useDeleteContact";

const DeleteCustomerContactActionModal = ({
  key,
  closeModal,
  contactId,
  customerId,
}) => {
  const dispatch = useDispatch();
  const { mutate } = useDeleteCustomerContact(customerId, contactId, {
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
    onSuccess: () => {
      closeModal();
      dispatch(
        showAlert({
          message: "Successfully deleted Customer Contact",
          type: "success",
        })
      );
    },
  });

  return (
    <ConfirmationModal
      key={key}
      isOpen
      toggle={closeModal}
      onContinue={() => mutate()}
      header="Delete Confirmation"
      message="This will delete the selected customer contact from the list. Are you sure you want to delete selected contact?"
      continueButtonText="Yes, Delete"
      continueButtonType="danger"
      cancelButtonText="No"
    />
  );
};

export default DeleteCustomerContactActionModal;
