import { helpArticles } from "consts";
import { ConfirmationModal, Row, Col } from "orion";
import { getHelpPortalURL } from "utils/mspUtils";

const MFAWarningModal = ({ isSSOEnabled, toggle, isOpen, onContinue }) => {
  return (
    <ConfirmationModal
      header={
        isSSOEnabled
          ? "Disable Single Sign-On for Administrators"
          : "Enable Single Sign-On for Administrators"
      }
      toggle={toggle}
      isOpen={isOpen}
      continueButtonText="Proceed"
      onContinue={onContinue}
      body={() => {
        return (
          <>
            <Row>
              <Col>
                <span>
                  {isSSOEnabled
                    ? "Disabling SSO will revoke access to all administrators authenticated through the SSO provider. However, Administrators can use their Managed Services Center account password to sign in. "
                    : "Enabling Single Sign-on will allow all administrators to access Managed Services Center with configured IdP credentials. "}
                  <a
                    href={getHelpPortalURL(helpArticles.ssoWithMsc)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more.
                  </a>
                  <br />
                  <br /> Do you want to proceed?
                </span>
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
};

export default MFAWarningModal;
